import styled from '@emotion/styled'
import LoadingDots from '../LoadingDots'

const TableLoadingContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 60px;
  left: 0;
  z-index: 10;
  opacity: 1;
  background: #eeeeee;
`

interface TableLoadingProps {
  loading: boolean
}

const TableLoading = ({ loading }: TableLoadingProps) => {
  if (!loading) return <div />

  return (
    <TableLoadingContainer data-testid="table-loading-container">
      <LoadingDots centered />
    </TableLoadingContainer>
  )
}

export default TableLoading
