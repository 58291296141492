import { onError } from 'apollo-link-error'
import { type ServerError, type ServerParseError } from 'apollo-link-http-common'
import { setContext } from 'apollo-link-context'
import localforage from 'localforage'
import { ApolloError } from 'apollo-client'
import instacart from '../common/instacart'
import { errors } from '../../utils/error-handling/errors'
import clientEnv from '../../utils/global/clientEnv'
import {
  isDisabledAccountError,
  isUndeterminedAccountError,
} from '../../api/utils/apollo/errors/isError.hooks'
import { logout, LogoutReason } from '../../gin-and-tonic/containers/log-out/utils/logout'
import { type EnterpriseGraphQLError } from './errors'

const fetchToken = async () => {
  const session = await localforage.getItem<{ token?: string }>('session')
  if (!session) return null

  return session.token
}

export const authLink = setContext(async (_, { headers }) =>
  fetchToken()
    .then(token => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        ...(clientEnv.PUBLIC_CLIENT_CYPRESS_TOKEN && {
          'x-cypress-token': clientEnv.PUBLIC_CLIENT_CYPRESS_TOKEN,
        }),
      },
    }))
    .catch(err =>
      // eslint-disable-next-line no-console
      console.error(err)
    )
)

export const errorLink = onError(error => {
  const { networkError } = error

  if (networkError) {
    if ((networkError as ServerError | ServerParseError).statusCode === 401) {
      instacart.logoutAndRedirect()
    } else {
      errors.captureException(networkError)
    }
  }

  if (error.graphQLErrors) {
    const graphQLErrors = error.graphQLErrors as EnterpriseGraphQLError[]

    const apolloError = new ApolloError({ graphQLErrors })

    if (isDisabledAccountError(apolloError)) {
      logout(LogoutReason.DisabledAccount)
      return
    }

    if (isUndeterminedAccountError(apolloError)) {
      logout(LogoutReason.TokenExpired)
      return
    }

    const { operationName, variables } = error.operation

    graphQLErrors.forEach(graphQLError => {
      const { message, extensions } = graphQLError
      const meta = { variables, error: graphQLError, operationName }

      if (extensions && extensions.code) {
        const errorMessage = `GraphQL Error ${extensions.code} performing ${operationName}: ${message}`
        const error = new Error(errorMessage) as Error & { dd_fingerprint: string }

        // Custom fingerprint to group errors by operation name and error code instead of default which is to put all GQL errors in the same bucket
        error.dd_fingerprint = `GRAPHQL_ERROR_${extensions.code}_${operationName}`

        errors.captureException(error, errorMessage, {
          extra: meta,
        })
      } else {
        errors.captureMessage(`Unknown GraphQL Error performing ${operationName}: ${message}`, {
          level: 'warning',
          extra: meta,
        })
      }
    })
  }
})
