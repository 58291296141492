// @ts-nocheck legacy file transformed to TS
import { createContext } from 'react'
import { NotificationsContext } from '../contexts/notifications/NotificationsContext'

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

const createContextHoc = (Context, displayName) => WrappedComponent => {
  function WithContext(props) {
    return (
      <Context.Consumer>{context => <WrappedComponent {...context} {...props} />}</Context.Consumer>
    )
  }

  WithContext.displayName = `${displayName}(${getDisplayName(WrappedComponent)})`

  return WithContext
}

export const CatalogContext = createContext()

export const withCatalogContext = createContextHoc(CatalogContext, 'withCatalogContext')

export const withNotificationContext = createContextHoc(
  NotificationsContext,
  'withNotificationContext'
)
