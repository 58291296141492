import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const cashflowPriceAuditControl: DomainAccessControlConfig = {
  permissions: [Permission.CashflowCatalogAuditView],
}

export const cashflowSpreadAuditControl: DomainAccessControlConfig = {
  permissions: [Permission.CashflowCatalogAuditView],
  featureToggles: FeatureToggle.cashflowSpreadAudits,
}
