import { useState, useEffect, useCallback, useRef } from 'react'
import { useLocation } from 'react-router'
import { RetailerSatisfactionSurveyStep } from '../RetailerSatisfactionSurveyStep'
import UserFeedbackSurveyPage1 from './UserFeedbackSurveyPage1'
import UserFeedbackSurveyPage2 from './UserFeedbackSurveyPage2'
import { UserFeedbackSurveyModal } from './UserFeedbackSurveyModal'

export enum UserFeedbackSurveyPages {
  Page1 = 'page1',
  Page2 = 'page2',
}

export interface UserFeedbackSurveyState {
  userFeedbackResponse: string
  userFeedbackAgreeContact: boolean
}

export interface UserFeedbackSurveyControllerProps {
  onClose: () => void
}

export interface UserFeedbackSurveyProps {
  onClose: () => void
  isModalClosing: boolean
}

export const SURVEY_ANIMATION_SLIDE_DURATION_MS = 500
export const SURVEY_ANIMATION_ON_CLOSE_DELAY = 450
export const SURVEY_SUCCESS_SLIDE_DURATION_MS = 2000

const defaultState = {
  userFeedbackResponse: '',
  userFeedbackAgreeContact: false,
}

export const UserFeedbackSurveyController = ({ onClose }: UserFeedbackSurveyControllerProps) => {
  const previousLocation = useRef(null)
  const location = useLocation()
  const [isModalClosing, setIsModalClosing] = useState(false)

  /**
   * Wrap the onClose function to first set isModalClosing to true
   * This is done so we can allow the modal to perform its exit animation first
   */
  const onRequestModalClose = useCallback(() => {
    if (!isModalClosing) {
      setIsModalClosing(true)
      setTimeout(() => {
        onClose()
      }, SURVEY_ANIMATION_ON_CLOSE_DELAY)
    }
  }, [onClose, isModalClosing])

  // Close the modal immediately if the user navigates away from the page
  useEffect(() => {
    if (previousLocation.current !== null) {
      onClose()
    } else {
      previousLocation.current = location.pathname
    }
  }, [location, onClose])

  const [surveyData, setSurveyData] = useState<UserFeedbackSurveyState>(defaultState)

  return (
    <UserFeedbackSurveyModal
      toolProps={{ toolName: 'User Feedback Survey', onClose: onRequestModalClose, isModalClosing }}
      milestoneSlug="user_feedback_survey_r202408"
      initialPage={UserFeedbackSurveyPages.Page1}
      surveyData={[surveyData, setSurveyData]}
      onSurveyCompleted={() => surveyData}
      onClose={onRequestModalClose}
      isClosing={isModalClosing}
    >
      <RetailerSatisfactionSurveyStep
        name={UserFeedbackSurveyPages.Page1}
        component={UserFeedbackSurveyPage1}
      />
      <RetailerSatisfactionSurveyStep
        name={UserFeedbackSurveyPages.Page2}
        component={UserFeedbackSurveyPage2}
      />
    </UserFeedbackSurveyModal>
  )
}
