import type { PropsWithChildren, FunctionComponent } from 'react'
import { Text, spacing } from '@retailer-platform/shared-components'
import { type AccessControlConfig } from '../../../legacy/components/AccessControl/accessControl.utils'
import { useDashMessages } from '../../../intl/intl.hooks'
import errorSvg from './assets/error.svg'

export const NoAccessPage: FunctionComponent<
  PropsWithChildren<{
    accessControl: AccessControlConfig
  }>
> = ({ accessControl }) => {
  const messages = useDashMessages({
    title: 'components.noAccessPage.title',
    description: 'components.noAccessPage.description',
  })

  return (
    <div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          gap: spacing.X16,
          width: 340,
        }}
      >
        <img css={{ width: 320, marginTop: -48 }} src={errorSvg} alt={messages.title} />

        <Text size="large" weight="bold">
          {messages.title}
        </Text>

        <Text>{messages.description}</Text>
      </div>
    </div>
  )
}
