import type { ReactNode, PropsWithChildren, FunctionComponent } from 'react'
import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { useNavContext } from '../utils/NavContext'
import { spacing } from '../../../foundation/spacing'
import { colors } from '../../../foundation/colors'
import { useRPPTour } from '../../../../utils/tour/tour.hooks'
import { NavFooterItems } from './NavFooterItems'

type Props = {
  mainContent: ReactNode
  footerContent: ReactNode
  headerContent?: ReactNode
  rightBorderColor?: string
}

const NavOuterContainer = styled.div({
  height: '100%',
  position: 'relative',
  width: 40,
})

const NavInnerContainer = styled.div<{
  isNavOpen: boolean
  backgroundColor: string
  rightBorderColor: string
}>(
  {
    paddingTop: spacing.X12,
    paddingBottom: spacing.X12,
    backgroundColor: colors.GRAYSCALE.X70,
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    overflowX: 'hidden',
    transition: 'width 0.3s',

    '& > *': {
      flexShrink: 0,
    },
  },
  ({ isNavOpen, backgroundColor, rightBorderColor }) => ({
    width: isNavOpen ? 256 : 40,
    backgroundColor,
    borderRight: rightBorderColor ? `1px solid ${rightBorderColor}` : undefined,
  })
)

export const NavMainContent = styled.div({
  flex: '1 1 auto',
  overflowY: 'auto',
  height: 0,
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

export const NavContainer: FunctionComponent<PropsWithChildren<Props>> = ({
  mainContent,
  footerContent,
  headerContent,
  rightBorderColor,
}) => {
  const { isNavOpen, openNav, closeNav, backgroundColor } = useNavContext()
  const navRef = useRef<HTMLDivElement>(null)
  const { isOpen } = useRPPTour()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (!navRef.current?.contains(event.target as Node) && !isOpen) {
        closeNav()
      }
    }

    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [closeNav, isOpen])

  return (
    <NavOuterContainer>
      <NavInnerContainer
        ref={navRef}
        isNavOpen={isNavOpen}
        onMouseEnter={openNav}
        onMouseLeave={closeNav}
        backgroundColor={backgroundColor}
        rightBorderColor={rightBorderColor}
      >
        <div>{headerContent}</div>

        <NavMainContent>{mainContent}</NavMainContent>
        <NavFooterItems>{footerContent}</NavFooterItems>
      </NavInnerContainer>
    </NavOuterContainer>
  )
}
