import React, { type FunctionComponent, Fragment } from 'react'
import { type DashRouteComponentProps } from '@retailer-platform/dashboard/routing'
import { TDSStylesProvider } from '@retailer-platform/shared-components/src/tds'
import { DocumentTitle, SelectionManagerProvider } from '@retailer-platform/dashboard/gin-and-tonic'
import { Breadcrumb, Breadcrumbs, PageHeader } from '@retailer-platform/shared-components'
import { InstacartManagedReportsList } from '../../components/normalized-reports-list/InstacartManagedReportsList'
import { REPORT_TYPE_CONFIG } from '../../components/normalized-reports-list/utils/ReportsNormalizedReportsList.utils'
import { useDomainMessages } from '../../utils/domain/intl'

interface Props
  extends DashRouteComponentProps<{
    /*
    Add here any properties that you expect to come from the route. For instance,

    id: string
  */
    partner_id: string
    warehouse_id: string
  }> {}

const InstacartManagedReportsListPage: FunctionComponent<React.PropsWithChildren<Props>> = ({
  match,
}) => {
  const messages = useDomainMessages({
    analytics: 'reportsDomain.breadcrumbs.analytics.root',
    normalizedReports: 'reportsDomain.instacartManagedReportsList.nav',
  })

  const partnerId = match?.params?.partner_id
  const retailerId = match?.params?.warehouse_id

  return (
    <TDSStylesProvider>
      <DocumentTitle titleId="reportsDomain.normalizedReports.list.pageTitle" />
      <PageHeader
        title={
          <div>
            <Breadcrumbs>
              <Breadcrumb>{messages.analytics}</Breadcrumb>
              <Breadcrumb>{messages.normalizedReports}</Breadcrumb>
            </Breadcrumbs>
          </div>
        }
      />

      <SelectionManagerProvider>
        <InstacartManagedReportsList
          partnerId={partnerId}
          retailerId={retailerId}
          reportTypesConfig={REPORT_TYPE_CONFIG}
          filterUnavailableReportTypes={true}
        />
      </SelectionManagerProvider>
    </TDSStylesProvider>
  )
}

export default InstacartManagedReportsListPage
