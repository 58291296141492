import type { PropsWithChildren, FC } from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { Button, SVGIcon, colors } from 'ic-snacks'

import TableLoader from '../../../../../components/Table/TableLoader'
import TableActionsConfirmDismiss, {
  TableActionsConfirmDismissTypes,
} from '../../../../../components/Table/TableActionsConfirmDismiss'
import { DashTooltip } from '../../../../../dash-blocks/dash-tooltip/DashTooltip'

interface Props {
  isMainAisle: boolean
  isEditingAisle: boolean
  isDeletingAisle: boolean
  isBusyAisle: boolean
  onUpdatePrimaryAisleClick: () => void
  onEditClick: () => void
  onDeleteClick: () => void
  onConfirmUpdateClick: () => void
  onConfirmDeleteClick: () => void
  onCancelClick: () => void
}

const buttonStyle = {
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
}

const Container = styled.div`
  width: 140px;
`

const FlexContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`

const ActionContainer = styled.div`
  width: 30px;
`

const ProductAislesActions: FC<PropsWithChildren<Props>> = props => {
  const { isBusyAisle, isMainAisle, isEditingAisle, isDeletingAisle } = props

  if (isBusyAisle) {
    return (
      <Container data-testid="busy">
        <TableLoader />
      </Container>
    )
  }

  return (
    <FlexContainer>
      {isMainAisle ? (
        <ActionContainer />
      ) : (
        <ActionContainer>
          <DashTooltip
            placement="left"
            target={
              <Button
                snacksStyle="flat"
                icon={<SVGIcon name="star" color={colors.GREEN_500} />}
                style={buttonStyle}
                onClick={props.onUpdatePrimaryAisleClick}
                elementAttributes={{ 'data-testid': 'make-primary' }}
              />
            }
          >
            <FormattedMessage id="catalog.product.tooltip.setPrimary" />
          </DashTooltip>
        </ActionContainer>
      )}
      {isEditingAisle || isDeletingAisle ? (
        <TableActionsConfirmDismiss
          actionType={
            isEditingAisle
              ? TableActionsConfirmDismissTypes.EDIT
              : TableActionsConfirmDismissTypes.DELETE
          }
          onConfirmClick={isEditingAisle ? props.onConfirmUpdateClick : props.onConfirmDeleteClick}
          onDismissClick={props.onCancelClick}
        />
      ) : (
        <>
          <ActionContainer>
            <DashTooltip
              placement="left"
              target={
                <Button
                  icon={<SVGIcon name="pencil" color={colors.GREEN_500} />}
                  snacksStyle="flat"
                  style={buttonStyle}
                  onClick={props.onEditClick}
                  elementAttributes={{ 'data-testid': 'edit' }}
                />
              }
            >
              <FormattedMessage id="catalog.product.tooltip.edit" />
            </DashTooltip>
          </ActionContainer>
          <ActionContainer>
            <DashTooltip
              placement="left"
              target={
                <Button
                  snacksStyle="flat"
                  icon={
                    <SVGIcon name="delete" color={isMainAisle ? colors.GRAY_46 : colors.RED_700} />
                  }
                  style={buttonStyle}
                  onClick={props.onDeleteClick}
                  elementAttributes={{ 'data-testid': 'delete' }}
                  disabled={isMainAisle}
                />
              }
            >
              <FormattedMessage
                id={
                  isMainAisle
                    ? 'catalog.product.tooltip.deletePrimary'
                    : 'catalog.product.tooltip.delete'
                }
              />
            </DashTooltip>
          </ActionContainer>
        </>
      )}
    </FlexContainer>
  )
}

export default ProductAislesActions
