import {
  transformFilterParamsToApiFilterSelectionsFactory,
  transformFilterParamsForApiConfig,
} from '../utils/insightsPortalSchemas.utils'
import { createSchemaConfig } from '../helpers/schema.helpers'
import { InsightsFormatterType } from '../types/schema.types'
import { Metrics, Dimensions } from './insightsPortalAdsOverview.types'

export { Metrics, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  metricsConfig: {
    [Metrics.REV_SHARE]: {
      label: 'insightsPortalDomain.adsDashboard.metrics.REV_SHARE',
      formatter: InsightsFormatterType.Currency,
    },
  },
  dimensions: Dimensions,
  dimensionsConfig: {
    [Dimensions.RETAILER]: {
      label: 'insightsPortalDomain.filters.demandSource.rsd',
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.filters.banners.label',
    },
    [Dimensions.RETAILER_LOCATION]: {
      label: 'insightsPortalDomain.filters.storeLocation.label',
    },
    [Dimensions.RETAILER_LOCATION_NAME]: {
      label: 'insightsPortalDomain.filters.storeLocation.label',
    },
    [Dimensions.DEMAND_SOURCE]: {
      label: 'insightsPortalDomain.filters.demandSource.label',
    },
    [Dimensions.WAREHOUSE_LOCATION_STATE]: {
      label: 'insightsPortalDomain.filters.state.label',
    },
    [Dimensions.AD_FORMAT]: {
      label: 'insightsPortalDomain.filters.adFormat.label',
    },
    [Dimensions.SURFACE]: {
      label: 'insightsPortalDomain.filters.surface.label',
    },
  },
  groupBysConfig: {
    [Dimensions.RETAILER]: {
      label: 'insightsPortalDomain.filters.demandSource.rsd',
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.filters.banners.label',
    },
    [Dimensions.RETAILER_LOCATION]: {
      label: 'insightsPortalDomain.filters.storeLocation.label',
    },
    [Dimensions.RETAILER_LOCATION_NAME]: {
      label: 'insightsPortalDomain.filters.storeLocation.label',
    },
    [Dimensions.DEMAND_SOURCE]: {
      label: 'insightsPortalDomain.filters.demandSource.label',
    },
    [Dimensions.WAREHOUSE_LOCATION_STATE]: {
      label: 'insightsPortalDomain.filters.state.label',
    },
    [Dimensions.AD_FORMAT]: {
      label: 'insightsPortalDomain.filters.adFormat.label',
    },
    [Dimensions.SURFACE]: {
      label: 'insightsPortalDomain.filters.surface.label',
    },
  },
})
