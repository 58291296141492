import type { PropsWithChildren, FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { colors } from '@retailer-platform/shared-components'
import { type NavMenuHierarchy } from '../../NavMenuHierarchy'
import { NavMenuLeftL1Item } from './NavMenuLeftL1Item'

type Props = {
  currentlySelectedItem: number
  setCurrentlySelectedItem: any
  navMenuHierarchy: NavMenuHierarchy
}

const Container = styled.div({
  borderRight: `1px solid ${colors.GRAYSCALE.X20}`,
  paddingTop: '24px',
  width: '226px',
  height: '100%',
})

export const DrawerContentsLeftPane: FunctionComponent<PropsWithChildren<Props>> = ({
  currentlySelectedItem,
  setCurrentlySelectedItem,
  navMenuHierarchy,
}) => (
  <Container>
    {navMenuHierarchy.hierarchy.map((e, idx) => (
      <NavMenuLeftL1Item
        key={idx}
        l1Key={idx}
        l1={e}
        title={e.name}
        currentlySelectedItem={currentlySelectedItem}
        setCurrentlySelectedItem={setCurrentlySelectedItem}
      />
    ))}
  </Container>
)
