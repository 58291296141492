import type { ComponentPropsWithoutRef, ComponentProps, ReactNode, CSSProperties } from 'react'
import { PureComponent } from 'react'
import { SVGIcon, Text, colors } from 'ic-snacks'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`

const BodyContainer = styled.div<{ size: 'large' | 'small' }>`
  margin-bottom: ${({ size }) => (size === 'large' ? '26px' : '18px')};
  text-align: center;
  width: ${({ size }) => (size === 'large' ? '380px' : '325px')};
`

const largeIconStyle = {
  width: 160,
  height: 160,
  color: colors.GRAY_74,
}

const smallIconStyle = {
  width: 75,
  height: 75,
  color: colors.GRAY_74,
}

export interface EmptyViewProps extends Omit<ComponentPropsWithoutRef<'div'>, 'title'> {
  size: 'small' | 'large'
  iconName: ComponentProps<typeof SVGIcon>['name']
  title: ReactNode
  body?: ReactNode
  action?: ReactNode
  iconStyle?: CSSProperties
}

class EmptyView extends PureComponent<EmptyViewProps> {
  static defaultProps = {
    size: 'large',
  }

  getIconStyle = (large: boolean, customStyles?: CSSProperties) => {
    const defaultStyle = large ? largeIconStyle : smallIconStyle

    return { ...defaultStyle, ...customStyles }
  }

  render() {
    const { size, iconName, title, body, action, iconStyle, ...rest } = this.props
    const large = size === 'large'
    const titleMargin = large ? '17px' : '10px'

    return (
      <Container {...rest}>
        <SVGIcon name={iconName} style={this.getIconStyle(large, iconStyle)} />
        <Text
          variant={large ? 'T.28' : 'T.18'}
          fontWeight="regular"
          style={{ marginTop: titleMargin, marginBottom: titleMargin }}
        >
          {title}
        </Text>
        {body && (
          <BodyContainer size={size}>
            <Text variant={large ? 'T.16' : 'T.12'} style={{ color: colors.GRAY_20 }}>
              {body}
            </Text>
          </BodyContainer>
        )}
        {action}
      </Container>
    )
  }
}

export default EmptyView
