/* eslint  @typescript-eslint/no-empty-function: 0 */
import type { MouseEventHandler } from 'react'
import { type StepType } from '@reactour/tour'
import { unstableFeatureHighlight } from '@instacart/ids-tooling'
import { ArrowLeftIcon } from '@instacart/ids-core'
import { type TourStep } from './tour.types'

const {
  FeatureHighlight,
  FeatureHighlightContent,
  FeatureHighlightDescription,
  FeatureHighlightIllustration,
  FeatureHighlightNavigation,
  FeatureHighlightNextButton,
  FeatureHighlightPrevButton,
  FeatureHighlightProgressText,
  FeatureHighlightTitle,
  FeatureHighlightCloseButton,
} = unstableFeatureHighlight

export function getDefaultTourProviderProps() {
  return {
    showCloseButton: false,
    disableInteraction: true,
    showNavigation: false,
    beforeClose: () => {},
    onClickClose: () => {},
    onClickMask: () => {},
    styles: {
      maskWrapper: base => ({ ...base, opacity: 0 }),
      popover: base => ({
        ...base,
        padding: '0 0 0 0',
        borderRadius: '12px',
      }),
      controls: base => ({ ...base, marginTop: 0 }),
      dot: base => ({ ...base, visibility: 'hidden' }),
    },
    showBadge: false,
    steps: [],
  }
}

export function mapStepConfigsToSteps(
  stepConfigs: TourStep[],
  messages: Record<
    | 'nextStepButtonA11yLabel'
    | 'prevStepButtonA11yLabel'
    | 'nextStepButton'
    | 'doneStepButton'
    | 'exitStepButton',
    string
  >
): StepType[] {
  return stepConfigs.map(
    (
      {
        selector,
        onEnterStep,
        onClickNextStep,
        onClickPrevStep,
        position,
        onExitTour,
        title,
        description,
        showStepper = true,
        showPrevButton = true,
        beaconPosition,
        imgUrl: illurationUrl,
        imgAlt,
        nextButtonLabel,
        prevButtonLabel,
      },
      index,
      self
    ) => {
      const isLastStep = self.length - 1 === index
      showStepper = showStepper && self.length > 1

      return {
        selector: selector,
        position: position,
        action: onEnterStep,
        content: props => {
          // TODO potential breaking change here. Need to double check if this is the correct way to handle this
          const { styles: _, ...stylelessProps } = props
          return (
            <FeatureHighlight
              beaconPosition={beaconPosition}
              totalSteps={self.length}
              illustration={
                illurationUrl ? (
                  <FeatureHighlightIllustration>
                    <img
                      css={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      src={illurationUrl}
                      alt={imgAlt}
                    />
                  </FeatureHighlightIllustration>
                ) : null
              }
              closeButton={
                <FeatureHighlightCloseButton
                  accessibleLabel={messages.exitStepButton}
                  onClick={onExitTour}
                />
              }
              content={
                <FeatureHighlightContent>
                  <FeatureHighlightProgressText>
                    {showStepper ? `STEP ${props.currentStep + 1} OF ${self.length}` : null}
                  </FeatureHighlightProgressText>
                  <FeatureHighlightTitle>{title}</FeatureHighlightTitle>
                  <FeatureHighlightDescription>{description}</FeatureHighlightDescription>
                </FeatureHighlightContent>
              }
              navigation={
                <FeatureHighlightNavigation
                  prevButton={
                    showPrevButton ? (
                      <FeatureHighlightPrevButton
                        accessibleLabel={messages.prevStepButtonA11yLabel}
                        onClick={
                          onClickPrevStep as ((
                            args: unstableFeatureHighlight.FeatureHighlighPropsBase
                          ) => void) &
                            MouseEventHandler<HTMLButtonElement>
                        }
                      >
                        {prevButtonLabel ? prevButtonLabel : <ArrowLeftIcon size="24" />}
                      </FeatureHighlightPrevButton>
                    ) : null
                  }
                  nextButton={
                    <FeatureHighlightNextButton
                      accessibleLabel={messages.nextStepButtonA11yLabel}
                      onClick={
                        onClickNextStep as ((
                          args: unstableFeatureHighlight.FeatureHighlighPropsBase
                        ) => void) &
                          MouseEventHandler<HTMLButtonElement>
                      }
                    >
                      {isLastStep
                        ? nextButtonLabel || messages.doneStepButton
                        : nextButtonLabel || messages.nextStepButton}
                    </FeatureHighlightNextButton>
                  }
                />
              }
              {...stylelessProps}
            />
          )
        },
      }
    }
  )
}
