import type { PropsWithChildren, FunctionComponent } from 'react'
import { useBooleanState } from '@retailer-platform/shared-components'
import { UserFeedbackContext, type UserFeedbackContextValue } from './UserFeedbackContext'

export const UserFeedbackProvider: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isFeedbackButtonShown, showFeedbackButton, hideFeedbackButton] = useBooleanState(true)

  const contextValue: UserFeedbackContextValue = {
    isFeedbackButtonShown,
    showFeedbackButton,
    hideFeedbackButton,
  }

  return (
    <UserFeedbackContext.Provider value={contextValue}>{children}</UserFeedbackContext.Provider>
  )
}
