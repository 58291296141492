// @ts-nocheck legacy file transformed to TS
import { Children, Fragment } from 'react'
import styled from '@emotion/styled'

const TableActionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const TableActionsSpacer = styled.div`
  width: 10px;
`

function TableActions({ children }) {
  return (
    <TableActionsContainer>
      {Children.map(children, (child, i) => (
        <Fragment>
          {i > 0 && <TableActionsSpacer />}
          {child}
        </Fragment>
      ))}
    </TableActionsContainer>
  )
}

export default TableActions
