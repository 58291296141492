import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const campaignManagementAdminAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.CampaignManagementView, Permission.CampaignManagementModify],
  },
  {
    permissions: [Permission.PageBuilderView, Permission.PageBuilderModify],
  },
]

// Permissions for managing Theme Pages
// TODO: Add granular permissions for managing Theme Pages later
export const campaignManagementThemePageAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.CampaignManagementView, Permission.CampaignManagementModify],
}

export const campaignManagementAdminCalendarPageAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.adminCalendarPage],
}

export const campaignManagementAdminCreativesPageAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.adminCreativesPage],
}

export const campaignManagementAdminRedemptionUrls: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.campaignManagementRedemptionUrls],
}

export const campaignManagementAdminMultiRetailerPages: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.campaignManagementMultiRetailerPages],
}

export const campaignManagementAdminMultiRetailerPagesViewControl: DomainAccessControlConfig = {
  permissions: [Permission.PageBuilderView],
}

export const campaignManagementAdminMultiRetailerPagesModifyControl: DomainAccessControlConfig = {
  permissions: [Permission.PageBuilderModify],
}
