import { borderRadius, Button, spacing, Text } from '@retailer-platform/shared-components'
import type { PropsWithChildren, FunctionComponent } from 'react'
import { useState } from 'react'
import { GraphPartialIcon, CloseIcon } from '@instacart/ids-core'
import { useDashMessages } from '../../../intl/intl.hooks'

export const StaleDataNotification: FunctionComponent<
  PropsWithChildren<{ refetch: () => void }>
> = ({ refetch }) => {
  const [shouldShow, setShouldShow] = useState(true)

  const messages = useDashMessages({
    header: 'staleDataNotification.header',
    content: 'staleDataNotification.content',
    action: 'staleDataNotification.action',
  })

  if (!shouldShow) return null

  return (
    <div
      css={{
        padding: 20,
        position: 'fixed',
        width: 400,
        background: 'rgb(246 247 248 / 80%)',
        top: spacing.X20,
        right: spacing.X20,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        zIndex: 2,
        borderRadius: borderRadius.X8,
      }}
    >
      <div
        css={{ position: 'absolute', top: 0, right: 0, padding: 10, cursor: 'pointer' }}
        onClick={() => {
          setShouldShow(false)
        }}
      >
        <CloseIcon />
      </div>
      <Text weight="bold">
        <GraphPartialIcon css={{ marginRight: 10 }} />
        {messages.header}
      </Text>
      <Text size="small">{messages.content}</Text>

      <Button compact onClick={() => refetch()} css={{ margin: 0 }}>
        {messages.action}
      </Button>
    </div>
  )
}
