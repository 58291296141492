import type { PropsWithChildren, FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { CircleLogo } from '../../common/select-partner/CircleLogo'
import { useDashMessage, useDashMessages } from '../../../intl/intl.hooks'
import { type WarehousesForSelection } from './RetailerAndSitePicker.types'

const InfoBar = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px',
  gap: '8px',
  minHeight: '64px',
  maxWidth: 'fit-content',
  background: '#FFF5EB',
})

interface Props {
  currentSelectedRetailer: WarehousesForSelection
}

export const RetailerInsufficientPermissionInfoBar: FunctionComponent<PropsWithChildren<Props>> = ({
  currentSelectedRetailer,
}) => {
  const messages = useDashMessages(
    { info: 'retailerPicker.retailerInsufficientPermission' },
    { currentSelectedRetailer: currentSelectedRetailer.label }
  )

  return (
    <InfoBar>
      <CircleLogo size={19} color={'#E97300'}>
        i
      </CircleLogo>
      {messages.info}
    </InfoBar>
  )
}
