import type { PropsWithChildren, FunctionComponent } from 'react'
import { useEffect } from 'react'
import { matchPath } from 'react-router'
import ErrorBoundary from '../../../legacy/components/ErrorBoundary'
import FeatureEducationModal from '../../../legacy/components/FeatureHighlights/FeatureEducationModal'
import useFeatureEducation from '../../../legacy/components/FeatureHighlights/useFeatureEducation'
import { RetailerNav } from '../../../gin-and-tonic/containers/nav/RetailerNav'
import { AppContentContainer } from '../../../gin-and-tonic/containers/app-content-container/AppContentContainer'
import { UserFeedbackButton } from '../../../gin-and-tonic/containers/user-feedback/UserFeedbackButton'
import { useAdminControls } from '../../../utils/contexts/admin-controls/AdminControlsContext'
import { LeftShortcutsPaneRetailer } from '../../../gin-and-tonic/containers/nav-v2/components/LeftShortcutsPane/LeftShortcutsPaneRetailer'
import { MenuBarTop } from '../../../gin-and-tonic/containers/nav-v2/components/MenuBarTop'
import { useNavHierarchyRetailer } from '../../../gin-and-tonic/containers/nav-v2/NavMenuHierarchy'
import { useWindowSizeIsWide } from '../../../utils/window/windows.hooks'
import { ContactHeader } from '../../utils/ContactHeader'
import { useRequiredNavDataLoadingStatus } from '../../../gin-and-tonic/containers/nav/utils/nav.hooks'
import { DASH_NAV_V2_HEIGHT, DASH_NAV_WIDTH } from '../../../legacy/common/theme'
import { useNavContext } from '../../../gin-and-tonic/containers/nav/utils/NavContext'
import { DashRoute } from '../../../legacy/dash-components/dash-route/DashRoute'
import { DashSwitch } from '../../../legacy/dash-components/dash-switch/DashSwitch'
import { DashRedirect } from '../../../legacy/dash-components/dash-redirect/DashRedirect'
import { HaltTerms } from '../../../sections/halt/components/terms/HaltTerms'
import { usePartnerContext, URL_PARTNER_HALT_TERMS } from '../../../exports/routing'
import { PartnerType } from '../../../exports/utils'
import { url } from '../../../utils/parsing/url'
import { useFetchCurrentEnterpriseAccountQuery } from '../../../__codegen__/api'
import { useTrackEvent } from '../../../utils/events/useTrackEvent.hook'
import { AttachPartnerAnalyticsMetadata } from '../../utils/AttachAnalyticsMetadata'
import { useAllowNavV1Access } from './partnerScoped.hooks'
import { RetailerScopedSection } from './retailer-scoped/RetailerScopedSection'
import { PartnerScopedRoutes } from './PartnerScopedRoutes'

interface Props {
  pathname: string
}

export const PartnerScopedApp: FunctionComponent<PropsWithChildren<Props>> = ({ pathname }) => {
  const { showContactHeader } = useAdminControls()
  const isWide = useWindowSizeIsWide()
  useAllowNavV1Access()
  const { navV2Enabled: useNavV2, isNavVisible, isSideNavVisible } = useNavContext()
  const partnerContext = usePartnerContext()
  const { data } = useFetchCurrentEnterpriseAccountQuery()

  // temporarily gate behind roulette
  const showTerms = !!data.currentEnterpriseAccount.featureToggles?.find(
    feature => feature.name === 'rt_idp_terms'
  )

  const { isOpen, onOpen, onClose, onClick, feature } = useFeatureEducation(pathname)

  useEffect(() => {
    if (isOpen) {
      onOpen()
    }
  }, [isOpen, onOpen, pathname])

  const trackEvent = useTrackEvent()

  useEffect(() => {
    if (data?.currentEnterpriseAccount?.id) {
      trackEvent({
        id: 'navV2.enabled',
        description: 'Tracks the current nav version for the user',
        data: {
          enabled: useNavV2,
          accountID: data?.currentEnterpriseAccount?.id,
        },
      })
    }
  }, [useNavV2, data?.currentEnterpriseAccount?.id, trackEvent])

  const { navMenuHierarchy } = useNavHierarchyRetailer()

  const { loading, error } = useRequiredNavDataLoadingStatus()
  if (loading || error) return null

  // IDP users need to accept T&C before they can use IDP tools
  // for now, any accepted partner agreement will do
  if (
    showTerms &&
    partnerContext.partnerType === PartnerType.IDP &&
    data?.currentEnterpriseAccount.acceptedPartnerAgreements.length === 0 &&
    !matchPath(pathname, { path: URL_PARTNER_HALT_TERMS })
  ) {
    return (
      // eslint-disable-next-line @typescript-eslint/naming-convention
      <DashRedirect to={url(URL_PARTNER_HALT_TERMS, { partner_id: partnerContext.partnerId })} />
    )
  }

  const topPadding = isNavVisible && useNavV2 ? DASH_NAV_V2_HEIGHT : '0px'
  const leftPadding = useNavV2 ? '0px' : DASH_NAV_WIDTH

  return (
    <>
      <DashSwitch>
        <DashRoute route="partner-halt-terms" component={HaltTerms} exact />
        <DashRoute
          route="partner-scoped"
          render={() => (
            <>
              {isNavVisible && !useNavV2 && <RetailerNav />}
              {isNavVisible && useNavV2 && <MenuBarTop navMenuHierarchy={navMenuHierarchy} />}
              <AttachPartnerAnalyticsMetadata />
              <UserFeedbackButton />
              <div
                css={{
                  display: 'flex',

                  // this CSS is a relic of the Nav V1 implementation. If we don't position absolute left: 0
                  // the content is shifted off screen to the left.
                  position: 'absolute',
                  left: leftPadding,

                  width: `calc(100% - ${leftPadding})`,
                  minHeight: `calc(100% - ${topPadding})`,
                  height: `calc(100% - ${topPadding})`,
                }}
              >
                {isNavVisible && isSideNavVisible && isWide && useNavV2 && (
                  <LeftShortcutsPaneRetailer />
                )}
                <FeatureEducationModal
                  isOpen={isOpen}
                  onClose={onClose}
                  onClick={onClick}
                  feature={feature}
                />
                <AppContentContainer>
                  {/* Totem does not have a proper staging/dev environment. Totem prod api is not accessible to mesh outside of prod */}
                  {showContactHeader && <ContactHeader />}
                  <ErrorBoundary pathname={pathname}>
                    <DashSwitch>
                      <DashRoute route="retailer-scoped" component={RetailerScopedSection} />
                      <PartnerScopedRoutes />
                    </DashSwitch>
                  </ErrorBoundary>
                </AppContentContainer>
              </div>
            </>
          )}
        />
      </DashSwitch>
    </>
  )
}
