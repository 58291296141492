import { datadogRum } from '@datadog/browser-rum'
import { logDebugEvent } from '../core/factories/events/eventDebugging'
import { segmentAnalytics, SEGMENT_OPTIONS } from '../core/factories/events/segmentAnalytics'
import clientEnv from '../global/clientEnv'

export const ENTERPRISE_EVENT_PREFIX = 'ent'

export type TrackedEvent = {
  id: string
  description: string
  data?: Record<string, any>
}

export const trackEvent = ({ id, description, data }: TrackedEvent) => {
  const eventPayload = {
    ...(data || {}),
    eventDescription: description,
    clientEnvironment: clientEnv.PUBLIC_CLIENT_ENVIRONMENT,
  }

  const explicitIdentifier = `${ENTERPRISE_EVENT_PREFIX}.${id}`

  segmentAnalytics().track(explicitIdentifier, eventPayload, SEGMENT_OPTIONS)

  datadogRum.addAction(id, eventPayload)

  logDebugEvent(explicitIdentifier, description, data)
}
