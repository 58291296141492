/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prefer-stateless-function */
import { Component, Suspense, lazy } from 'react'
import { Redirect } from 'react-router-dom'
import {
  ReportsPickupAnalyticsAccessControl,
  InstacartManagedReportsListPage,
  InstacartManagedReportsListPageAccessControl,
  TransactionLogsListPage,
  TransactionLogsListPageAccessControl,
} from '@retailer-platform/domain-reports'
import RetailerRoute, { type RetailerRouteComponentProps } from '../../components/RetailerRoute'
import DocumentTitle from '../../components/DocumentTitle'
import RetailerSwitch from '../../components/RetailerSwitch'
import { InventoryFilesAccessControl } from './files/InventoryFiles.configuration'
import { AlcoholTlogsAccessControl } from './alcohol/AlcoholTlogs.configuration'
import { RetailerScopeWrapper } from '../../../gin-and-tonic/containers/retailer-scope-wrapper/RetailerScopeWrapper'
import { type RouteName } from '../../../utils/routing/routes'
import { type AccessControlConfig } from '../../components/AccessControl/accessControl.utils'

const Loading = () => null

const ReportsPickupAnalyticsPage = lazy(
  () =>
    // eslint-disable-next-line workspaces/no-relative-imports
    import(
      '../../../../../../domains/reports/src/pages/pickup-analytics/ReportsPickupAnalyticsPage'
    )
)

const InventoryFiles = lazy(
  () => import(/* webpackChunkName: "inventory-files" */ './files/InventoryFiles')
)

const AlcoholTlogs = lazy(
  () => import(/* webpackChunkName: "alcohol-tlogs" */ './alcohol/AlcoholTlogs')
)

class Reports extends Component<RetailerRouteComponentProps> {
  render() {
    return (
      <Suspense fallback={<Loading />}>
        <RetailerSwitch>
          <RetailerRoute
            route="files"
            exact
            render={props => (
              <RetailerScopeWrapper retailerSelectionOptional={true}>
                <DocumentTitle titleId="reports.files.title">
                  <InventoryFiles {...props} />
                </DocumentTitle>
              </RetailerScopeWrapper>
            )}
            accessControl={InventoryFilesAccessControl}
          />
          <RetailerRoute
            route="alcohol-tlogs"
            exact
            render={props => (
              <RetailerScopeWrapper formattedMessageTitle={'alcoholTlogs.title'}>
                <DocumentTitle titleId="alcoholTlogs.title">
                  <AlcoholTlogs {...props} />
                </DocumentTitle>
              </RetailerScopeWrapper>
            )}
            accessControl={AlcoholTlogsAccessControl}
          />
          <RetailerRoute
            route="pickupAnalytics"
            exact
            component={ReportsPickupAnalyticsPage}
            // @ts-ignore-next-line
            accessControl={ReportsPickupAnalyticsAccessControl}
            scopePicker={{
              formattedMessageTitle: 'reportsDomain.pickupAnalytics.pageTitle',
            }}
          />

          {/* routes from the Reports domain */}
          <RetailerRoute
            route={'instacart-managed-reports-list' as unknown as RouteName}
            exact
            component={InstacartManagedReportsListPage}
            accessControl={
              InstacartManagedReportsListPageAccessControl as unknown as AccessControlConfig
            }
          />
          <RetailerRoute
            route={'transaction-logs-list' as unknown as RouteName}
            exact
            component={TransactionLogsListPage}
            accessControl={TransactionLogsListPageAccessControl as unknown as AccessControlConfig}
          />

          <Redirect
            from="/partners/:partner_id/warehouses/:warehouse_id/reports"
            to="/partners/:partner_id/warehouses/:warehouse_id/reports/files"
          />
        </RetailerSwitch>
      </Suspense>
    )
  }
}

export default Reports
