/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react'

import * as React from 'react'
import { getDisplayName } from '../common/reactUtils'

interface Opts {
  displayName: string
  required?: boolean
}

interface RequiredOpts extends Opts {
  required: true
}

type ContextHoc<T> = <P extends T>(
  WrappedComponent: React.ComponentType<P>
) => React.FunctionComponent<Omit<P, keyof T>>

export function createContextHoc<T>(
  Context: React.Context<T | null>,
  opts: RequiredOpts
): ContextHoc<T>
export function createContextHoc<T>(Context: React.Context<T>, opts: Opts): ContextHoc<T>
export function createContextHoc<T>(
  Context: React.Context<T>,
  { required, displayName }: Opts = { displayName: 'withContext' }
): ContextHoc<T> {
  return function withContext<P extends T>(
    WrappedComponent: React.ComponentType<P>
  ): React.FunctionComponent<Omit<P, keyof T>> {
    function WithContext(props: Omit<P, keyof T>) {
      return (
        <Context.Consumer>
          {context => {
            if (required && !context) {
              throw new Error(`Context ${Context.displayName} not provided`)
            }

            // cast temporarily
            // Workaround for https://github.com/microsoft/TypeScript/issues/28884
            return <WrappedComponent {...context} {...(props as P)} />
          }}
        </Context.Consumer>
      )
    }

    WithContext.displayName = `${displayName}(${getDisplayName(WrappedComponent)})`

    return WithContext
  }
}

export function useRequiredContext<T>(context: React.Context<T | null>): T {
  const contextValue = useContext(context)

  if (!contextValue) {
    throw new Error(`Context ${context.displayName} not provided`)
  }

  return contextValue
}
