import { type RedirectProps, Redirect } from 'react-router'
import { type DashRouteName } from '../../../utils/routing/routes'
import { useCoreContext } from '../../../utils/core/RPPCoreContext'

type ToProps<TToRoute> =
  | { to?: undefined; toRoute: TToRoute }
  | { to: RedirectProps['to']; toRoute?: undefined }

// Note that the fromRoute and toRoute  props are used by the DashSwitch to determine the Route's from/to values
export type DashRedirectProps<TToRoute, TFromRoute> = Pick<RedirectProps, 'exact' | 'push'> &
  ToProps<TToRoute> & {
    fromRoute?: TFromRoute
  }

export function DashRedirect<TToRoute extends DashRouteName, TFromRoute extends DashRouteName>({
  fromRoute,
  toRoute,
  ...rest
}: DashRedirectProps<TToRoute, TFromRoute>) {
  const { routesByName } = useCoreContext()
  // `to` and `from` can be injected by the DashSwitch
  const redirectProps = { ...(rest as RedirectProps) }

  // If they haven't been injected, we add them ourselves
  if (!redirectProps.to && toRoute) {
    redirectProps.to = routesByName[toRoute]
  }

  // We don't need to check if they have access to the fromRoute
  if (!redirectProps.from && fromRoute) {
    redirectProps.from = routesByName[fromRoute]
  }

  return <Redirect {...redirectProps} />
}
