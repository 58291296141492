import { forwardRef } from 'react'
import { css } from '@emotion/react'
import { fontSize } from '../../foundation/fontSize'
import { colors } from '../../foundation/colors'
import { type EmotionStyles } from '../../utils/styling/types'
import { type TestID } from '../../utils/testing/types'
import { Button, type ButtonProps } from '../button/Button'

export interface TextButtonProps extends ButtonProps, EmotionStyles, TestID {}

const textButtonStyles = css({
  height: 18,
  paddingLeft: 2,
  paddingRight: 2,
  fontSize: fontSize.X12,
  color: colors.PRIMARY.REGULAR,
  background: 'none',
  '&:hover': {
    background: 'none',
    color: colors.GRAYSCALE.X70,
  },
  '&:disabled': {
    background: 'none',
    color: colors.GRAYSCALE.X50,
  },
})

export const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
  ({ styles, ...rest }, ref) => (
    <Button styles={css(textButtonStyles, styles)} ref={ref} {...rest} />
  )
)
