import type { PropsWithChildren, FunctionComponent } from 'react'
import { cloneElement, useMemo } from 'react'
import { usePendingPartnerOnboardingRedirection } from '@retailer-platform/domain-onboarding'
import { RouteSuspense } from '../../../legacy/dash-components/route-suspense/RouteSuspense'
import { useCoreContext } from '../../../utils/core/RPPCoreContext'
import { DashSwitch } from '../../../exports/routing'

export const PartnerScopedRoutes: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const coreContext = useCoreContext()

  const scopedRouters = useMemo(
    () => coreContext.partnerScopedRouters.map(el => cloneElement(el, { key: el.props.route })),
    [coreContext]
  )

  const { loading } = usePendingPartnerOnboardingRedirection()

  if (loading) {
    return null
  }

  return (
    <RouteSuspense>
      <DashSwitch>{scopedRouters}</DashSwitch>
    </RouteSuspense>
  )
}
