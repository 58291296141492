import type { PropsWithChildren, FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { colors, Button } from 'ic-snacks'
import { type OnClick } from '../../../../../../../../utils/react/event.types'

const AddAllContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${colors.GRAY_93};
  padding: 15px 30px;
`

interface Props {
  onClick: OnClick
  disabled: boolean
  numStores: number
}

export const StoreGroupsFormLocationSelectorAddAll: FunctionComponent<PropsWithChildren<Props>> = ({
  onClick,
  disabled,
  numStores,
}) => (
  <AddAllContainer>
    <Button
      snacksStyle="primary"
      size="small"
      onClick={onClick}
      disabled={disabled}
      elementAttributes={{ 'data-testid': 'store-location-selector-add-all' }}
    >
      <FormattedMessage id="settings.storeGroups.addModal.addAll" values={{ numStores }} />
    </Button>
  </AddAllContainer>
)
