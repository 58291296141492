import type { PropsWithChildren, FC } from 'react'
import { Children, useMemo } from 'react'
import { sortBy } from 'lodash'
import { useNavContext } from '../../../nav/utils/NavContext'
import { SubOption } from './SubOption'
import { Option } from './Option'

type Props = {
  navEntryPoint: string
  onClose?: () => void
  optionType: 'option' | 'subOption'
}

export const NavEntryPointGroup: FC<PropsWithChildren<Props>> = ({
  children,
  navEntryPoint,
  onClose,
  optionType,
}) => {
  const { navigationEntries } = useNavContext()

  const allChildren = useMemo(() => {
    const domainNavItems =
      navigationEntries[navEntryPoint]?.map((e, idx) =>
        optionType === 'option' ? (
          <Option
            onClick={onClose}
            key={idx}
            title={e.labelId}
            route={e.route}
            position={e.positionNavV2}
            accessControl={e.accessControl}
          />
        ) : (
          <SubOption
            onClick={onClose}
            key={idx}
            title={e.labelId}
            route={e.route}
            position={e.positionNavV2}
            accessControl={e.accessControl}
          />
        )
      ) || []

    let allChildren = [...Children.toArray(children), ...domainNavItems]
    allChildren = sortBy(allChildren, child => child.props.position || 0)

    return allChildren
  }, [children, navEntryPoint, navigationEntries, onClose, optionType])

  return <>{allChildren}</>
}
