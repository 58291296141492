import styled from '@emotion/styled'
import React from 'react'
import { type Color, IssueIcon } from '@instacart/ids-core'
import { type CSSObject } from '@emotion/react'
import { Text, useTDSContext } from '../../tds'
import { Text as LegacyText } from '../text/Text'
import { colors, spacing } from '../../foundation'
import { useMessages } from '../../utils/intl/intl.hooks'
export const FormField = styled.div<{ noMargin?: boolean }>(
  {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.X4,
  },
  ({ noMargin }) => ({ paddingBottom: noMargin ? 0 : spacing.X24 })
)

export const FormFieldLabel: React.FunctionComponent<
  React.PropsWithChildren<React.HTMLProps<'label'> & { optional?: boolean }>
> = ({ children, optional, ...props }) => {
  const { useTDS } = useTDSContext()
  const messages = useMessages({
    optional: 'sharedComponents.formField.optional',
  })
  return useTDS ? (
    <Text css={{ marginBottom: `${spacing.X8} !important` }} typography="bodyEmphasized">
      {children}

      {optional && (
        <Text
          css={{ display: 'inline', marginLeft: spacing.X4, color: colors.GRAYSCALE.X50 }}
          typography="accentRegular"
        >
          {messages.optional}
        </Text>
      )}
    </Text>
  ) : (
    <LegacyText {...props} as="label" weight="semibold" size="medium">
      {children}

      {optional && (
        <LegacyText
          css={{ display: 'inline', marginLeft: spacing.X4, color: colors.GRAYSCALE.X50 }}
        >
          {messages.optional}
        </LegacyText>
      )}
    </LegacyText>
  )
}
export const FormFieldDescription: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, ...props }) => {
  const { useTDS } = useTDSContext()
  return useTDS ? (
    <Text {...props} typography="accentRegular">
      {children}
    </Text>
  ) : (
    <LegacyText {...props} size="small">
      {children}
    </LegacyText>
  )
}

export const FormFieldFeedback: React.FunctionComponent<
  React.PropsWithChildren<
    React.HTMLProps<'label'> & {
      icon: React.ComponentType<React.PropsWithChildren<{ size: number; color: Color }>>
      iconColor?: Color
      className?: string
      containerStyles?: CSSObject
    }
  >
> = ({ children, icon: Icon, iconColor, containerStyles = {}, ...props }) => {
  const { useTDS } = useTDSContext()
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: spacing.X4,
        ...containerStyles,
      }}
    >
      <Icon size={13} color={iconColor || 'systemGrayscale70'} />

      {useTDS ? (
        <Text typography="accentRegular" css={{ color: colors.GRAYSCALE.X70 }}>
          {children}
        </Text>
      ) : (
        <LegacyText {...props} as="label" size="small" css={{ color: colors.GRAYSCALE.X70 }}>
          {children}
        </LegacyText>
      )}
    </div>
  )
}

export const FormFieldError: React.FunctionComponent<
  React.PropsWithChildren<
    React.HTMLProps<'label'> & {
      icon?: React.ComponentType<React.PropsWithChildren<{ size: number; color: Color }>>
      containerStyles?: CSSObject
    }
  >
> = ({ icon: Icon = IssueIcon, ...props }) => (
  <FormFieldFeedback
    {...props}
    css={{ color: colors.DETRIMENTAL.REGULAR }}
    icon={Icon}
    iconColor="systemDetrimentalRegular"
  />
)
