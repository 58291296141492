import { ICPlatformVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const creativeManagerAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.CreativeManagerView],
    minICPlatformVersion: ICPlatformVersion.r2023_12_0,
    maxICPlatformVersion: ICPlatformVersion.r2024_12_0,
  },
]

export const creativeManagerV2AccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.CreativeManagerView],
    minICPlatformVersion: ICPlatformVersion.r2025_01_0,
  },
]

export const creativeManagerModifyAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.CreativeManagerModify],
    minICPlatformVersion: ICPlatformVersion.r2023_12_0,
  },
]
