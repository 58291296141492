const EN_US = {
  'foodstormDomain.title': 'FoodStorm',
  'foodstormDomain.educationalBanner.heading':
    'Prepare for the Easter catering rush with FoodStorm',
  'foodstormDomain.educationalBanner.information':
    'If you offer prepared foods or holiday catering, now is the time to prepare for Easter. FoodStorm can help bring your assortment online, streamline in-store production and grow your perimeter sales.',
  'foodstormDomain.educationalBanner.buttonText': 'Get Started Today',
} as const

export default EN_US
