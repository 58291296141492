import type { FunctionComponent } from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'
import { getLeafNodes, type Option } from './utils'
import MultiScopeDropdownOptionContainer from './MultiScopeDropdownOptionContainer'

interface Props {
  options: Option[]
  selectedState: Record<string, Option>
  setSelectedState: (state: Record<string, Option>) => void
  multiSelect: boolean
  onSingleSelect: (option: Option) => void
}

const LoadMoreBannerText = styled.div({
  fontFamily: 'Instacart Sans Text v1.1',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  textDecorationLine: 'underline',
  color: '#0A5546',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
  cursor: 'pointer',
})

const MultiScopeDropdownHiddenOptionContainer: FunctionComponent<Props> = ({
  options,
  selectedState,
  setSelectedState,
  multiSelect,
  onSingleSelect,
}) => {
  const [expanded, setExpanded] = useState(false)

  const leafNodes = getLeafNodes(options)

  return (
    <div>
      {expanded ? (
        options.map(option => (
          <div key={option.id}>
            <MultiScopeDropdownOptionContainer
              option={option}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              multiSelect={multiSelect}
              onSingleSelect={onSingleSelect}
            />
          </div>
        ))
      ) : (
        <LoadMoreBannerText onClick={() => setExpanded(true)}>
          Show {leafNodes.length} more...
        </LoadMoreBannerText>
      )}
    </div>
  )
}

export default MultiScopeDropdownHiddenOptionContainer
