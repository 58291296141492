// @ts-nocheck legacy file transformed to TS
import { Component } from 'react'
import styled from '@emotion/styled'
import LoadingDots from './LoadingDots'

const Container = styled.div`
  width: 18px;
  position: relative;
  ${props =>
    props.centered
      ? `
      top: 300px;
      left: 50%;
    `
      : ''};
`

const Spinner = props => (
  <Container {...props}>
    <LoadingDots height={20} />
  </Container>
)

export default Spinner
