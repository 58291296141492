import { HeaderTitlePortal, HeaderExtraContentPortal } from './RetailerScopeWrapper'

interface HeaderPortalProps {
  /**
   * Displayed above the scope picker
   */
  headerComponent: JSX.Element
  /**
   * Displayed below the scope picker
   */
  headerExtraContent?: JSX.Element
}

export const HeaderPortal = ({ headerComponent, headerExtraContent }: HeaderPortalProps) => (
  <>
    <HeaderTitlePortal>{headerComponent}</HeaderTitlePortal>
    <HeaderExtraContentPortal>{headerExtraContent}</HeaderExtraContentPortal>
  </>
)
