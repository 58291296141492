import type { PropsWithChildren, FunctionComponent } from 'react'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { Drawer } from '@mantine/core'
import { type NavMenuHierarchy } from '../../NavMenuHierarchy'
import { MENU_BAR_Z_INDEX } from '../navBar.constants'
import { NavMenuTopL1Item } from './NavMenuTopL1Item'
import { NavMenuTopDropdown } from './NavMenuTopDropdown'

type Props = {
  currentlySelectedItem: number
  setCurrentlySelectedItem: (value: number) => void
  navMenuHierarchy: NavMenuHierarchy
}

export const NavMenuTopDrawer: FunctionComponent<PropsWithChildren<Props>> = ({
  currentlySelectedItem,
  setCurrentlySelectedItem,
  navMenuHierarchy,
}) => {
  const drawerOpened = currentlySelectedItem !== null

  const closeDrawer = () => {
    setCurrentlySelectedItem(null)
  }

  const topBarItems = navMenuHierarchy.hierarchy.map((l1, idx) => (
    <NavMenuTopL1Item
      key={idx}
      l1Key={idx}
      l1={l1}
      title={l1.name}
      setCurrentlySelectedItem={setCurrentlySelectedItem}
      currentlySelectedItem={currentlySelectedItem}
      hoverColor={navMenuHierarchy.navBarColorHover}
      backgroundColor={navMenuHierarchy.navBarColor}
    />
  ))

  return (
    <>
      {topBarItems}
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        withCloseButton={false}
        size={`auto`}
        padding={0}
        position="top"
        transitionProps={{ duration: 150 }}
        zIndex={MENU_BAR_Z_INDEX - 1}
        styles={{
          content: {
            height: 'auto',
          },
        }}
      >
        {/* autoFocus hidden element first */}
        <div tabIndex={-1} data-autofocus />
        <NavMenuTopDropdown
          closeDrawer={closeDrawer}
          currentlySelectedItem={currentlySelectedItem}
          navMenuHierarchy={navMenuHierarchy}
        />
      </Drawer>
    </>
  )
}
