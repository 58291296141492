import { ICPlatformVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'
import { FeatureEntitlements } from './featureEntitlements'

export const retailerCollectionsV2AccessControl: DomainAccessControlConfig = {
  // warehouseFeatureToggles: [FeatureToggle.retailerCollectionsV2],
  permissions: [Permission.RetailerCollectionsV2],
}

export const retailerCollectionsV2RetailerAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.retailerCollectionsV2],
  permissions: [Permission.RetailerCollectionsV2],
  maxICPlatformVersion: ICPlatformVersion.r2024_12_0,
}

export const retailerCollectionsV2AdminAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.retailerCollectionsV2Preview],
  notWarehouseFeatureToggles: [FeatureToggle.retailerCollectionsV2],
  permissions: [Permission.Admin],
}

export const legacyDepartmentsAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.retailerCollectionsDepartmentsAisles],
}

export const dynamiclyFedStaticCollectionsAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.Admin],
  warehouseFeatureToggles: [FeatureToggle.DynamiclyFedStaticCollections],
}

export const pinnedProductsAccessControl: DomainAccessControlConfig = [
  {
    // individual warehouse toggle for testing
    // reuse manual sort order warehouse toggle and only when retailer is entitled to have this feature
    warehouseFeatureToggles: [FeatureToggle.RetailerCollectionsManualSortOrderWarehouse],
    featureEntitlementIds: [FeatureEntitlements.CollectionProductPinning],
  },
  {
    // version tied
    featureEntitlementIds: [FeatureEntitlements.CollectionProductPinning],
    minICPlatformVersion: ICPlatformVersion.r2024_02_0,
  },
]

export const retailerCollectionsManualSortControl: DomainAccessControlConfig = [
  {
    // pre-release testing
    featureToggles: [FeatureToggle.RetailerCollectionsManualSortOrder],
    featureEntitlementIds: [FeatureEntitlements.CollectionProductPinning],
    warehouseFeatureToggles: [FeatureToggle.RetailerCollectionsManualSortOrderWarehouse],
  },
  {
    // reuse pinning entitlement
    featureToggles: [FeatureToggle.RetailerCollectionsManualSortOrder],
    featureEntitlementIds: [FeatureEntitlements.CollectionProductPinning],
    minICPlatformVersion: ICPlatformVersion.r2024_04_0,
  },
]

export const collectionDuplicationAccessControl: DomainAccessControlConfig = {
  minICPlatformVersion: ICPlatformVersion.r2024_09_0,
}

export const adminAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.Admin],
}
