import type { PropsWithChildren, ComponentType, FunctionComponent } from 'react'
import { useState, Fragment } from 'react'
import styled from '@emotion/styled'
import { Redirect } from 'react-router'
import Modal from '../Modal'
import deprecatedAnalytics from '../../common/deprecatedAnalytics'
import { url } from '../../../utils/parsing/url'
import { URL_DASHBOARD } from '../../../utils/routing/routes'
import { useTrackEvent } from '../../../utils/events/useTrackEvent.hook'
import { useDeepEffectTrackEvent } from '../../../utils/events/hooks'
import Spinner from '../Spinner'
import { AppAdminSectionAccessControl } from '../../../sections/admin/AdminSection.configuration'
import useAccessControl from '../AccessControl/useAccessControl'
import { LinkTextButton } from '../../../gin-and-tonic/routing/link-text-button/LinkTextButton'
import clientEnv from '../../../utils/global/clientEnv'
import { useCurrentAccountDetails } from '../../../api/current-account/currentAccount.hooks'
import { ColumnBase } from './ListComponents'
import BannerList from './BannerList'
import PartnerList from './PartnerList'

const BodyRow = styled.div<{ autoSelectActive?: boolean }>`
  display: ${props => (props.autoSelectActive ? 'none' : 'flex')};
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

export interface Props {
  initialPartnerId?: number | undefined
  beforeRedirect?: () => void
  handleClose: () => void
  autoSelect?: boolean
}

const SelectPartnerWarehouse: FunctionComponent<PropsWithChildren<Props>> = ({
  initialPartnerId,
  beforeRedirect,
  handleClose,
  autoSelect,
}) => {
  useDeepEffectTrackEvent({
    id: 'partner_retailer_selector.viewed',
    description: 'Viewed the partner/retailer selector',
  })

  const { account } = useCurrentAccountDetails()

  const [selectedPartnerId, selectPartner] = useState<number | undefined>(initialPartnerId)

  const [selectedWarehouseId, selectWarehouse] = useState<number | undefined>(undefined)

  const [autoSelectActive, toggleAutoSelect] = useState<boolean | undefined>(autoSelect)

  const hasAccess = useAccessControl()
  const hasAppAdminAccess = hasAccess(AppAdminSectionAccessControl)

  const trackEvent = useTrackEvent()

  if (selectedPartnerId && selectedWarehouseId) {
    const redirectDashboardUrl = url(URL_DASHBOARD, {
      partner_id: selectedPartnerId.toString(),
      warehouse_id: selectedWarehouseId.toString(),
    })

    // Trigger any side effects before redirecting
    // Used to ensure the modal is closed properly
    if (beforeRedirect) beforeRedirect()

    return <Redirect push to={redirectDashboardUrl} />
  }

  const handleSelectPartner = (nextId: number) => {
    if (!account) return

    deprecatedAnalytics.track('nav.warehouse_view', {
      user_id: account.canonicalId,
      partner_id: nextId,
    })

    if (!autoSelectActive) {
      trackEvent({
        id: 'partner_retailer_selector.selected_partner',
        description: 'Selected a specific partner within the partner/retailer selection section',
        data: {
          partnerId: nextId,
        },
      })
    }

    selectPartner(nextId)
  }

  const handleSelectWarehouse = (nextId: number) => {
    if (!account || !selectedPartnerId) return

    if (!autoSelectActive) {
      trackEvent({
        id: 'partner_retailer_selector.selected_retailer',
        description: 'Selected a specific retailer within the partner/retailer selection section',
        data: {
          partnerId: selectedPartnerId,
          retailerId: nextId,
          warehouse_id: nextId,
        },
      })
    }

    selectWarehouse(nextId)
  }

  const loadingSpinner = (
    <ColumnBase style={{ flex: 1, height: '700px' }}>
      <Spinner style={{ position: 'absolute', left: '50%', top: '50%' }} />
    </ColumnBase>
  )

  return (
    <Modal width={1200} height={900} onClose={handleClose}>
      {(
        Header: ComponentType<PropsWithChildren<unknown>>,
        Body: ComponentType<PropsWithChildren<unknown>>,
        Footer: ComponentType<PropsWithChildren<unknown>>
      ) => [
        <Header>Change Banner</Header>,
        <Body>
          <BodyRow autoSelectActive={autoSelectActive} data-testid="select-partner-warehouse-body">
            <PartnerList
              selectedPartnerId={selectedPartnerId}
              selectPartner={handleSelectPartner}
              autoSelect={autoSelectActive}
              toggleAutoSelect={toggleAutoSelect}
            />
            <BannerList
              selectedPartnerId={selectedPartnerId}
              selectWarehouse={handleSelectWarehouse}
              autoSelect={autoSelectActive}
              toggleAutoSelect={toggleAutoSelect}
            />
          </BodyRow>
          {autoSelectActive && loadingSpinner}
        </Body>,
        <Footer>
          {hasAppAdminAccess && (
            <Fragment>
              <LinkTextButton
                messageId="app.admin.nav.links.instacartAdmin"
                toRoute="app-admin"
                data-testid="select-partner-warehouse-admin-link"
              />
              <LinkTextButton
                messageId="app.admin.nav.links.theGarden"
                to="/partners/289/warehouses/1000/dashboard"
                data-testid="select-partner-warehouse-garden-link"
              />
            </Fragment>
          )}
        </Footer>,
      ]}
    </Modal>
  )
}

export default SelectPartnerWarehouse
