import type { PropsWithChildren, FunctionComponent } from 'react'
import { useMemo } from 'react'
import Table from '../../../../../../../../components/Table/Table'
import { type StoreLocationDetails } from '../../utils/locations.hooks'
import { useStoreGroupFormLocationTableColumns } from './utils/tableColumns.hooks'
import { useStoreGroupsFormLocationPagination } from './utils/tablePagination.hooks'
import {
  type Retailer,
  useGetRetailersForPartnerQuery,
} from '../../../../../../../../../__codegen__/api'
import { usePartnerId } from '../../../../../../../../../utils/routing/params.hooks'

interface Props {
  locations: StoreLocationDetails[]
  isLocationSelected: (id: string) => boolean
  handleToggleLocation: (id: string) => void
  showActionButtons?: boolean
}

const getTableProps = () => ({
  style: {
    height: 'auto',
  },
})

export const StoreGroupsFormLocationSelectorTable: FunctionComponent<PropsWithChildren<Props>> = ({
  locations,
  isLocationSelected,
  handleToggleLocation,
  showActionButtons = true,
}) => {
  const columns = useStoreGroupFormLocationTableColumns({
    isLocationSelected,
    handleToggleLocation,
    showActionButtons,
  })

  const { data, meta, onPageChange, minRows } = useStoreGroupsFormLocationPagination({
    locations,
  })

  const partnerId = usePartnerId()

  const { data: partnerData } = useGetRetailersForPartnerQuery({
    variables: { partnerId: partnerId },
  })

  const dataWithRetailerName = useMemo(() => {
    const allRetailers = partnerData?.partner?.retailers || []
    const retailersById: Record<string, Retailer> = allRetailers.reduce((acc, r) => {
      acc[r.id] = r
      return acc
    }, {})

    return data?.map(d => ({
      ...d,
      retailerName: retailersById[d.retailerId]?.name,
    }))
  }, [data, partnerData])

  return (
    <Table
      columns={columns}
      data={dataWithRetailerName}
      meta={meta}
      onPageChange={onPageChange}
      getTableProps={getTableProps}
      minRows={minRows}
      paginated
    />
  )
}
