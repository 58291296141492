import type { PropsWithChildren, FunctionComponent } from 'react'
import { css, type SerializedStyles } from '@emotion/react'
import { type EmotionStyles } from '../../utils/styling/types'
import { fontSize } from '../../foundation/fontSize'
import { type DashMessages } from '../../../intl/intl.types'
import { useDashMessage } from '../../../intl/intl.hooks'
import { type TestID } from '../../utils/testing/types'
import { spacing } from '../../foundation/spacing'
import { type TypographySizes } from '../../foundation/types'

export interface Props extends EmotionStyles, TestID {
  messageId?: DashMessages
  size?: TypographySizes
  className?: string
}

const textStyles = css({
  marginTop: spacing.X0,
  marginBottom: spacing.X0,
  marginLeft: spacing.X8,
  marginRight: spacing.X8,
})

const sizeStyles: { [key in TypographySizes]: SerializedStyles } = {
  small: css({
    fontSize: fontSize.X12,
    lineHeight: '18px',
  }),
  medium: css({
    fontSize: fontSize.X15,
    lineHeight: '22px',
  }),
  large: css({
    fontSize: fontSize.X18,
    lineHeight: '28px',
  }),
}

export const Text: FunctionComponent<PropsWithChildren<Props>> = ({
  messageId,
  styles,
  children,
  size = 'small',
  ...rest
}) => {
  const message = useDashMessage(messageId)

  return (
    <div css={css(sizeStyles[size], textStyles, styles)} {...rest}>
      {message}
      {children}
    </div>
  )
}
