import type { PropsWithChildren, FunctionComponent } from 'react'
import { useState } from 'react'
import { colors, useBooleanState } from '@retailer-platform/shared-components'
import { useRouteMatch } from 'react-router'
import { useCoreContext } from '../../../../utils/core/RPPCoreContext'
import { URL_APP_ADMIN } from '../../../../utils/routing/routes'
import { useLocalForageKey } from '../../../../utils/local-storage/useLocalForageKey.hooks'
import { NavContext } from './NavContext'
import { type NavContextValue } from './nav.types'

interface NavProviderProps {}

const navStyleContext = {
  'retailer-scoped': {
    backgroundColor: colors.GRAYSCALE.X70,
    contentColor: colors.GRAYSCALE.X0,
    iconColor: 'systemGrayscale00',
  },
  admin: {
    backgroundColor: colors.GRAYSCALE.X10,
    contentColor: colors.GRAYSCALE.X50,
    iconColor: 'systemGrayscale50',
  },
  'dev-portal': {
    backgroundColor: colors.GRAYSCALE.X0,
    contentColor: colors.GRAYSCALE.X70,
    iconColor: 'systemGrayscale50',
  },
} as const

type NavV2EnabledStorageProps = {
  navV2Enabled: boolean
}

export const NavProvider: FunctionComponent<PropsWithChildren<NavProviderProps>> = ({
  children,
}) => {
  const [isNavOpen, openNav, closeNav] = useBooleanState(false)
  const [folderControlledByTour, setFolderControlledByTour] = useState('')
  const [isBlockedByModal, setIsBlockedByModal] = useState(false)
  const [isNavVisible, showNav, hideNav] = useBooleanState(true)
  const [isSideNavVisible, showSideNav, hideSideNav] = useBooleanState(true)

  const [allowNavV1RouletteEnabled, setAllowNavV1RouletteEnabled] = useState(false)

  const { data, setData: setNavV2Enabled } =
    useLocalForageKey<NavV2EnabledStorageProps>('navV2Enabled')

  const navPreference = data?.navV2Enabled ?? true
  const navV2Enabled = allowNavV1RouletteEnabled ? navPreference : true
  const hadOptedOutNavV2 = data?.navV2Enabled === false

  const [mostRecentClickedRoute, setMostRecentClickedRoute] = useState(null)

  const adminMatch = useRouteMatch(URL_APP_ADMIN)
  const getNavType = () => {
    if (adminMatch && navV2Enabled) return 'admin-v2'
    if (adminMatch && !navV2Enabled) return 'admin'
    if (navV2Enabled) return 'retailer-scoped-v2'
    return 'retailer-scoped'
  }
  const navType = getNavType()

  const {
    domainNavigationEntries = {},
    domainNavigationEntriesV2 = {},
    domainAdminNavigationEntries = {},
    domainAdminNavigationEntriesV2 = {},
  } = useCoreContext()

  const domainEntry = () => {
    if (navType === 'admin-v2') return domainAdminNavigationEntriesV2
    if (navType === 'admin') return domainAdminNavigationEntries
    if (navType === 'retailer-scoped') return domainNavigationEntries
    if (navType === 'retailer-scoped-v2') return domainNavigationEntriesV2
  }

  const navTypeSpecificContext = {
    navigationEntries: domainEntry(),
    ...navStyleContext[navType],
  }

  const contextValue: NavContextValue = {
    isNavOpen,
    openNav,
    closeNav,
    folderControlledByTour,
    setFolderControlledByTour,
    isBlockedByModal,
    setIsBlockedByModal,
    allowNavV1RouletteEnabled,
    setAllowNavV1RouletteEnabled,
    navV2Enabled,
    setNavV2Enabled: enabled => setNavV2Enabled({ navV2Enabled: enabled }),
    mostRecentClickedRoute,
    setMostRecentClickedRoute,
    isNavVisible,
    showNav,
    hideNav,
    isSideNavVisible,
    showSideNav,
    hideSideNav,
    hadOptedOutNavV2,
    ...navTypeSpecificContext,
  }

  return <NavContext.Provider value={contextValue}>{children}</NavContext.Provider>
}
