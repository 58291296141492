import type { PropsWithChildren, FunctionComponent } from 'react'
import { Fragment } from 'react'
import { SparkleIcon, LogOutIcon, StoreIcon, TechnicalIcon } from '@instacart/ids-core'
import { NavItem } from '../NavItem'
import { useNavContext } from '../../utils/NavContext'
import { useCurrentAccountDetails } from '../../../../../api/current-account/currentAccount.hooks'
import { useTrackEvent } from '../../../../../utils/events/useTrackEvent.hook'
import { useAdminControls } from '../../../../../utils/contexts/admin-controls/AdminControlsContext'
import { AdminControlModal } from '../../../admin-controls-modal/AdminControlsModal'

export const AdminNavFooterContent: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const { account: currentAccount } = useCurrentAccountDetails()
  const { allowNavV1RouletteEnabled, setNavV2Enabled, closeNav } = useNavContext()
  const { openAdminControls } = useAdminControls()

  const trackEvent = useTrackEvent()

  return (
    <Fragment>
      {allowNavV1RouletteEnabled && (
        <NavItem
          isMainItem
          Icon={SparkleIcon}
          title="navV2.userSettings.switchToNavV2"
          onClick={() => {
            setNavV2Enabled(true)
            closeNav()
            trackEvent({
              id: 'navV2.navigation.switchToNavV2',
              description: 'Tracks whenever the user switches to NavV2 from the side navigation',
              data: {
                accountID: currentAccount?.id,
              },
            })
          }}
        />
      )}
      <>
        <NavItem
          isMainItem
          Icon={TechnicalIcon}
          title="app.admin.nav.links.adminControls"
          onClick={openAdminControls}
        />
        <AdminControlModal />
      </>
      <NavItem title="app.admin.nav.links.retailerDashboard" route="scope" Icon={StoreIcon} />
      <NavItem title="app.admin.nav.links.logOut" route="log-out" Icon={LogOutIcon} />
    </Fragment>
  )
}
