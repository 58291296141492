/* eslint-disable react/prop-types */
import _ from 'lodash'
import type { PropsWithChildren, ComponentType, FunctionComponent } from 'react'
import { useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import Bluebird from 'bluebird'
import { StringParam, useQueryParams } from 'use-query-params'
import { useParams } from 'react-router-dom'
import instacart from '../../../../../common/instacart'
import { RegionLocationContext } from '../../../../../contexts/regionLocation/RegionLocationContext'
import { usePartnerId, useRetailerId } from '../../../../../../utils/routing/params.hooks'
import { type Items } from '../../../types/items.types'
import useAccessControl from '../../../../../components/AccessControl/useAccessControl'
import { type Query } from './ProductLocations'

export const useProductLocationsHelper = () => {
  const hasAccess = useAccessControl()
  const partnerId = usePartnerId()
  const retailerId = useRetailerId()
  // This accounts for the old and new routes
  const { productId, product_id } = useParams<{
    productId?: string
    product_id?: string
  }>()
  const [queryParams] = useQueryParams({
    inventory_area_ids: StringParam,
    region_ids: StringParam,
    item_ids: StringParam,
  })

  const resolvedProductId = productId || product_id
  const { inventoryAreas } = useContext(RegionLocationContext)
  const allInventoryAreaIds = useMemo(() => {
    if (
      hasAccess({
        // @ts-expect-error, throwaway feature toggle
        warehouseFeatureToggles: ['rt_product_locations_chunking'],
      })
    ) {
      return inventoryAreas.map(v => v.id.toString())
    }

    // Undefined means letting the server decide which inventory areas to use (previous behavior)
    return [undefined]
  }, [hasAccess, inventoryAreas])

  const result = useQuery<
    Items,
    unknown,
    Items,
    [string, { resolvedProductId: string; queryParams: Query; allInventoryAreaIds: string[] }]
  >({
    staleTime: Infinity,
    queryKey: [
      'productLocationsData',
      // This defines dependencies for the query, when any of these change, the query will get executed again
      { resolvedProductId, queryParams, allInventoryAreaIds },
    ],
    queryFn: () => {
      const resolvedInventoryAreas = queryParams.inventory_area_ids
        ? queryParams.inventory_area_ids.split(',')
        : allInventoryAreaIds
      const chunkedInventoryAreaIds = _.chunk(resolvedInventoryAreas, 50)

      /**
       * We go through each chunk with a concurrency of 5.
       * This is to avoid hitting the 30s timeout on the backend when all the inventory area ids are used (the default).
       *
       * If there is a defined inventory_area_id(s) from query params, we use those.
       * These ids are scoped for access in the API.
       */
      return Bluebird.map(
        chunkedInventoryAreaIds,
        chunk => {
          const localQuery = {
            ...queryParams,
            // This can be undefined if the retailer has no access to the feature
            inventory_area_ids: chunk?.join(','),
          }

          return instacart.go
            .get(
              `/v1/partners/${partnerId}/warehouses/${retailerId}/products/${resolvedProductId}/items`,
              { query: localQuery }
            )
            .then(({ data }) => data as Items)
        },
        { concurrency: 5 }
      ).then(results => results.flat())
    },
  })

  const itemIds = useMemo(() => {
    if (!result.data) return []

    return result.data.map(({ id }) => id)
  }, [result.data])
  return { ...result, itemIds }
}

export const withLegacyProductLocationsHelper = <T,>(
  Component: ComponentType<PropsWithChildren<T>>
): FunctionComponent<PropsWithChildren<T>> => {
  return props => {
    const { isError, isLoading, data, refetch, itemIds } = useProductLocationsHelper()

    return (
      <Component
        {...props}
        isError={isError}
        isLoading={isLoading}
        data={data}
        refetch={refetch}
        itemIds={itemIds}
      />
    )
  }
}
