// @ts-nocheck legacy file transformed to TS
import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import ReactTable, { ReactTableDefaults } from 'react-table'
import { Icon, colors, Text } from 'ic-snacks'
import map from 'lodash/map'
import styled from '@emotion/styled'

import withRetailerRouter from '../../common/withRouter'

import TablePaginator from './TablePaginator'
import TableLoading from './TableLoading'

const ErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  text-align: center;
  padding: 40px;
`

Object.assign(ReactTableDefaults, {
  LoadingComponent: TableLoading,
  PaginationComponent: TablePaginator,
})

const HeaderContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-align: left;
`

const HeaderTitle = styled.span`
  margin-right: 8px;
`

const CellContainer = styled.div`
  color: ${colors.GRAY_46};
  font-size: 14px;
`

class Table extends Component {
  static propTypes = {
    data: PropTypes.array,
    onClickRow: PropTypes.func,
    paginated: PropTypes.bool,
    getTrProps: PropTypes.func,
    getTheadTrProps: PropTypes.func,
    getTableProps: PropTypes.func,
    onPageChange: PropTypes.func,
    noDataState: PropTypes.node,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    meta: PropTypes.object,
    style: PropTypes.object,
    showPagination: PropTypes.bool,
    errorMessage: PropTypes.string,
  }

  static defaultProps = {
    data: null,
    noDataState: 'No rows found',
    onClickRow: () => {},
    paginated: true,
    getTableProps: () => ({}),
    onPageChange: null,
    isLoading: false,
    isError: false,
    meta: {},
    style: {},
    showPagination: true,
    errorMessage: 'Failed to load table data. Please try again.',
  }

  static getTableProps = () => ({
    style: {
      padding: '0',
      margin: '0',
      background: 'white',
      borderRadius: '0px',
      border: 'none',
      height: 'calc(100vh - 180px)',
    },
  })

  static getTbodyProps = () => ({
    style: {
      overflowY: 'scroll',
    },
  })

  static getTfootProps = () => ({
    style: {
      borderTop: `1px solid ${colors.GRAY_93}`,
      boxShadow: 'none',
    },
  })

  static getTfootTdProps = () => ({
    style: {
      paddingLeft: '20px',
      border: 'none',
    },
  })

  static getTheadProps = () => ({
    style: {
      boxShadow: 'none',
      borderBottom: `1px solid ${colors.GRAY_93}`,
      padding: '0',
      overflowY: 'scroll',
    },
  })

  static getTheadThProps = () => ({
    style: {
      borderRight: 'none',
      paddingTop: '20px',
      paddingBottom: '20px',
      overflow: 'hidden',
      whiteSpace: 'normal',
    },
  })

  static getTheadTrProps = () => ({
    style: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  })

  static getTrProps = () => ({
    style: {
      minHeight: '62px',
      paddingLeft: '20px',
      paddingRight: '20px',
      textAlign: 'left',
    },
  })

  static getPaginationProps = () => ({
    style: {
      height: '60px',
    },
  })

  state = {
    sorted: [],
  }

  constructor(props) {
    super(props)

    this.ref = createRef()
  }

  onSortedChange = sorted => this.setState(() => ({ sorted }))

  getSortedComponent = id => {
    const sortInfo = this.state.sorted.filter(item => item.id === id)
    if (sortInfo && sortInfo.length) {
      if (sortInfo[0].desc) return <Icon name="arrowDownSmall" />

      return <Icon name="arrowUpSmall" />
    }

    return null
  }

  columns = () => {
    const self = this

    return map(this.props.columns, ({ Header, header, headerStyle, Cell, ...others }) => ({
      id: header, // default id can be overwritten via others
      ...others,
      Header: props =>
        Header ? (
          <Header />
        ) : (
          <HeaderContainer>
            <HeaderTitle>{header}</HeaderTitle>
            {self.getSortedComponent(props.column.id)}
          </HeaderContainer>
        ),
      headerStyle: headerStyle || {
        boxShadow: 'none',
        outline: 'none',
        color: colors.GRAY_46,
        display: 'flex',
        padding: '20px 5px',
      },
      getProps: () => ({
        table: this.props,
      }),
      Cell: row => <CellContainer>{Cell ? Cell(row) : row.value}</CellContainer>,
    }))
  }

  handleRowClick = row => {
    if (!row) return

    this.props.onClickRow(row.original)
  }

  onPageChange = page => {
    if (this.props.onPageChange) {
      return this.props.onPageChange(page + 1)
    }
    this.props.history.push({
      query: {
        ...this.props.query,
        page: page + 1,
      },
    })
  }

  getTdProps = (state, row) => ({
    style: {
      border: 'none',
      justifyContent: 'flex-start',
      alignItems: 'center',
      display: 'flex',
    },
    onClick: this.handleRowClick.bind(this, row),
  })

  renderErrorState = () => (
    <ErrorContainer>
      <Text variant="T.14" style={{ color: colors.RED_500 }}>
        {this.props.errorMessage}
      </Text>
    </ErrorContainer>
  )

  render() {
    const { data, isLoading, isError, meta, style, ...other } = this.props

    if (isError) {
      return this.renderErrorState()
    }

    return (
      <ReactTable
        {...other}
        ref={this.ref}
        style={{
          border: 'none',
          backgroundColor: '#fff',
          ...style,
        }}
        page={this.props.page}
        sorted={this.state.sorted}
        onSortedChange={this.onSortedChange}
        showPagination={this.props.showPagination}
        loading={isLoading}
        minRows={this.props.minRows || 10}
        noDataText={isLoading ? '' : this.props.noDataText}
        sortable={false}
        multiSort={false}
        filterable={false}
        manual
        paginated={this.props.paginated}
        totalPages={meta && meta.total_pages}
        perPage={meta && meta.per_page}
        totalCount={meta && meta.total_count}
        hasNextPage={meta && meta.has_next_page}
        showPageSizeOptions={false}
        getTableProps={() => ({
          ...Table.getTableProps(),
          ...this.props.getTableProps(),
        })}
        getTheadProps={this.props.getTheadProps || Table.getTheadProps}
        getTheadThProps={this.props.getTheadThProps || Table.getTheadThProps}
        getTheadTrProps={Table.getTheadTrProps}
        getTbodyProps={this.props.getTbodyProps || Table.getTbodyProps}
        getTrProps={this.props.getTrProps || Table.getTrProps}
        getTdProps={this.props.getTdProps || this.getTdProps}
        getTfootProps={Table.getTfootProps}
        getTfootTdProps={Table.getTfootTdProps}
        getPaginationProps={Table.getPaginationProps}
        onPageChange={this.onPageChange}
        data={data || []}
        columns={this.columns()}
      />
    )
  }
}

export const getLegacyTableProps = Table.getTableProps
export const getLegacyTableTrProps = Table.getTrProps
export default withRetailerRouter(Table)
