import type { MouseEvent, PropsWithChildren, FunctionComponent } from 'react'
import { Fragment } from 'react'
import { Button, colors, SVGIcon } from 'ic-snacks'

import TableLoader from './TableLoader'

export enum TableActionsConfirmDismissTypes {
  EDIT = 'edit',
  DELETE = 'delete',
}

const baseButtonProps = {
  style: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  snacksStyle: 'flat' as const,
}

interface Props {
  actionType: TableActionsConfirmDismissTypes
  onConfirmClick(event: MouseEvent): void
  onDismissClick(event: MouseEvent): void
  isBusy?: boolean
}

const TableActionsConfirmDismiss: FunctionComponent<PropsWithChildren<Props>> = (props: Props) => {
  const { actionType, onConfirmClick, onDismissClick, isBusy } = props

  const isDeleting = actionType === TableActionsConfirmDismissTypes.DELETE

  if (isBusy) {
    return (
      <div data-testid="table-actions-busy">
        <TableLoader />
      </div>
    )
  }

  const dismissIcon = <SVGIcon name="xCircle" color={colors.GRAY_46} />

  const confirmIcon = (
    <SVGIcon name="checkmarkCircleFilled" color={isDeleting ? colors.RED_700 : colors.GREEN_500} />
  )

  return (
    <Fragment>
      <Button
        {...baseButtonProps}
        elementAttributes={{ 'data-testid': 'table-actions-dismiss' }}
        icon={dismissIcon}
        onClick={onDismissClick}
      />
      <Button
        {...baseButtonProps}
        elementAttributes={{ 'data-testid': 'table-actions-confirm' }}
        icon={confirmIcon}
        onClick={onConfirmClick}
      />
    </Fragment>
  )
}

export default TableActionsConfirmDismiss
