import Radium from 'radium'
import type { PropsWithChildren, FunctionComponent } from 'react'
import NotificationsProvider from '../../contexts/notifications/NotificationsProvider'
import GlobalProvider from '../../contexts/global/GlobalProvider'

const { StyleRoot } = Radium

interface Props {}

export const App: FunctionComponent<PropsWithChildren<Props>> = ({ children }) => (
  <StyleRoot id="radium-root">
    <GlobalProvider>
      <NotificationsProvider>{children}</NotificationsProvider>
    </GlobalProvider>
  </StyleRoot>
)
