import {
  type DashRouteType,
  URL_RETAILER_SCOPED,
  URL_APP_ADMIN,
} from '@retailer-platform/dashboard/routing'

/**
 * The route url and types for this domain are defined here, but the routes themselves
 * are defined in the legacy `reports` router in `dashboard/src/legacy/routes/reports`
 */
const URL_REPORTS = `${URL_RETAILER_SCOPED}/reports`
const URL_INSTACART_MANAGED_REPORTS_LIST = `${URL_REPORTS}/instacart-managed-reports`
const URL_TRANSACTION_LOGS_LIST = `${URL_REPORTS}/transaction-logs`

export type DashRouteSet =
  | DashRouteType<'instacart-managed-reports-list'>
  | DashRouteType<'transaction-logs-list'>

const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'instacart-managed-reports-list': URL_INSTACART_MANAGED_REPORTS_LIST,
  'transaction-logs-list': URL_TRANSACTION_LOGS_LIST,
}

export { routes }
