import type { PropsWithChildren, FunctionComponent } from 'react'
import { useEffect, useCallback } from 'react'
import instacart from '../../common/instacart'
import groupUser from '../../../utils/events/groupUser'
import identifyUser from '../../../utils/events/identifyUser'
import { useCurrentAccountDetails } from '../../../api/current-account/currentAccount.hooks'
import { errors } from '../../../utils/error-handling/errors'
import { type FetchCurrentEnterpriseAccountQuery } from '../../../__codegen__/api'
import { GlobalContext, type GlobalContextValue } from './GlobalContext'

interface Props {}

const GlobalProvider: FunctionComponent<PropsWithChildren<Props>> = ({ children }) => {
  const { loading, account } = useCurrentAccountDetails()

  const handleLogout = useCallback(async () => {
    await instacart.logout()
  }, [])

  // Configure Sentry and segment settings for user
  useEffect(() => {
    async function configureUserAnalytics(
      account: FetchCurrentEnterpriseAccountQuery['currentEnterpriseAccount']
    ) {
      // Sentry configuration
      errors.setUser({ id: account.canonicalId })

      // Identify user/group on segment
      identifyUser(account)
      groupUser(account)
    }

    if (account) {
      configureUserAnalytics(account)
    }
  }, [account])

  if (loading) return null

  const contextValue: GlobalContextValue = {
    loading,
    onLogout: handleLogout,
  }

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>
}

export default GlobalProvider
