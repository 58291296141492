import type { PropsWithChildren, FunctionComponent } from 'react'
import { useMemo, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { colors } from 'ic-snacks'
import sortBy from 'lodash/sortBy'
import Spinner from '../Spinner'
import { Input } from '../../../gin-and-tonic/common/input/Input'
import { useAllPartnersForUser } from '../../../api/partners/useAllPartnersForUser.hook'
import PartnerCell from './PartnerCell'

import { ColumnRow, ColumnTitle, ColumnBase } from './ListComponents'

const PartnersColumn = styled(ColumnBase)`
  flex: 0.3;
  border-right: 1px solid ${colors.GRAY_93};
  max-width: 300px;
`

// We exclude certain partners that are either in the `test`/prelaunch phase,
// or are legacy/deprecated partners which shouldn't be shown in the list.
export const EXCLUDED_PARTNER_ID_LIST = [
  // Demo or Legacy
  128, 231, 831, 791,
] as const

interface Props {
  selectedPartnerId: number | undefined
  selectPartner: (id: number) => void
  autoSelect?: boolean
  toggleAutoSelect?: (nextAutoSelectState: boolean) => void
}

const PartnerList: FunctionComponent<PropsWithChildren<Props>> = ({
  selectPartner,
  selectedPartnerId,
  autoSelect,
  toggleAutoSelect,
}) => {
  const { partners, loading, error } = useAllPartnersForUser()

  useEffect(() => {
    if (partners && !loading && !error) {
      if (autoSelect && partners.length === 1) {
        selectPartner(partners[0].id)
      } else {
        toggleAutoSelect?.(false)
      }
    }
  }, [partners, loading, error, selectPartner, autoSelect, toggleAutoSelect])

  const [filterValue, setFilterValue] = useState('')

  const renderedPartnerContent = useMemo(() => {
    if (!partners) return []

    const filteredPartnerList = partners.filter(
      ({ id, name }) =>
        !EXCLUDED_PARTNER_ID_LIST.some(excludedId => excludedId === id) &&
        // Filter out partners on our excluded list, or those with the name `do not use`
        !name.toLocaleLowerCase().includes('do not use') &&
        name.toLocaleLowerCase().includes(filterValue || '')
    )

    const sortedPartnerList = sortBy(filteredPartnerList, partner => partner.name)

    return sortedPartnerList
  }, [filterValue, partners])

  return (
    <PartnersColumn>
      <ColumnTitle>RETAILER</ColumnTitle>
      <ColumnRow style={{ height: 'auto', width: '100%', padding: '0 40px' }}>
        <Input
          name="retailer-filter"
          onChange={e => setFilterValue(e.target.value)}
          value={filterValue}
          placeholder="filter by name..."
        />
      </ColumnRow>
      <ColumnRow>
        {loading ? (
          <Spinner
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
            }}
          />
        ) : (
          renderedPartnerContent.map(props => (
            <PartnerCell
              {...props}
              key={props.id}
              onClick={selectPartner}
              selectedPartnerId={selectedPartnerId}
            />
          ))
        )}
      </ColumnRow>
    </PartnersColumn>
  )
}

export default PartnerList
