import type { PropsWithChildren, FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { Tooltip, colors, spacing } from '@retailer-platform/shared-components'
import { ChevronRightIcon, CloseIcon, StoreIcon } from '@instacart/ids-core'
import { useDashMessages } from '../../../intl/intl.hooks'
import { Tag } from '../../containers/retailer-and-site-picker/RetailerAndSitePicker.styles'
import { IconButton } from './SelectPartner.styles'

const MenuOptionStyle = styled.div(({ expandedHeight }: { expandedHeight: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  margin: '8px 0px',
  gap: '4px',

  width: '508px',
  height: 'auto',

  border: `1px solid ${colors.GRAYSCALE.X20}`,
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: colors.GRAYSCALE.X10,
    cursor: 'pointer',
  },
}))

const MenuOptionLabelStyle = styled.div(({ heavy }: { heavy?: boolean }) => ({
  fontWeight: heavy ? 600 : 400,
  fontSize: '14px',
  lineHeight: '20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const NonShrink = styled.div({
  flexDirection: 'column',
  flexShrink: 1,
  minWidth: '0px', // https://css-tricks.com/flexbox-truncated-text/
})

const MenuOptionSubLabelStyle = styled.div({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
})

const tags = {
  internal: {
    tagColor: '#EDF7F4',
    tagText: 'internalOnlyTag',
  },
  retailerPartner: {
    tagColor: '#F6F7F8',
    tagText: 'partnerTag',
  },
}

const FarRightIndent = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: 0,
  gap: '8px',
})

const Chevron = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: '0px 8px',
  padding: '0px 8px',

  background: 'transparent',
})

const StackLogosContainer = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
})

const StackedLogoImage = styled.img(({ offset, zIndex }: { offset: number; zIndex: number }) => ({
  transform: `translate(${offset}px)`,
  zIndex: zIndex,
  width: 32,
  height: 32,
  background: 'white',
  boxSizing: 'border-box',
  border: `1px solid ${colors.GRAYSCALE.X20}`,
  borderRadius: '44px',
}))

const StackedLogoDiv = styled.div(({ offset, zIndex }: { offset: number; zIndex: number }) => ({
  transform: `translate(${offset}px)`,
  zIndex: zIndex,
  width: 32,
  background: 'white',
  boxSizing: 'border-box',
  border: `1px solid ${colors.GRAYSCALE.X20}`,
  borderRadius: '44px',
}))

interface Props {
  tagType?: string
  label: JSX.Element | string
  labelTag?: string
  subLabel?: JSX.Element
  farRightSubLabel?: string
  onClose?: (e) => void
  logos?: string[]
  showChevron?: boolean
  heavyLabel?: boolean
}

export const MenuOption: FunctionComponent<PropsWithChildren<Props>> = ({
  tagType,
  label,
  labelTag,
  subLabel,
  farRightSubLabel,
  onClose,
  logos,
  showChevron,
  heavyLabel,
}) => {
  const tagAttributes = tags[tagType]

  const messages = useDashMessages({
    clear: 'selectPartner.recentlyVisited.toolTipClear',
    internalOnlyTag: 'selectPartner.internalOnlyTag',
    partnerTag: 'selectPartner.partnerTag',
  })

  const logoImages = logos?.map((url, index) =>
    url ? (
      <StackedLogoImage offset={index * 8} zIndex={100 - index} key={index} src={url} alt="logo" />
    ) : (
      <StackedLogoDiv offset={index * 8} zIndex={100 - index} key={index}>
        <StoreIcon key={index} size={31} color="systemGrayscale30" />
      </StackedLogoDiv>
    )
  )

  return (
    <MenuOptionStyle expandedHeight={!!subLabel}>
      <div css={{ display: 'flex', alignItems: 'center', width: '100%', gap: '4px' }}>
        <MenuOptionLabelStyle heavy={heavyLabel}>{label}</MenuOptionLabelStyle>
        {labelTag && (
          <Tag color={colors.GRAYSCALE.X10} css={{ padding: spacing.X4, margin: spacing.X4 }}>
            {labelTag}
          </Tag>
        )}

        <FarRightIndent>
          <StackLogosContainer>{logoImages}</StackLogosContainer>
          {tagAttributes && (
            <Tag color={tagAttributes.tagColor}>{messages[tagAttributes.tagText]}</Tag>
          )}
          {showChevron && (
            <Chevron>
              <ChevronRightIcon size={24} />
            </Chevron>
          )}
          {farRightSubLabel && (
            <MenuOptionSubLabelStyle>{farRightSubLabel}</MenuOptionSubLabelStyle>
          )}
        </FarRightIndent>
        {onClose && (
          <Tooltip
            placement="bottom"
            hideArrow
            target={
              <IconButton onClick={onClose}>
                <CloseIcon size={16} />
              </IconButton>
            }
          >
            {messages.clear}
          </Tooltip>
        )}
      </div>
      {subLabel && (
        <NonShrink>
          <MenuOptionSubLabelStyle>{subLabel}</MenuOptionSubLabelStyle>
        </NonShrink>
      )}
    </MenuOptionStyle>
  )
}
