// @ts-nocheck legacy file transformed to TS
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'ic-snacks'
import styled from '@emotion/styled'
import { FieldArray } from 'formik'
import reject from 'lodash/reject'

const Container = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Box = styled.div``

const InlineContainer = styled.div`
  width: 100%;
  position: relative;
  display: block;
`

const InlineBox = styled.div`
  float: left;
  width: 140px;
  margin-bottom: 20px;
  display: inline-block;
`

const checkboxStyle = {
  alignItems: 'flex-start',
}

class CheckboxGroup extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string.isRequired,
        isDisabled: PropTypes.bool,
        isHidden: PropTypes.bool,
      })
    ).isRequired,
    theme: PropTypes.oneOf(['light', 'dark']),
    values: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    inline: PropTypes.bool,
    isDisabled: PropTypes.bool,
  }

  static defaultProps = {
    multiple: false,
    inline: false,
    isDisabled: false,
  }

  render() {
    const [A, B] = this.props.inline ? [InlineContainer, InlineBox] : [Container, Box]

    return (
      <A>
        <FieldArray
          name={this.props.name}
          render={({ push, pop, remove }) =>
            reject(this.props.options, ({ isHidden }) => isHidden).map(
              ({ label, value, isDisabled, render }) => (
                <B key={value}>
                  <Checkbox
                    id={`checkbox-${value}`}
                    name={value}
                    style={checkboxStyle}
                    isDisabled={isDisabled || this.props.isDisabled}
                    isSelected={this.props.values.includes(value)}
                    onChange={() => {
                      if (this.props.multiple) {
                        if (this.props.values.includes(value)) {
                          remove(this.props.values.indexOf(value))
                        } else {
                          push(value)
                        }
                      } else {
                        while (this.props.values.length) {
                          pop()
                        }
                        push(value)
                      }
                    }}
                  >
                    {render ? render() : label}
                  </Checkbox>
                </B>
              )
            )
          }
        />
      </A>
    )
  }
}

export default CheckboxGroup
