// @ts-nocheck legacy file transformed to TS
import { Component, Fragment } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

class FormattedTimeWithOffset extends Component<any> {
  static propTypes = {
    value: PropTypes.instanceOf(Date),
    offset: PropTypes.number,
  }

  render() {
    const { value, offset, format } = this.props

    if (format) {
      return <Fragment>{moment.utc(value).add(offset, 'h').format(format)}</Fragment>
    }

    const offsetMoment = moment.utc(value).add(offset, 'h')

    if (offsetMoment.format('mm') === '00') {
      return <Fragment>{offsetMoment.format('h A')}</Fragment>
    }

    return <Fragment>{offsetMoment.format('h:mm A')}</Fragment>
  }
}

export default FormattedTimeWithOffset
