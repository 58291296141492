import type { ReactNode } from 'react'
import { Fragment } from 'react'
import { injectIntl } from 'react-intl'
import { type InjectedIntlProps } from '../../intl/intl.types'
import { _useDocumentTitleString } from '../../utils/document/document.hooks'

interface Props {
  children?: ReactNode
  title?: string
  titleId?: string
}

interface DefaultProps {
  titleId: string
}

const defaultProps: DefaultProps = {
  titleId: 'default.title',
}

const DocumentTitle = injectIntl<'intl', Props & InjectedIntlProps>(
  ({ title, titleId, intl, children }) => {
    const titleString = title || intl.formatMessage({ id: titleId })

    _useDocumentTitleString(titleString)

    return <Fragment>{children}</Fragment>
  }
)

DocumentTitle.defaultProps = defaultProps

export default DocumentTitle
