import type { PropsWithChildren, FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { DASH_NAV_WIDTH, Z_INDEX_FIRST } from '../../../legacy/common/theme'
import { useRequiredNavDataLoadingStatus } from './utils/nav.hooks'
import { RetailerNavContent } from './components/retailer-nav/RetailerNavContent'

const NavContainer = styled.aside({
  position: 'fixed',
  height: '100%',
  display: 'flex',
  width: DASH_NAV_WIDTH,
  top: 0,
  left: 0,
  zIndex: Z_INDEX_FIRST,
})

export const RetailerNav: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const { loading, error } = useRequiredNavDataLoadingStatus()

  if (loading || error) return null

  return (
    <NavContainer>
      <RetailerNavContent />
    </NavContainer>
  )
}
