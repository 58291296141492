import React, { useMemo } from 'react'
import ReactModal, { type Styles } from 'react-modal'
import { CloseIcon } from '@instacart/ids-core'
import { IconButton } from '@instacart/ids-tooling'
import { useTDSContext } from '../../tds/index'
import { type TestID } from '../../utils/testing/types'
import { spacing } from '../../foundation'
import './Style.css'
import {
  style,
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButtonContainer,
} from './Modal.styles'

export { ModalContainer, ModalHeader, ModalBody, ModalFooter, ModalDivider } from './Modal.styles'

export type ModalPosition = 'top-center' | 'center' | 'top-right'

export interface ModalProps extends React.ComponentProps<typeof ReactModal>, TestID {
  /** The max height of the modal */
  maxHeight?: number | string
  /** The max width of the modal -- note that default styles use 100% width */
  maxWidth?: number | string
  /** The min width of the modal */
  minWidth?: number | string
  /** Header content, can be any ReactChild -- overriden by children */
  headerContent?: React.ReactChild
  /** Footer content, can be any ReactChild -- overriden by children */
  footerContent?: React.ReactChild
  /** Body` content, can be any ReactChild -- overriden by children */
  bodyContent?: React.ReactChild
  /** Hide the background overlay but keep rest of the area disabled */
  hideOverlay?: boolean
  /** Override default modal styles */
  styleOverrides?: Styles
  /** Hides the 'X' close button in top right. Don't forget to handle onRequestClose */
  hideCloseButton?: boolean
  /** Controls where the modal is placed on the window */
  position?: ModalPosition
}

export const Modal: React.FunctionComponent<React.PropsWithChildren<ModalProps>> = ({
  footerContent,
  bodyContent,
  headerContent,
  onRequestClose,
  maxWidth = 480,
  maxHeight = 600,
  minWidth,
  hideOverlay,
  hideCloseButton,
  children,
  styleOverrides,
  position = 'center',
  ...props
}) => {
  const styles = useMemo(() => {
    let positionProps = {}
    if (position === 'top-center') {
      positionProps = {
        position: 'absolute',
        top: '10%',
        bottom: null,
      }
    } else if (position === 'top-right') {
      positionProps = {
        position: 'absolute',
        margin: spacing.X48,
        top: '0',
        right: '0',
        bottom: '0',
      }
    }

    return {
      ...style,
      content: {
        ...style.content,
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        minWidth,
        width: '100%',
        ...positionProps,
        ...(styleOverrides?.content as {}),
      },
      overlay: {
        ...style.overlay,
        ...(styleOverrides?.overlay as {}),
        ...(hideOverlay && { backgroundColor: 'transparent' }),
      },
    }
  }, [maxWidth, maxHeight, minWidth, hideOverlay, styleOverrides, position])

  const baseTestId = `${props['data-testid'] || 'default'}-modal`

  const { useTDS } = useTDSContext()
  const contents = children ?? (
    <ModalContainer data-testid={`${baseTestId}-container`}>
      {!hideCloseButton && (
        <ModalHeader data-testid={`${baseTestId}-header`} useTDS={useTDS}>
          {headerContent}
        </ModalHeader>
      )}

      <ModalBody data-testid={`${baseTestId}-body`} useTDS={useTDS}>
        {bodyContent}
      </ModalBody>

      {footerContent && (
        <ModalFooter data-testid={`${baseTestId}-footer`}>{footerContent}</ModalFooter>
      )}
    </ModalContainer>
  )

  return (
    <ReactModal
      /**
       * Hardcode this as we will probably not need to specify it.
       * If we ever need to do SSR, this could cause issues though.
       *
       * If needed, can be overriden by the consumer by specifying
       * the same prop
       */
      appElement={document?.body}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      {...props}
      onRequestClose={onRequestClose}
      style={styles}
    >
      {!hideCloseButton && (
        <CloseButtonContainer onClick={onRequestClose} data-testid={`${baseTestId}-close-button`}>
          <IconButton
            icon={CloseIcon}
            accessibleLabel="close"
            css={{ backgroundColor: 'transparent' }}
          />
        </CloseButtonContainer>
      )}
      {contents}
    </ReactModal>
  )
}
