import {
  type DashRouteType,
  URL_RETAILER_SCOPED,
  URL_APP_ADMIN,
} from '@retailer-platform/dashboard/routing'

const URL_INSIGHTS_PORTAL = `${URL_RETAILER_SCOPED}/insights-portal`
const URL_INSIGHTS_PORTAL_REDIRECT_PAGE = `${URL_INSIGHTS_PORTAL}/redirect-page`

// Store performance
const URL_INSIGHTS_PORTAL_STORE_PERFORMANCE = `${URL_INSIGHTS_PORTAL}/store-performance`
const URL_INSIGHTS_PORTAL_COMPLETED_ORDERS = `${URL_INSIGHTS_PORTAL_STORE_PERFORMANCE}/completed-orders`
const URL_INSIGHTS_PORTAL_STORE_PERFORMANCE_BASKET_OVERVIEW = `${URL_INSIGHTS_PORTAL_STORE_PERFORMANCE}/basket/overview`
const URL_INSIGHTS_PORTAL_STORE_PERFORMANCE_BASKET_OUT_OF_STOCK = `${URL_INSIGHTS_PORTAL_STORE_PERFORMANCE}/basket/out-of-stock`
const URL_INSIGHTS_PORTAL_STORE_PERFORMANCE_SEARCH = `${URL_INSIGHTS_PORTAL_STORE_PERFORMANCE}/search`
const URL_INSIGHTS_PORTAL_STORE_PERFORMANCE_IMAGE_COVERAGE = `${URL_INSIGHTS_PORTAL_STORE_PERFORMANCE}/image-coverage`

// Executive summary dashboard
const URL_INSIGHTS_PORTAL_EXECUTIVE_SUMMARY = `${URL_INSIGHTS_PORTAL}/executive-summary`
const URL_INSIGHTS_PORTAL_SUMMARY_REPORT_PDF = `${URL_INSIGHTS_PORTAL}/summary-report-pdf`

// Collections insights
const URL_INSIGHTS_PORTAL_MERCHANDISING = `${URL_INSIGHTS_PORTAL}/merchandising`
const URL_INSIGHTS_PORTAL_MERCHANDISING_COLLECTIONS_OVERVIEW = `${URL_INSIGHTS_PORTAL_MERCHANDISING}/collections/overview`
const URL_INSIGHTS_PORTAL_MERCHANDISING_COLLECTIONS_PRODUCT_CAROUSELS = `${URL_INSIGHTS_PORTAL_MERCHANDISING}/collections/product-carousels`
const URL_INSIGHTS_PORTAL_MERCHANDISING_COLLECTIONS_SINGLE_COLLECTION = `${URL_INSIGHTS_PORTAL_MERCHANDISING}/collections/single-collection`
const URL_INSIGHTS_PORTAL_MERCHANDISING_COLLECTIONS_DEPARTMENTS = `${URL_INSIGHTS_PORTAL_MERCHANDISING}/collections/departments`
const URL_INSIGHTS_PORTAL_MERCHANDISING_COLLECTIONS_PAGE = `${URL_INSIGHTS_PORTAL_MERCHANDISING}/collections/page`

// Campaigns with offers
const URL_INSIGHTS_PORTAL_MARKETING = `${URL_INSIGHTS_PORTAL}/marketing`
const URL_INSIGHTS_PORTAL_MARKETING_CAMPAIGNS_OVERVIEW = `${URL_INSIGHTS_PORTAL_MARKETING}/campaigns/overview`
const URL_INSIGHTS_PORTAL_MARKETING_CAMPAIGNS_REDEMPTIONS_IN_APP = `${URL_INSIGHTS_PORTAL_MARKETING}/campaigns/redemptions-in-app`
const URL_INSIGHTS_PORTAL_MARKETING_CAMPAIGNS_REDEMPTIONS_IN_STORE = `${URL_INSIGHTS_PORTAL_MARKETING}/campaigns/redemptions-in-store`
const URL_INSIGHTS_PORTAL_MARKETING_CAMPAIGNS_USER_GROWTH = `${URL_INSIGHTS_PORTAL_MARKETING}/campaigns/user-growth`
const URL_INSIGHTS_PORTAL_MARKETING_CAMPAIGNS_BASKET = `${URL_INSIGHTS_PORTAL_MARKETING}/campaigns/basket`

// Campaigns without offers
const URL_INSIGHTS_PORTAL_MARKETING_AWARENESS_CAMPAIGNS_OVERVIEW = `${URL_INSIGHTS_PORTAL_MARKETING}/awareness-campaigns/overview`
const URL_INSIGHTS_PORTAL_MARKETING_AWARENESS_CAMPAIGNS_BASKET_IMPACT = `${URL_INSIGHTS_PORTAL_MARKETING}/awareness-campaigns/basket-impact`
const URL_INSIGHTS_PORTAL_MARKETING_AWARENESS_CAMPAIGNS_FUNNEL_DATA = `${URL_INSIGHTS_PORTAL_MARKETING}/awareness-campaigns/funnel-data`

// Creatives
const URL_INSIGHTS_PORTAL_PLACEMENTS_INSIGHTS = `${URL_INSIGHTS_PORTAL}/placements-insights`

// Custom reports
const URL_INSIGHTS_PORTAL_REPORTS_LOGS = `${URL_INSIGHTS_PORTAL}/reports-logs`
const URL_INSIGHTS_PORTAL_REPORTS_LOGS_CUSTOM_REPORT = `${URL_INSIGHTS_PORTAL_REPORTS_LOGS}/custom-report`
const URL_INSIGHTS_PORTAL_REPORTS_LOGS_CUSTOM_REPORT_LIST = `${URL_INSIGHTS_PORTAL_REPORTS_LOGS_CUSTOM_REPORT}/reports/list`
const URL_INSIGHTS_PORTAL_REPORTS_LOGS_CUSTOM_REPORT_EXPORTS_LIST = `${URL_INSIGHTS_PORTAL_REPORTS_LOGS_CUSTOM_REPORT}/exports/list`
const URL_INSIGHTS_PORTAL_REPORTS_LOGS_CUSTOM_REPORT_PREVIEW = `${URL_INSIGHTS_PORTAL_REPORTS_LOGS_CUSTOM_REPORT}/preview/`

// Ads dashboard
const URL_INSIGHTS_PORTAL_ADS_DASHBOARD = `${URL_INSIGHTS_PORTAL}/ads-dashboard`
const URL_INSIGHTS_PORTAL_SPONSORED_PRODUCT_ADS_DASHBOARD = `${URL_INSIGHTS_PORTAL}/ads-dashboard/sponsored-product`
const URL_INSIGHTS_PORTAL_DISPLAY_ADS_DASHBOARD = `${URL_INSIGHTS_PORTAL}/ads-dashboard/display`
const URL_INSIGHTS_PORTAL_OVERVIEW_ADS_DASHBOARD = `${URL_INSIGHTS_PORTAL}/ads-dashboard/overview`
const URL_INSIGHTS_PORTAL_PROMOTIONS_AD_DASHBOARD = `${URL_INSIGHTS_PORTAL}/ads-dashboard/promotions`
const URL_INSIGHTS_PORTAL_DISPLAY_PREVIEW_BLOCKING_TOOL = `${URL_INSIGHTS_PORTAL}/display-preview-blocking-tool`

// Inventory intelligence
const URL_INSIGHTS_PORTAL_INVENTORY_INTELLIGENCE = `${URL_INSIGHTS_PORTAL}/inventory-intelligence`

// Store labor performance
const URL_INSIGHTS_PORTAL_STORE_LABOR_PERFORMANCE_V2 = `${URL_INSIGHTS_PORTAL}/reports/store-labor-performance`

// Dashboard builder
const URL_INSIGHTS_PORTAL_DASHBOARD_CREATE = `${URL_INSIGHTS_PORTAL}/dashboard-create`
const URL_ADMIN_INSIGHTS_PORTAL_DASHBOARD_CREATE = `${URL_APP_ADMIN}/insights-portal/dashboard-create`

// @hygen:inject scoped-urls

// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'insights-portal'>
  | DashRouteType<'insights-portal-redirect-page'>
  | DashRouteType<'insights-portal-store-performance-completed-orders'>
  | DashRouteType<'insights-portal-store-performance-basket-overview'>
  | DashRouteType<'insights-portal-store-performance-basket-out-of-stock'>
  | DashRouteType<'insights-portal-store-performance-search'>
  | DashRouteType<'insights-portal-store-performance-image-coverage'>
  | DashRouteType<'insights-portal-summary-report-pdf'>
  | DashRouteType<'insights-portal-executive-summary'>
  | DashRouteType<'insights-portal-merchandising-collections-overview'>
  | DashRouteType<'insights-portal-merchandising-collections-product-carousel'>
  | DashRouteType<'insights-portal-merchandising-collections-single-collection'>
  | DashRouteType<'insights-portal-merchandising-collections-departments'>
  | DashRouteType<'insights-portal-merchandising-collections-page'>
  | DashRouteType<'insights-portal-marketing-campaigns-analytics-overview'>
  | DashRouteType<'insights-portal-marketing-campaigns-analytics-redemptions-in-app'>
  | DashRouteType<'insights-portal-marketing-campaigns-analytics-redemptions-in-store'>
  | DashRouteType<'insights-portal-marketing-campaigns-analytics-user-growth'>
  | DashRouteType<'insights-portal-marketing-campaigns-analytics-basket-impact'>
  | DashRouteType<'insights-portal-marketing-awareness-campaigns-analytics-overview'>
  | DashRouteType<'insights-portal-marketing-awareness-campaigns-analytics-basket-impact'>
  | DashRouteType<'insights-portal-marketing-awareness-campaigns-analytics-funnel-data'>
  | DashRouteType<'insights-portal-placements-insights'>
  | DashRouteType<'insights-portal-reports-logs-custom-report-list'>
  | DashRouteType<'insights-portal-reports-logs-custom-report-exports-list'>
  | DashRouteType<'insights-portal-reports-logs-custom-report-preview'>
  | DashRouteType<'insights-portal-ads-dashboard'>
  | DashRouteType<'insights-portal-sponsored-product-ads-dashboard'>
  | DashRouteType<'insights-portal-display-ads-dashboard'>
  | DashRouteType<'insights-portal-overview-ads-dashboard'>
  | DashRouteType<'insights-portal-promotions-ads-dashboard'>
  | DashRouteType<'insights-portal-display-preview-blocking-tool'>
  | DashRouteType<'insights-portal-store-labor-performance-v2'>
  | DashRouteType<'insights-portal-inventory-intelligence'>
  | DashRouteType<'insights-portal-admin-dashboard-create'>
  | DashRouteType<'insights-portal-dashboard-create'>

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'insights-portal': URL_INSIGHTS_PORTAL,
  'insights-portal-redirect-page': URL_INSIGHTS_PORTAL_REDIRECT_PAGE,

  'insights-portal-store-performance-completed-orders': URL_INSIGHTS_PORTAL_COMPLETED_ORDERS,
  'insights-portal-store-performance-basket-overview':
    URL_INSIGHTS_PORTAL_STORE_PERFORMANCE_BASKET_OVERVIEW,
  'insights-portal-store-performance-basket-out-of-stock':
    URL_INSIGHTS_PORTAL_STORE_PERFORMANCE_BASKET_OUT_OF_STOCK,
  'insights-portal-store-performance-search': URL_INSIGHTS_PORTAL_STORE_PERFORMANCE_SEARCH,
  'insights-portal-store-performance-image-coverage':
    URL_INSIGHTS_PORTAL_STORE_PERFORMANCE_IMAGE_COVERAGE,

  'insights-portal-executive-summary': URL_INSIGHTS_PORTAL_EXECUTIVE_SUMMARY,
  'insights-portal-summary-report-pdf': URL_INSIGHTS_PORTAL_SUMMARY_REPORT_PDF,

  'insights-portal-merchandising-collections-overview':
    URL_INSIGHTS_PORTAL_MERCHANDISING_COLLECTIONS_OVERVIEW,
  'insights-portal-merchandising-collections-product-carousel':
    URL_INSIGHTS_PORTAL_MERCHANDISING_COLLECTIONS_PRODUCT_CAROUSELS,
  'insights-portal-merchandising-collections-single-collection':
    URL_INSIGHTS_PORTAL_MERCHANDISING_COLLECTIONS_SINGLE_COLLECTION,
  'insights-portal-merchandising-collections-departments':
    URL_INSIGHTS_PORTAL_MERCHANDISING_COLLECTIONS_DEPARTMENTS,
  'insights-portal-merchandising-collections-page':
    URL_INSIGHTS_PORTAL_MERCHANDISING_COLLECTIONS_PAGE,

  'insights-portal-marketing-campaigns-analytics-overview':
    URL_INSIGHTS_PORTAL_MARKETING_CAMPAIGNS_OVERVIEW,
  'insights-portal-marketing-campaigns-analytics-redemptions-in-app':
    URL_INSIGHTS_PORTAL_MARKETING_CAMPAIGNS_REDEMPTIONS_IN_APP,
  'insights-portal-marketing-campaigns-analytics-redemptions-in-store':
    URL_INSIGHTS_PORTAL_MARKETING_CAMPAIGNS_REDEMPTIONS_IN_STORE,
  'insights-portal-marketing-campaigns-analytics-user-growth':
    URL_INSIGHTS_PORTAL_MARKETING_CAMPAIGNS_USER_GROWTH,
  'insights-portal-marketing-campaigns-analytics-basket-impact':
    URL_INSIGHTS_PORTAL_MARKETING_CAMPAIGNS_BASKET,

  'insights-portal-marketing-awareness-campaigns-analytics-overview':
    URL_INSIGHTS_PORTAL_MARKETING_AWARENESS_CAMPAIGNS_OVERVIEW,
  'insights-portal-marketing-awareness-campaigns-analytics-basket-impact':
    URL_INSIGHTS_PORTAL_MARKETING_AWARENESS_CAMPAIGNS_BASKET_IMPACT,
  'insights-portal-marketing-awareness-campaigns-analytics-funnel-data':
    URL_INSIGHTS_PORTAL_MARKETING_AWARENESS_CAMPAIGNS_FUNNEL_DATA,

  'insights-portal-placements-insights': URL_INSIGHTS_PORTAL_PLACEMENTS_INSIGHTS,

  'insights-portal-reports-logs-custom-report-list':
    URL_INSIGHTS_PORTAL_REPORTS_LOGS_CUSTOM_REPORT_LIST,
  'insights-portal-reports-logs-custom-report-exports-list':
    URL_INSIGHTS_PORTAL_REPORTS_LOGS_CUSTOM_REPORT_EXPORTS_LIST,
  'insights-portal-reports-logs-custom-report-preview':
    URL_INSIGHTS_PORTAL_REPORTS_LOGS_CUSTOM_REPORT_PREVIEW,

  'insights-portal-ads-dashboard': URL_INSIGHTS_PORTAL_ADS_DASHBOARD,
  'insights-portal-sponsored-product-ads-dashboard':
    URL_INSIGHTS_PORTAL_SPONSORED_PRODUCT_ADS_DASHBOARD,
  'insights-portal-display-ads-dashboard': URL_INSIGHTS_PORTAL_DISPLAY_ADS_DASHBOARD,
  'insights-portal-overview-ads-dashboard': URL_INSIGHTS_PORTAL_OVERVIEW_ADS_DASHBOARD,
  'insights-portal-promotions-ads-dashboard': URL_INSIGHTS_PORTAL_PROMOTIONS_AD_DASHBOARD,
  'insights-portal-display-preview-blocking-tool':
    URL_INSIGHTS_PORTAL_DISPLAY_PREVIEW_BLOCKING_TOOL,

  'insights-portal-store-labor-performance-v2': URL_INSIGHTS_PORTAL_STORE_LABOR_PERFORMANCE_V2,
  'insights-portal-inventory-intelligence': URL_INSIGHTS_PORTAL_INVENTORY_INTELLIGENCE,

  'insights-portal-admin-dashboard-create': URL_ADMIN_INSIGHTS_PORTAL_DASHBOARD_CREATE,
  'insights-portal-dashboard-create': URL_INSIGHTS_PORTAL_DASHBOARD_CREATE,
  // @hygen:inject route-pair
}

// sub pages that have different owners are assigned to different totem entities
export const totemRouteOverrides: { [Key in DashRouteSet['identifier']]?: string } = {
  'insights-portal-overview-ads-dashboard': 'insights-portal-ads-dashboard-tool',
  'insights-portal-sponsored-product-ads-dashboard': 'insights-portal-ads-dashboard-tool',
  'insights-portal-store-labor-performance-v2': 'insights-portal-store-labor-performance-tool',
  'insights-portal-display-ads-dashboard': 'insights-portal-ads-dashboard-tool',
  'insights-portal-promotions-ads-dashboard': 'insights-portal-ads-dashboard-tool',
}

export type DomainRouteName = keyof typeof routes
