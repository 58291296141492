import { useMemo } from 'react'
import {
  dataFetchingError,
  dataFetchingLoading,
  type DataFetchingState,
  dataFetchingSuccess,
} from '../data-fetching/dataFetching.types'
import { type WarehouseLocation } from '../../types'
import { useGetAllRetailerLocationsForRetailerQuery } from '../../../../__codegen__/api'
import { mapRetailerLocationData } from '../../../../utils/contexts/warehouse/queries/dataUtils'
import { useRetailerId } from '../../../../utils/routing/params.hooks'

type Result = DataFetchingState<WarehouseLocation[]>
type UseWarehouseLocationsParams = {
  active?: boolean
  retailerIds?: string[]
}

export const useWarehouseLocations = (params: UseWarehouseLocationsParams = {}): Result => {
  const retailerId = useRetailerId()
  const active = params.active ?? true
  const retailerIds = params.retailerIds ?? [retailerId]

  const queryParams = {
    retailerIds,
  }

  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
  } = useGetAllRetailerLocationsForRetailerQuery({
    variables: queryParams,
    skip: queryParams.retailerIds.length === 0,
  })

  const data = useMemo(() => {
    if (!locationsData?.retailerLocationSearchArray) return []

    const warehouseLocations = locationsData.retailerLocationSearchArray.map(location =>
      mapRetailerLocationData(location)
    )

    if (active) {
      return warehouseLocations.filter(location => location.active)
    }
    return warehouseLocations
  }, [locationsData?.retailerLocationSearchArray, active])

  if (locationsError) {
    return dataFetchingError<WarehouseLocation[]>(new Error('Failed to fetch warehouse locations.'))
  }
  if (locationsLoading) {
    return dataFetchingLoading<WarehouseLocation[]>()
  }

  return dataFetchingSuccess<WarehouseLocation[]>(data)
}
