import type { PropsWithChildren, FunctionComponent } from 'react'
import { css } from '@emotion/react'
import { Img } from 'react-image'
import INSTACART_LOGO from '../../../assets/instacart-logo.svg'
import { Header } from '../header/Header'
import { spacing } from '../../foundation/spacing'
import { fontSize } from '../../foundation/fontSize'
import { type EmotionStyles } from '../../utils/styling/types'
import { Text } from '../text/Text'
import { type TestID } from '../../utils/testing/types'
import { type DashMessages } from '../../../intl/intl.types'

export interface Props extends EmotionStyles, TestID {
  headerMessageId?: DashMessages
  contentMessageId?: DashMessages
  secondaryContentMessageId?: DashMessages
}

const outerStyles = css({
  width: '100%',
  maxWidth: 540,
  minWidth: 400,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyItems: 'flex-start',
  alignItems: 'center',
  paddingTop: '20vh',
})

const headerWrapperStyles = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
})

const imageStyles = css({
  width: spacing.X120,
  margin: spacing.X16,
})

const contentStyles = css({
  marginTop: spacing.X40,
  fontSize: fontSize.X15,
})

const childStyles = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  justifyContent: 'flex-start',
  marginTop: spacing.X32,
  marginRight: spacing.X8,
  marginLeft: spacing.X8,
  marginBottom: spacing.X8,
})

export const PublicLayout: FunctionComponent<PropsWithChildren<Props>> = ({
  styles,
  headerMessageId,
  contentMessageId,
  secondaryContentMessageId,
  children,
  'data-testid': testId,
}) => (
  <div css={outerStyles} data-testid={testId}>
    <div css={headerWrapperStyles}>
      <Img css={imageStyles} src={INSTACART_LOGO} />
      <Header messageId={headerMessageId as any} data-testid={testId && `${testId}-header`} />

      {contentMessageId && (
        <Text
          styles={contentStyles}
          messageId={contentMessageId as any}
          data-testid={testId && `${testId}-content`}
        />
      )}
      {secondaryContentMessageId && (
        <Text
          styles={contentStyles}
          messageId={secondaryContentMessageId as any}
          data-testid={testId && `${testId}-secondary-content`}
        />
      )}
    </div>
    <div css={css(childStyles, styles)}>{children}</div>
  </div>
)
