import type { PropsWithChildren, ComponentType, FunctionComponent } from 'react'
import { Fragment, lazy } from 'react'
import { Redirect } from 'react-router'
import { ORDER_DELIVERY_URL } from '../../common/routes'
import RetailerSwitch from '../../components/RetailerSwitch'
import RetailerRoute, { type RetailerRouteComponentProps } from '../../components/RetailerRoute'
import DocumentTitle from '../../components/DocumentTitle'

// Old routes
import {
  OrdersPickupAccessControl,
  OrdersDeliveryAccessControl,
  OrdersScanAndPayAccessControl,
  OrderDetailAccessControl,
} from './OrdersSection.configuration'

const LegacyOrderDetailsRoute = lazy(() => import('../../routes/orders/details/OrderDetailsRoute'))
const OrdersList = lazy(() => import('./components/list/OrdersList'))

export const OrdersSection: FunctionComponent<PropsWithChildren<unknown>> = () => (
  <Fragment>
    <DocumentTitle titleId="orders.title" />
    <RetailerSwitch>
      <RetailerRoute
        accessControl={OrdersDeliveryAccessControl}
        route="delivery"
        exact
        scopePicker={{}}
        render={props => <OrdersList orderType="delivery" {...props} />}
      />
      <RetailerRoute
        accessControl={OrdersPickupAccessControl}
        route="pickup"
        exact
        scopePicker={{}}
        render={props => <OrdersList orderType="pickup" {...props} />}
      />
      <RetailerRoute
        accessControl={OrdersScanAndPayAccessControl}
        route="scanandpay"
        exact
        scopePicker={{}}
        render={props => <OrdersList orderType="scan_and_pay" {...props} />}
      />
      <RetailerRoute
        accessControl={OrderDetailAccessControl}
        route="order"
        component={
          LegacyOrderDetailsRoute as unknown as ComponentType<
            PropsWithChildren<RetailerRouteComponentProps>
          >
        }
      />
      <Redirect to={ORDER_DELIVERY_URL} />
    </RetailerSwitch>
  </Fragment>
)
