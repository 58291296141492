import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const onboardingAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.launchOnboarding],
}

export const onboardingSiteSetupAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.OnboardingSiteSetup],
}

export const onboardingSiteSetupChecklistAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.OnboardingSiteSetupChecklist],
}

export const onboardingRetailerProfileViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.OnboardingRetailerProfileView],
}

export const onboardingRetailerProfileModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.OnboardingRetailerProfileModify],
}
