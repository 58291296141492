import type { PropsWithChildren, FunctionComponent } from 'react'
import { useCallback, useState } from 'react'
import { Button, type ButtonProps } from '../../common/button/Button'
import { type DashRouteName } from '../../../utils/routing/routes'
import { DashRedirect } from '../../../legacy/dash-components/dash-redirect/DashRedirect'

interface Props extends Omit<ButtonProps, 'type'> {
  toRoute: DashRouteName
}

export const LinkButton: FunctionComponent<PropsWithChildren<Props>> = ({ toRoute, ...rest }) => {
  const [clicked, setClicked] = useState<boolean>(false)
  const onClickHandler = useCallback(() => setClicked(true), [])

  if (clicked) {
    return <DashRedirect push toRoute={toRoute} />
  }

  return <Button {...rest} onClick={onClickHandler} />
}
