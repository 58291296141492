import { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRedirect, DomainRoute } from '../utils/domain/routingComponents'
import { accountManagementPartnerAccessControl } from '../access-control/accountManagementPartnerAccess.configuration'

const AccountManagementListAccountsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AccountManagementListAccountsPage" */
      '../pages/partner/AccountManagementListAccountsPage'
    )
)

const AccountManagementCreateAccountPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AccountManagementCreateAccountPage" */
      '../pages/partner/AccountManagementCreateAccountPage'
    )
)

const AccountManagementUpdateAccountPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AccountManagementUpdateAccountPage" */
      '../pages/partner/AccountManagementUpdateAccountPage'
    )
)

// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const accountManagementPartnerRouter = (
  <DomainRoute
    route="partner-account-management"
    accessControl={accountManagementPartnerAccessControl}
  >
    <DashSwitch>
      <DomainRoute
        exact
        route="partner-account-management-list"
        component={AccountManagementListAccountsPage}
      />

      <DomainRoute
        exact
        route="partner-account-management-create"
        component={AccountManagementCreateAccountPage}
      />

      <DomainRoute
        route="partner-account-management-edit"
        component={AccountManagementUpdateAccountPage}
      />

      <DomainRedirect toRoute="partner-account-management-list" />

      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
