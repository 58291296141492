// @ts-nocheck legacy file transformed to TS
import { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import VirtualList from 'react-tiny-virtual-list'
import DropdownOption from './DropdownOption'

class DropdownOptions extends PureComponent {
  static propTypes = {
    maxHeight: PropTypes.number,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        isDisabled: PropTypes.bool,
      })
    ).isRequired,
    defaultable: PropTypes.bool.isRequired,
    defaultText: PropTypes.string,
    multiple: PropTypes.bool,
    selectAll: PropTypes.bool,
    selectedAll: PropTypes.bool,
    onDefault: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    isOptionSelected: PropTypes.func.isRequired,
  }

  render() {
    const {
      defaultable,
      defaultText,
      maxHeight,
      options,
      multiple,
      selectAll,
      selectedAll,
      onDefault,
      onSelect,
      onSelectAll,
      isOptionSelected,
    } = this.props

    const showSelectAll = Boolean(multiple && selectAll)
    const itemIndexStart = 0 + showSelectAll + defaultable
    const itemCount = options.length + itemIndexStart
    const itemsHeight = itemCount * 37
    const height = Math.min(maxHeight, itemsHeight)

    return (
      <VirtualList
        width="100%"
        height={height}
        itemCount={itemCount}
        itemSize={37}
        overscanCount={5}
        renderItem={({ index, style }) => {
          if (showSelectAll && index === 0) {
            return (
              <DropdownOption
                key={index}
                id="checkbox-select-all"
                selectable
                selected={selectedAll}
                onClick={onSelectAll}
                label={<FormattedMessage id="common.lists.selectAll" />}
              />
            )
          }

          if (defaultable && index === itemIndexStart - 1) {
            return <DropdownOption key={index} onClick={onDefault} label={defaultText} />
          }

          const option = options[index - itemIndexStart]

          return (
            <DropdownOption
              key={index}
              style={style}
              id={`checkbox-${option.value}`}
              disabled={option.isDisabled}
              selectable={multiple}
              selected={multiple ? isOptionSelected(option.value) : undefined}
              onClick={() => onSelect(option.value)}
              label={option.label}
            />
          )
        }}
      />
    )
  }
}

export default DropdownOptions
