import type { FunctionComponent } from 'react'
import { Divider } from './Common'
import { type Option } from './utils'
import MultiScopeDropdownHiddenOptionContainer from './MultiScopeDropdownHiddenOptionContainer'
import MultiScopeDropdownOption from './MultiScopeDropdownOption'

interface Props {
  option: Option
  selectedState: Record<string, Option>
  setSelectedState: (state: Record<string, Option>) => void
  multiSelect: boolean
  onSingleSelect: (option: Option) => void
}

const renderChildren = (
  option: Option,
  options: Option[],
  selectedState: Record<string, Option>,
  setSelectedState: (state: Record<string, Option>) => void,
  multiSelect: boolean,
  onSingleSelect: (option: Option) => void
) => {
  let visibleOptions: Option[]
  let hiddenOptions: Option[]
  if (option.type === 'all') {
    visibleOptions = options
    hiddenOptions = []
  } else {
    visibleOptions = options.slice(0, 3)
    hiddenOptions = options.slice(3)
  }

  const jsxArr = visibleOptions.map(option => (
    <div key={option.id}>
      <MultiScopeDropdownOptionContainer
        option={option}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        multiSelect={multiSelect}
        onSingleSelect={onSingleSelect}
      />
    </div>
  ))

  if (hiddenOptions.length > 0) {
    jsxArr.push(
      <MultiScopeDropdownHiddenOptionContainer
        options={hiddenOptions}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        multiSelect={multiSelect}
        onSingleSelect={onSingleSelect}
      />
    )
  }

  return jsxArr
}

const MultiScopeDropdownOptionContainer: FunctionComponent<Props> = ({
  option,
  selectedState,
  setSelectedState,
  multiSelect,
  onSingleSelect,
}) => (
  <div>
    <MultiScopeDropdownOption
      option={option}
      selectedState={selectedState}
      setSelectedState={setSelectedState}
      multiSelect={multiSelect}
      onSingleSelect={onSingleSelect}
    />
    {option.type === 'all' && <Divider />}
    {!option.leafNode && (
      <div css={{ marginLeft: option.type === 'all' ? '0px' : '24px' }}>
        {renderChildren(
          option,
          option.children,
          selectedState,
          setSelectedState,
          multiSelect,
          onSingleSelect
        )}
      </div>
    )}
  </div>
)

export default MultiScopeDropdownOptionContainer
