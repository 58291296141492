import type { PropsWithChildren, FunctionComponent } from 'react'
import { cloneElement, useMemo } from 'react'
import { DashRoute } from '../../legacy/dash-components/dash-route/DashRoute'
import { DashSwitch } from '../../legacy/dash-components/dash-switch/DashSwitch'
import { DashRedirect } from '../../legacy/dash-components/dash-redirect/DashRedirect'
import { useCoreContext } from '../../utils/core/RPPCoreContext'
import { RouteSuspense } from '../../legacy/dash-components/route-suspense/RouteSuspense'
import { AppAdminSectionAccessControl } from './AdminSection.configuration'
import { AdminRoot } from './components/root/AdminRoot'

export const AdminSectionRoutes: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const coreContext = useCoreContext()

  const adminRouters = useMemo(
    () => coreContext.adminRouters.map(el => cloneElement(el, { key: el.props.route })),
    [coreContext]
  )

  return (
    <RouteSuspense>
      <DashSwitch>
        <DashRoute
          exact
          route="app-admin"
          component={AdminRoot}
          accessControl={AppAdminSectionAccessControl}
        />

        {adminRouters}

        <DashRedirect toRoute="app-admin" />
      </DashSwitch>
    </RouteSuspense>
  )
}
