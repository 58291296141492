import { Button, spacing } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { type FeatureHighlight } from './types'
import { useDashMessages } from '../../../intl/intl.hooks'

interface FeatureEducationSlideLayoutProps {
  feature: FeatureHighlight
  onClick: () => void
  onClose: () => void
}

const Root = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
})

const ImageWrapper = styled('div')({
  borderRadius: spacing.X8,
  marginRight: spacing.X48,
  flex: 1,
})

const FeatureEducationSlideLayoutBody = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})

const StyledMarkdownWrapper = styled('div')({
  overflow: 'scroll',
  marginTop: spacing.X28,
  marginBottom: spacing.X8,
  maxWidth: 400,
  flex: 1,
})

const Image = styled('img')({
  objectFit: 'contain',
  width: 427,
  height: '100%',
})

const ActionButtonsWrapper = styled.div({
  display: 'flex',
  marginBottom: spacing.X26,
  'button:first-child': {
    marginLeft: 0,
  },
})

const FeatureEducationSlideLayout = ({
  feature,
  onClick,
  onClose,
}: FeatureEducationSlideLayoutProps) => {
  const { img, Component, buttonTextMsg } = feature
  const i18n = useDashMessages({
    buttonText: buttonTextMsg,
    maybeLater: 'featureEducation.modal.maybeLater',
  })

  return (
    <Root>
      <ImageWrapper>
        <Image src={img} />
      </ImageWrapper>
      <FeatureEducationSlideLayoutBody>
        <StyledMarkdownWrapper>
          <Component />
        </StyledMarkdownWrapper>
        <ActionButtonsWrapper>
          <Button onClick={onClick}>{i18n.buttonText}</Button>
          <Button variant="tertiary" onClick={onClose}>
            {i18n.maybeLater}
          </Button>
        </ActionButtonsWrapper>
      </FeatureEducationSlideLayoutBody>
    </Root>
  )
}

export default FeatureEducationSlideLayout
