/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactNode, PropsWithChildren, ComponentType } from 'react'

import { createElement, Children, Component } from 'react'
import PropTypes from 'prop-types'
import { isValidElementType } from 'react-is'
import qs from 'query-string'
// eslint-disable-next-line no-restricted-imports
import {
  type RouteProps,
  type RouteComponentProps,
  Route,
  type RouteChildrenProps,
} from 'react-router'
import invert from 'lodash/invert'
import memoize from 'lodash/memoize'
import { type PatchedHistory } from '../common/history'
import { type RouteName } from '../../utils/routing/routes'
import { url } from '../../utils/parsing/url'
import { RouteSuspense } from '../dash-components/route-suspense/RouteSuspense'
import { withCoreContext } from '../../utils/core/RPPCoreContext'
import { type RPPCoreContext } from '../../utils/core/RPPCore.types'
import { type AccessControlConfig } from './AccessControl/accessControl.utils'
import AccessControl from './AccessControl/AccessControl'
import { RetailerScopeWrapper } from '../../gin-and-tonic/containers/retailer-scope-wrapper/RetailerScopeWrapper'

const getRoutesByPath = memoize(invert)

interface PatchedRouteChildrenProps<TParams extends {} = any, TQuery = any>
  extends RouteChildrenProps<TParams> {
  history: PatchedHistory<TQuery>
}

export type ParsedQuery<Base> = {
  [Key in keyof Base]: Required<Base>[Key] extends any[] ? string[] : string
}

export interface RetailerRouteComponentProps<
  TParams extends {} = {},
  TQuery = {},
  TParsedQuery extends Partial<ParsedQuery<TQuery>> = Partial<ParsedQuery<TQuery>>
> extends RouteComponentProps<TParams> {
  history: PatchedHistory<TQuery>
  query: TParsedQuery
  url<Params extends { [K in keyof Params]?: string } = {}>(
    name: RouteName,
    params?: Params
  ): string
  currentRoute?: RouteName
}

type ChildFunction<TParams extends {}, TQuery, TParsedQuery extends {}> = (
  props: RetailerRouteComponentProps<TParams, TQuery, TParsedQuery>
) => ReactNode

interface Props<TParams extends {}, TQuery, TParsedQuery extends {}>
  extends Omit<RouteProps, 'render'> {
  route?: RouteName
  component?: ComponentType<
    PropsWithChildren<RetailerRouteComponentProps<TParams, TQuery, TParsedQuery>>
  >
  render?: (props: RetailerRouteComponentProps<TParams, TQuery, TParsedQuery>) => ReactNode
  children?: ChildFunction<TParams, TQuery, TParsedQuery> | ReactNode
  accessControl?: AccessControlConfig
  coreContext?: RPPCoreContext
  scopePicker: any
}

function createGetUrl(defaultParams: { [key: string]: string }, routesByName) {
  return function getUrl<Params extends { [K in keyof Params]?: string } = {}>(
    name: RouteName,
    params: Params
  ) {
    const pattern = routesByName[name]

    if (!pattern) {
      throw new Error(`Unknown Route: ${name}`)
    }

    return url(pattern, {
      ...defaultParams,
      ...params,
    })
  }
}

class RetailerRoute<
  TParams extends {} = any,
  TQuery = any,
  TParsedQuery extends {} = any
> extends Component<Props<TParams, TQuery, TParsedQuery>> {
  // Adding propTypes to maintain support for our non TS components
  // @TODO remove when all route files are written in TS
  static propTypes = {
    path: PropTypes.string,
    exact: PropTypes.bool,
    strict: PropTypes.bool,
    sensitive: PropTypes.bool,
    component: (props: Props<any, any, any>, propName: keyof Props<any, any, any>) => {
      if (props[propName] && !isValidElementType(props[propName])) {
        return new Error(
          `Invalid prop 'component' supplied to 'Route': the prop is not a valid React component`
        )
      }

      return null
    },
    render: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
    scopePicker: PropTypes.any,
  }

  renderRoute(routeProps: PatchedRouteChildrenProps) {
    const { children, component, render } = this.props
    const { routesByName } = this.props.coreContext
    const { match } = routeProps
    const query = qs.parse(routeProps.location.search) as Partial<ParsedQuery<TQuery>>
    const routesByPath = getRoutesByPath(routesByName)

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const childProps = {
      ...routeProps,
      query,
      url: createGetUrl(match ? match.params : {}, routesByName),
      currentRoute: match ? routesByPath[match.path] : undefined,
    } as RetailerRouteComponentProps<TParams, TQuery, TParsedQuery>

    if (component) {
      return match ? createElement(component, childProps) : null
    }

    if (render) {
      return match ? render(childProps) : null
    }

    if (typeof children === 'function') {
      return (children as ChildFunction<TParams, TQuery, TParsedQuery>)(childProps)
    }

    if (children && Children.count(children) !== 0) {
      return Children.only(children)
    }

    return null
  }

  render() {
    const { children, component, render, path, route, accessControl, scopePicker, ...rest } =
      this.props
    const { routesByName } = this.props.coreContext

    let response
    if (accessControl) {
      const accessControlPath = (route && routesByName[route]) || path

      response = (
        <RouteSuspense>
          <AccessControl accessControlConfig={accessControl} accessBlockedResult="remove-from-dom">
            <Route path={accessControlPath} {...rest}>
              {props => this.renderRoute(props)}
            </Route>
          </AccessControl>
        </RouteSuspense>
      )
    } else if (route) {
      response = (
        <Route path={routesByName[route]} {...rest}>
          {props => this.renderRoute(props)}
        </Route>
      )
    } else {
      response = (
        <Route path={path} {...rest}>
          {props => this.renderRoute(props)}
        </Route>
      )
    }

    if (scopePicker) {
      response = <RetailerScopeWrapper {...scopePicker}>{response}</RetailerScopeWrapper>
    }

    return response
  }
}

export default withCoreContext(RetailerRoute)
