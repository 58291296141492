import type { PropsWithChildren, FC } from 'react'
import { OpenInIcon } from '@instacart/ids-core'
import { useIntl } from 'react-intl'
import { NavLink } from '../NavLink'
import { useAbsoluteRouteMatch } from '../../../../../utils/routing/useAbsoluteRouteMatch.hooks'
import { NavItemStyle, TextContainer } from '../NavItem'
import useAccessControl from '../../../../../legacy/components/AccessControl/useAccessControl'
import { type AccessControlConfig } from '../../../../../legacy/components/AccessControl/accessControl.utils'

type Props = {
  onClick: () => void
  route?: string
  href?: string
  title: string
  newTab?: boolean
  accessControl?: AccessControlConfig
  position?: number
}

export const SubOption: FC<PropsWithChildren<Props>> = ({
  title,
  route,
  onClick,
  href,
  newTab,
  accessControl,
}) => {
  const intl = useIntl()
  const routeParams = useAbsoluteRouteMatch()
  const hasAccess = useAccessControl()

  const selected = route === routeParams.route
  const routePresent = !!(route || href)

  if (accessControl && !hasAccess(accessControl)) {
    return null
  }

  return (
    <div css={{ position: 'relative', alignItems: 'center', width: '100%' }}>
      <NavLink newTab={newTab} onClick={onClick} route={route} href={href}>
        <NavItemStyle selected={selected} padding="0px 0px 0px 40px">
          <TextContainer fontWeight={400}>{intl.formatMessage({ id: title })}</TextContainer>
        </NavItemStyle>
      </NavLink>

      {(href || newTab) && (
        <div css={{ position: 'absolute', top: '12px', right: '24px' }}>
          <OpenInIcon css={{ fill: '#0A5546' }} size={16} />
        </div>
      )}
    </div>
  )
}
