import pick from 'lodash/pick'
import partition from 'lodash/partition'
import {
  type GetAllRetailerLocationsForRetailerQuery,
  type GetRetailerQuery,
  type RetailerLocation,
  FulfillmentModelEnum,
  PickingModelEnum,
  type GetRetailerLocalesMeshQuery,
} from '../../../../__codegen__/api'
import {
  FulfillmentMethod,
  type PartnerType,
  PickingLabor,
  type WarehouseLocation,
} from '../../../../legacy/common/types'

// Map from API types to app types
const pickingLaborMappings = {
  [PickingModelEnum.Retailer]: PickingLabor.Retailer,
  [PickingModelEnum.FullService]: PickingLabor.FullService,
  [PickingModelEnum.Iss]: PickingLabor.ISS,
}

// Map from API types to app types
const fulfillmentMethodMappings = {
  [FulfillmentModelEnum.Retailer]: FulfillmentMethod.Retailer,
  [FulfillmentModelEnum.Locker]: FulfillmentMethod.Locker,
  [FulfillmentModelEnum.Iss]: FulfillmentMethod.ISS,
}

// Map warehouse location data from modern API to WarehouseLocation type
// (Mostly mapped as-is, but need to map enums and a couple fields that are slightly different)
export const mapRetailerLocationData = (
  retailerLocation: Omit<RetailerLocation, 'zipPlusFour' | 'retailer' | 'platformOnlyEnabled'>
): WarehouseLocation => {
  const basicAttrs = pick(retailerLocation, [
    'id',
    'active',
    'name',
    'street',
    'city',
    'state',
    'zipCode',
    'locationCode',
    'pickup',
    'partnerAlcoholDelivery',
    'retailerId',
  ])

  const pickingLabor = pickingLaborMappings[retailerLocation?.pickingModel] ?? null
  const fulfillmentMethods = (retailerLocation?.fulfillmentModels || []).map(
    model => fulfillmentMethodMappings[model]
  )

  return {
    ...basicAttrs,
    pickingLabor,
    fulfillmentMethods,
    configValues: {
      hybridPickingEnabled: retailerLocation.hybridPickingEnabled,
    },
  }
}

// Map warehouse data from our various API data sources to `WarehouseWithLocations` type
export const mapWarehouseData = ({
  retailerData,
  localesData,
  locationsData,
}: {
  retailerData: GetRetailerQuery['retailer']
  localesData: GetRetailerLocalesMeshQuery['catalogToolsApiServiceGetLocalesByRetailer']['retailerLocales']
  locationsData: GetAllRetailerLocationsForRetailerQuery['retailerLocationSearchArray']
}) => {
  const [warehouseLocations, inactiveWarehouseLocations] = (() => {
    const newApiAllWarehouseLocations = (locationsData || []).map(retailerLocation =>
      mapRetailerLocationData(retailerLocation)
    )
    return partition(newApiAllWarehouseLocations, location => location.active)
  })()

  const defaultLocale =
    localesData.length === 1
      ? localesData[0]
      : localesData.find(locale => locale.localeCode.toLowerCase().startsWith('en'))

  return {
    ...retailerData,

    // Location Data
    warehouseLocations,
    inactiveWarehouseLocations,

    // Modern API Attributes
    id: parseInt(retailerData.id, 10),

    // Locale Data
    locales: localesData,
    defaultLocale,
  }
}

// Map warehouse data from our various API data sources to `WarehouseWithLocations` type
export const mapPartnerData = ({
  partnerId,
  partnerData,
  localesData,
  featureEntitlements,
  featureToggles,
}: {
  partnerId: string
  partnerData: { name: string; partnerType: string }
  localesData: GetRetailerLocalesMeshQuery['catalogToolsApiServiceGetLocalesByRetailer']['retailerLocales']
  featureEntitlements: { id: string; name: string }[]
  featureToggles: { name: string }[]
}) => {
  const defaultLocale = null

  return {
    ...partnerData,
    partnerType: partnerData.partnerType as PartnerType,

    // Modern API Attributes
    id: partnerId,

    featureEntitlements,
    featureToggles,

    // Locale Data
    locales: localesData,
    defaultLocale,
  }
}
