import { FeatureToggle } from '../../access-control/featureToggles'
import { Permission } from '../../access-control/permissions'
import { type DomainAccessControlConfig } from '../../utils/domain/accessControl'

export const TransactionLogsListPageAccessControl: DomainAccessControlConfig = [
  {
    permissions: Permission.Tlogs,
    featureToggles: [FeatureToggle.TransactionLogsV2],
  },
  {
    permissions: Permission.Tlogs,
    warehouseFeatureToggles: [FeatureToggle.TransactionLogsV2],
  },
]
