import type { PropsWithChildren, FunctionComponent } from 'react'
import { Fragment } from 'react'
import {
  CategoryIcon,
  HomeIcon,
  ItemIcon,
  SparkleIcon,
  SafetyIcon,
  OfficeIcon,
  AccountIcon,
  TeamIcon,
  CheckboxSelectedIcon,
  MoneyIcon,
  GraphIcon,
  AllStoresCategoryIcon,
} from '@instacart/ids-core'
import { NavFolder } from '../NavFolder'
import { AdminNavEntryPoint } from '../../utils/adminNavConfig'
import { NavItem } from '../NavItem'

export const AdminNavMainContent: FunctionComponent<PropsWithChildren<unknown>> = () => (
  <Fragment>
    <NavItem title="app.admin.nav.links.home" route="app-admin" Icon={HomeIcon} />

    {/*

      PLEASE KEEP THIS ALPHABETICALLY SORTED BY TITLE RENDERERD ON UI
      ----------------------------------------------------------------

    */}

    <NavFolder
      title="app.admin.nav.links.accounts"
      Icon={AccountIcon}
      navEntryPoint={AdminNavEntryPoint.Account}
    />

    <NavFolder
      title="app.admin.nav.links.business"
      Icon={TeamIcon}
      navEntryPoint={AdminNavEntryPoint.Business}
    />

    <NavFolder
      title="app.admin.nav.links.catalogAdmin"
      Icon={ItemIcon}
      navEntryPoint={AdminNavEntryPoint.CatalogAdmin}
    />

    <NavFolder
      title="app.admin.nav.links.financialHardening"
      Icon={MoneyIcon}
      navEntryPoint={AdminNavEntryPoint.FinancialHardening}
    />

    <NavFolder
      Icon={SparkleIcon}
      title="app.admin.nav.links.campaignManagement"
      navEntryPoint={AdminNavEntryPoint.CampaignManagement}
    />

    <NavFolder
      Icon={AllStoresCategoryIcon}
      title="app.admin.nav.links.connectedStores"
      navEntryPoint={AdminNavEntryPoint.ConnectedStores}
    />

    <NavFolder
      Icon={CategoryIcon}
      title="app.admin.nav.links.lps"
      navEntryPoint={AdminNavEntryPoint.LandingPageSystem}
    />

    <NavFolder
      Icon={CategoryIcon}
      title="app.admin.nav.links.merchandising"
      navEntryPoint={AdminNavEntryPoint.Merchandising}
    ></NavFolder>

    <NavFolder
      title="app.admin.nav.links.retailerManagement"
      Icon={OfficeIcon}
      navEntryPoint={AdminNavEntryPoint.RetailerManagement}
    />

    <NavFolder
      title="app.admin.nav.links.marketplaceOps"
      Icon={SafetyIcon}
      navEntryPoint={AdminNavEntryPoint.MarketplaceOps}
    />

    <NavFolder
      title="app.admin.nav.links.recipesAdmin"
      Icon={ItemIcon}
      navEntryPoint={AdminNavEntryPoint.RecipesAdmin}
    />

    <NavFolder
      title="app.admin.nav.links.approvalsManagement"
      Icon={CheckboxSelectedIcon}
      navEntryPoint={AdminNavEntryPoint.ApprovalsManagement}
    />

    <NavFolder
      title="app.admin.nav.links.analytics"
      Icon={GraphIcon}
      navEntryPoint={AdminNavEntryPoint.Analytics}
    />
  </Fragment>
)
