import type { ReactNode } from 'react'
import { Children } from 'react'
import { isElement } from 'react-is'
import usePartnerAccessControl from '../../../../legacy/components/AccessControl/usePartnerAccessControl'

export const useHasAccessibleChildren = (children: ReactNode): boolean => {
  const hasAccess = usePartnerAccessControl()

  if (!children) return false

  const hasAccessibleChildren = Children.map(children, child => {
    if (!isElement(child)) return true

    //the access control may be in the immediate child component or the grandchild component
    const accessControl = child.props.accessControl || child.props.children?.props?.accessControl

    // if there is no accessControl prop, the child is always accessible
    if (!accessControl) return true

    return hasAccess(accessControl)
  }).some(childAccess => childAccess)

  return hasAccessibleChildren
}
