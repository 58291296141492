import type { PropsWithChildren, FunctionComponent } from 'react'
import styled from '@emotion/styled'
import DocumentTitle from '../../components/DocumentTitle'
import { useTrackEvent } from '../../../utils/events/useTrackEvent.hook'
import { type RetailerScopedRouteParams } from '../../common/types'
import { type DashRouteComponentProps } from '../dash-route/utils/dashRoute.types'
import { NotFound } from './components/NotFound'
import { useHandleNotFoundClick } from './utils/notFoundClick.hooks'

const PageContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  /* Raises the content closer to the top of the screen */
  padding-bottom: 150px;
`

interface Props extends DashRouteComponentProps<Partial<RetailerScopedRouteParams>> {}

export const NotFoundPage: FunctionComponent<PropsWithChildren<Props>> = ({
  history: { replace },
  match: {
    params: { partner_id: partnerId, warehouse_id: retailerId },
  },
}) => {
  const trackEvent = useTrackEvent()
  trackEvent({ id: 'not_found.viewed', description: 'Viewed the not found page' })

  const handleNotFoundClick = useHandleNotFoundClick({ replace, partnerId, retailerId })

  return (
    <PageContainer>
      <DocumentTitle titleId="notFound.title" />
      <NotFound handleNotFoundClick={handleNotFoundClick} />
    </PageContainer>
  )
}
