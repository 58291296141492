import type { ReactNode, FunctionComponent, ComponentPropsWithoutRef } from 'react'
import { useState, forwardRef } from 'react'
import { CloseButton, typography, useTheme } from '@retailer-platform/shared-components/src/tds'
import { IssueIcon } from '@instacart/ids-core'
import clientEnv from '../../../utils/global/clientEnv'
import { useDashMessage } from '../../../intl/intl.hooks'

interface BetaEnvBannerProps {
  children: ReactNode
}

export const BetaEnvBanner: FunctionComponent<BetaEnvBannerProps> = ({ children }) => {
  const [isBannerVisible, setBannerVisible] = useState(true)
  const isBeta = clientEnv.PUBLIC_CLIENT_IS_BETA
  const text = useDashMessage('betaEnvBanner.text')

  const handleDismiss = () => {
    setBannerVisible(false)
  }

  return (
    <div>
      {isBeta && isBannerVisible && (
        <Alert buttons={<CloseButton compact variant="secondary" onClick={handleDismiss} />}>
          {text}
        </Alert>
      )}
      {children}
    </div>
  )
}

/**
 * We want to use the TDS Alert but it doesn't accept styles. So we are duplicating it here to customize one off.
 * I don't like this but the show must go on.
 */

export type AlertProps = {
  layout?: 'horizontal' | 'stacked'
  buttons?: ReactNode
  children?: ReactNode
} & ComponentPropsWithoutRef<'div'>

export type AlertVariant = 'info' | 'success' | 'warning' | 'error'

export const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  const { layout = 'horizontal', buttons, children, ...rest } = props
  const theme = useTheme()

  const alertContainerStyles = {
    padding: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderRadius: '4px',
    height: '36px',
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.systemAlertLight,
  }

  const bodyContainerStyles = {
    display: 'flex',
    flexDirection: layout === 'stacked' ? ('column' as const) : ('row' as const),
    flex: 1,
    justifyContent: 'space-between',
    alignSelf: 'center',
    alignItems: layout === 'stacked' ? ('flex-start' as const) : ('center' as const),
    gap: '8px',
    ...typography.accentRegular,
  }

  const buttonsContainerStyles = {
    display: 'flex',
    gap: '16px',
    alignItems: 'end',
  }

  const iconProps = {
    size: 16,
  }
  const icon = (
    <IssueIcon
      {...iconProps}
      fill={theme.colors.systemAlertRegular}
      data-testid="notification-icon"
    />
  )

  return (
    <div css={alertContainerStyles} role="alert" ref={ref} {...rest}>
      {icon}
      <div css={bodyContainerStyles}>
        {children}
        {buttons && <div css={buttonsContainerStyles}>{buttons}</div>}
      </div>
    </div>
  )
})
