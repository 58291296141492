import type { ReactNode, PropsWithChildren } from 'react'
import { Component } from 'react'
import styled from '@emotion/styled'
import { colors } from 'ic-snacks'

const FieldContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`

const LabelContainer = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.GRAY_46};
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`

interface Props {
  label?: ReactNode
}

class FormField extends Component<PropsWithChildren<Props>> {
  render() {
    const { label, children } = this.props

    return (
      <FieldContainer>
        {label && <LabelContainer>{label}</LabelContainer>}
        <InnerContainer>{children}</InnerContainer>
      </FieldContainer>
    )
  }
}

export default FormField
