import type { PropsWithChildren, FunctionComponent } from 'react'
import { useRef, useEffect, useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { colors } from '@retailer-platform/shared-components'
import { useNavContext } from '../../../nav/utils/NavContext'
import { useAbsoluteRouteMatch } from '../../../../../utils/routing/useAbsoluteRouteMatch.hooks'
import { L1Header } from '../L1Header'
import { useGenerateL2ComponentTree } from '../navBar.common'

const NavOuterContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  width: '226px',
  borderRight: `1px solid ${colors.GRAYSCALE.X20}`,
  backgroundColor: 'white',
  flexShrink: 0,
})

const NavMainContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100%',
  rowGap: '16px',
})

type Props = {
  indexedL1ByRoutes
}

const navItemPadding = '8px 16px 8px 21px'
const navGroupPadding = '8px 24px'
const navGroupCSSProperties = {
  display: 'flex',
  flexGrow: 0,
}
export const LeftShortcutsPane: FunctionComponent<PropsWithChildren<Props>> = ({
  indexedL1ByRoutes,
}) => {
  const [isNavMainContentScrolled, setIsNavMainContentScrolled] = useState(false)
  const navMainContentRef = useRef<HTMLDivElement>(null)

  const { mostRecentClickedRoute, setMostRecentClickedRoute } = useNavContext()
  const routeParams = useAbsoluteRouteMatch()
  const intl = useIntl()

  const l1 = indexedL1ByRoutes[routeParams?.route] || indexedL1ByRoutes[mostRecentClickedRoute]

  // we currently setMostRecentClickedRoute(null) when the dashboard is clicked from the top menu
  // it can also be navigated to by clicking "back" from a browser, so this handles that edge case
  if (routeParams?.route === 'dashboard') {
    setMostRecentClickedRoute(null)
  }

  const l2Items = useGenerateL2ComponentTree(
    l1,
    () => {},
    false,
    navItemPadding,
    navGroupPadding,
    navGroupCSSProperties,
    false
  )

  const onNavMainContentScroll = useCallback(() => {
    if (navMainContentRef.current) {
      setIsNavMainContentScrolled(navMainContentRef.current.scrollTop > 0)
    }
  }, [])

  useEffect(() => {
    const navMainContent = navMainContentRef.current
    if (navMainContent) {
      navMainContent.addEventListener('scroll', onNavMainContentScroll)
    }

    return () => {
      if (navMainContent) {
        navMainContent.removeEventListener('scroll', onNavMainContentScroll)
      }
    }
  }, [onNavMainContentScroll])

  if (!l1) {
    return null
  }

  const Icon = l1.icon

  return (
    <NavOuterContainer>
      {l1.name && (
        <L1Header
          HeaderIcon={Icon}
          titleMessage={intl.formatMessage({ id: l1.name })}
          titleSize="small"
          customPadding="24px 24px 16px 24px"
          withLowShadow={isNavMainContentScrolled}
        />
      )}
      <NavMainContent ref={navMainContentRef}>{l2Items}</NavMainContent>
    </NavOuterContainer>
  )
}
