// @ts-nocheck legacy file transformed to TS
import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import isNaN from 'lodash/isNaN'
import isNumber from 'lodash/isNumber'
import Spinner from '../Spinner'
import Warning from '../Warning'
import FlexRow from '../FlexRow'

const InlineTextFieldContainer = styled(FlexRow)`
  flex: 1;
  width: 120px;
`

const InlineTextFieldInput = styled.input`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(189, 189, 189);
  border-radius: 4px;
  box-sizing: border-box;
  color: rgb(66, 66, 66);
  margin: 0px;
  padding: 5px 10px;
  position: relative;
  opacity: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 14px;
`

const InlineTextFieldBusy = styled(InlineTextFieldInput.withComponent('input'))`
  border: 1px solid transparent;
`

const InlineTextFieldHidden = styled.span`
  width: 18px;
  visibility: hidden;
`

const PREFIX_BY_TYPE = {
  currency: '$',
}

class InlineTextField extends Component {
  static propTypes = {
    isBusy: PropTypes.bool,
    isError: PropTypes.bool,
    type: PropTypes.oneOf(['currency']).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }

  static defaultProps = {
    isBusy: false,
    isError: false,
  }

  state = {
    value: `$${this.props.value || ''}`,
    isActive: false,
  }

  constructor(props) {
    super(props)
    this.ref = createRef()
    this.prefix = PREFIX_BY_TYPE[this.props.type] || ''
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isActive && this.state.isActive) {
      this.ref.current.focus()
    }
  }

  onChange = e => {
    const val = e.target.value.replace(this.prefix, '')
    try {
      this.validate(val)

      this.setState({
        value: this.prefix + val,
      })
    } catch (err) {
      // noop
    }
  }

  validate = val => {
    switch (this.props.type) {
      case 'currency':
        if (isNaN(+val)) throw new Error()
        if (!isNumber(+val)) throw new Error()
        break
      default:
    }
  }

  onKeyDown = e => {
    if (e.keyCode === 13) {
      this.onApply()
    } else if (e.keyCode === 27) {
      this.onReset()
    }
  }

  onBlur = () => {
    this.onApply()
  }

  onReset = () => {
    this.setState({
      value: this.prefix + this.props.value,
      isActive: false,
    })
  }

  onApply = () => {
    if (this.props.isBusy) return
    this.setState(
      {
        isActive: false,
      },
      () => this.props.onChange(this.state.value.replace(this.prefix, ''))
    )
  }

  onActive = () => {
    if (this.props.isBusy) return
    this.setState({
      isActive: true,
    })
  }

  renderErrorState() {
    return (
      <InlineTextFieldContainer onClick={this.onActive}>
        <InlineTextFieldBusy readOnly value={this.state.value} />
        <Warning />
      </InlineTextFieldContainer>
    )
  }

  renderBusyState() {
    return (
      <InlineTextFieldContainer>
        <InlineTextFieldBusy readOnly value={this.state.value} />
        <Spinner />
      </InlineTextFieldContainer>
    )
  }

  renderInputState() {
    return (
      <InlineTextFieldContainer>
        <InlineTextFieldInput
          type="text"
          ref={this.ref}
          name={this.props.name}
          autocomplete="off"
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          value={this.state.value}
        />
        <InlineTextFieldHidden>x</InlineTextFieldHidden>
      </InlineTextFieldContainer>
    )
  }

  render() {
    if (this.props.isBusy) return this.renderBusyState()
    if (this.props.isError && !this.state.isActive) return this.renderErrorState()
    if (this.state.isActive) return this.renderInputState()

    return (
      <InlineTextFieldContainer>
        <InlineTextFieldInput
          onClick={this.onActive}
          type="text"
          readOnly
          name={this.props.name}
          autocomplete="off"
          value={this.state.value}
        />
        <InlineTextFieldHidden>x</InlineTextFieldHidden>
      </InlineTextFieldContainer>
    )
  }
}

export default InlineTextField
