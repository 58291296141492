// @ts-nocheck legacy file transformed to TS
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors, Checkbox } from 'ic-snacks'
import FlexRow from '../FlexRow'

const Option = styled(FlexRow)`
  flex: 1;
  width: 100%;
  color: ${colors.GRAY_46};
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 12px;
  padding-right: 5px;
  border-radius: 3px;
  height: 37px;
  transition: background 0.1s linear;
  background: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    background: #eee;
  }
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`

const Label = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 18px;
  width: 90%;
`

const checkboxStyle = {
  right: '-12px',
  position: 'relative',
}

class DropdownOption extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
  }

  static defaultProps = {
    disabled: false,
    selectable: false,
    selected: false,
  }

  render() {
    const { id, label, disabled, selectable, selected, onClick, style } = this.props

    return (
      <Option
        role="option"
        data-testid="dropdown-option"
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
        style={style}
      >
        <Label>{label}</Label>
        {selectable && <Checkbox id={id} style={checkboxStyle} isSelected={selected} />}
      </Option>
    )
  }
}

export default DropdownOption
