import { Modal, ModalBody, Button, spacing } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import FeatureEducationSlideLayout from './FeatureEducationSlideLayout'
import { type FeatureHighlight } from './types'
import { Z_INDEX_FIRST } from '../../../legacy/common/theme'

interface FeatureEducationModalProps {
  isOpen: boolean
  onClose: (id?: string) => void
  onClick: () => void
  feature: FeatureHighlight
}

const StyledModalBody = styled(ModalBody)({
  padding: spacing.X24,
})

const FeatureEducationModal = ({
  isOpen,
  onClose,
  onClick,
  feature = null,
}: FeatureEducationModalProps) => {
  if (!feature) return null

  const { Component, renderAsStandaloneComponent, modalStylesOverride } = feature

  return renderAsStandaloneComponent ? (
    <Component isOpen={isOpen} onClick={onClick} onClose={onClose} />
  ) : (
    <Modal
      hideCloseButton
      isOpen={isOpen}
      maxWidth={960}
      maxHeight={720}
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={false}
      styleOverrides={{
        content: {
          padding: spacing.X22,
        },
        overlay: {
          zIndex: Z_INDEX_FIRST + 1,
        },
        ...modalStylesOverride,
      }}
    >
      <StyledModalBody>
        <FeatureEducationSlideLayout feature={feature} onClick={onClick} onClose={onClose} />
      </StyledModalBody>
    </Modal>
  )
}

export default FeatureEducationModal
