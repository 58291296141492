import type { ReactNode, HTMLProps } from 'react'
import { forwardRef } from 'react'
import { css, type SerializedStyles } from '@emotion/react'
import { fontSize } from '../../foundation/fontSize'
import { colors } from '../../foundation/colors'
import { borderRadius } from '../../foundation/borderRadius'
import { type DashMessages } from '../../../intl/intl.types'
import { useDashMessage } from '../../../intl/intl.hooks'
import { type TestID } from '../../utils/testing/types'
import { type EmotionStyles } from '../../utils/styling/types'
import { spacing } from '../../foundation/spacing'
import { ErrorMessage } from '../error-message/ErrorMessage'
import { type OnChange } from '../../../utils/react/event.types'

export interface InputProps extends EmotionStyles, TestID, HTMLProps<HTMLInputElement> {
  disabled?: boolean
  name: string
  errorMessageId?: DashMessages
  placeholderMessageId?: DashMessages
  type?: 'email' | 'password' | 'text' | 'number' | 'time' | 'date'
  value?: string | number
  onChange?: OnChange
  outerStyles?: SerializedStyles
  errorMessage?: ReactNode
  min?: string
  max?: string
}

const outerStyles = css({
  width: '100%',
  marginTop: 6,
  marginRight: spacing.X8,
  marginLeft: spacing.X8,
  marginBottom: 10,
})

const inputStyles = css({
  height: 46,
  paddingLeft: 11,
  paddingRight: spacing.X4,
  fontSize: fontSize.X12,
  color: colors.GRAYSCALE.X70,
  backgroundColor: colors.GRAYSCALE.X0,
  borderRadius: borderRadius.X8,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colors.GRAYSCALE.X30,
  width: '100%',
  '&:focus': {
    borderColor: colors.GRAYSCALE.X70,
  },

  '&:disabled': {
    background: colors.GRAYSCALE.X10,
  },
})

const errorInputStyles = css({
  backgroundColor: colors.DETRIMENTAL.LIGHT,
  borderColor: colors.DETRIMENTAL.REGULAR,
})

const errorStyles = css({
  fontSize: fontSize.X10,
  height: spacing.X12,
  marginLeft: spacing.X12,
  marginRight: spacing.X0,
  marginTop: spacing.X0,
  marginBottom: `-${spacing.X12}`,
})

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      errorMessage,
      errorMessageId,
      placeholderMessageId,
      styles,
      'data-testid': testId,
      outerStyles: overrideOuterStyles,
      ...rest
    },
    ref
  ) => {
    const placeholderMessage = useDashMessage(placeholderMessageId)
    const errorMessageTestId = testId ? `${testId}-error-message` : undefined
    const isError = Boolean(errorMessageId)

    return (
      <div css={css(outerStyles, overrideOuterStyles)}>
        <input
          css={css(inputStyles, styles, isError && errorInputStyles)}
          placeholder={placeholderMessage}
          data-testid={testId}
          {...rest}
          ref={ref}
        />
        <ErrorMessage
          collapse={false}
          styles={errorStyles}
          messageId={errorMessageId}
          data-testid={errorMessageTestId}
        >
          {errorMessage}
        </ErrorMessage>
      </div>
    )
  }
)
