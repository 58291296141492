import { useCallback, useMemo, useState } from 'react'
import { type InjectedDashIntlProps } from '../../../../../../intl/intl.types'
import {
  type StoreGroupsFormikValues,
  type StoreGroupsFormikErrors,
  type StoreGroupsFormInitializerProps,
} from './form.types'
import { NEW_STORE_GROUP_DEFAULTS } from './form.constants'

export const useStoreGroupsFormValidator = ({ intl }: InjectedDashIntlProps) =>
  useCallback(
    ({ name, selectedLocationIds }: StoreGroupsFormikValues): StoreGroupsFormikErrors => {
      const errors: StoreGroupsFormikErrors = {}

      if (!name || name.trim().length === 0) {
        errors.name = intl.formatMessage({ id: 'settings.storeGroups.form.errors.name.required' })
      }

      if (selectedLocationIds.length === 0) {
        errors.selectedLocationIds = intl.formatMessage({
          id: 'settings.storeGroups.form.errors.selectedLocationIds.required',
        })
      }

      return errors
    },
    [intl]
  )

export const useStoreGroupsFormInitialValues = ({
  existingStoreGroup,
}: StoreGroupsFormInitializerProps): StoreGroupsFormikValues =>
  useMemo(() => {
    if (!existingStoreGroup) return NEW_STORE_GROUP_DEFAULTS

    const idSet = new Set(existingStoreGroup.retailerLocationIds)

    return {
      existingStoreGroupId: existingStoreGroup.id,
      name: existingStoreGroup.name,
      selectedLocationIds: Array.from(idSet),
      retailerIds: existingStoreGroup.retailerIds,
    }
  }, [existingStoreGroup])

export const useStoreGroupsEditModeState = () => {
  const [isEditLocationsMode, setIsEditLocationsMode] = useState(false)
  const toggleEditLocationsMode = useCallback(
    () => setIsEditLocationsMode(!isEditLocationsMode),
    [isEditLocationsMode]
  )

  return {
    isEditLocationsMode,
    toggleEditLocationsMode,
  }
}
