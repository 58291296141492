export enum Permission {
  Products = 'products',
  InstacartInternal = 'admin_panel.legacy_permission',
  ProductSearchCPTButton = 'catalog_product_search_report_cpt_problem',
  ProductAttributesEdit = 'catalog_product_attributes.modify',
  RetailerCollectionsV2 = 'retailer_collections.view',
  ItemAnomalyView = 'catalog_item_anomaly.view',
  CatalogProductsView = 'catalog_products.view',
  CatalogProductsModify = 'catalog_products.modify',
}
