import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import * as React from 'react'
import { type SVGIconProps } from '@instacart/ids-core'
import LinkBase from '../../../../legacy/components/LinkBase'
import { type RouteName } from '../../../../utils/routing/routes'
import { type AccessControlConfig } from '../../../../legacy/components/AccessControl/accessControl.utils'
import usePartnerAccessControl from '../../../../legacy/components/AccessControl/usePartnerAccessControl'
import { useTrackEvent } from '../../../../utils/events/useTrackEvent.hook'

export type NavLinkProps = {
  isMainItem?: boolean
  selected?: boolean
  Icon?: (props: Omit<SVGIconProps, 'component'>) => JSX.Element
  route?: string
  href?: string
  accessControl?: AccessControlConfig
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  id?: string
  position?: number
  navBadge?: React.ReactElement
  newTab?: boolean
}

const linkAttrs = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

export const NavLink: FunctionComponent<React.PropsWithChildren<NavLinkProps>> = ({
  Icon,
  accessControl,
  route,
  isMainItem = false,
  navBadge,
  href,
  onClick,
  children,
  newTab,
  ...rest
}) => {
  const trackEvent = useTrackEvent()

  const wrappedOnClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      onClick?.(event)

      // Wrapped onclick to track user clicks.
      // Want to compare these to things like navigation actions via search.
      trackEvent({
        id: 'navV2.navigation.action',
        description: 'Use clicked to navigate',
        data: {
          href,
          route,
        },
      })
    },
    [onClick, href, route, trackEvent]
  )

  const hasAccess = usePartnerAccessControl()
  if (accessControl && !hasAccess(accessControl)) return null

  return (
    <LinkBase
      route={route as RouteName}
      {...rest}
      href={href}
      {...(href || newTab ? linkAttrs : {})}
      onClick={wrappedOnClick}
      wrapperStyles={{ display: 'block' }}
      elementAttributes={{ style: { display: 'contents' } }}
    >
      {children}
    </LinkBase>
  )
}
