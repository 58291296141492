import {
  Button,
  NotificationLevel,
  clearNotification,
  notify,
  spacing,
} from '@retailer-platform/shared-components'
import { useEffect, useMemo } from 'react'
import { useDashMessages } from '../../../intl/intl.hooks'
import { useCurrentAccountContext } from '../../current-account/CurrentAccountContext'
import { useGetIcPlatformVersionsQuery } from '../../../__codegen__/api'
import { makeQueryStaleWhileRevalidate } from '../../apollo/makeQueryStaleWhileRevalidate'
import { useAdminControls } from '../../contexts/admin-controls/AdminControlsContext'
import { ICPlatformVersion, fallbackICPlatformVersion } from './versions.constants'

const NOTIFICATION_ID = 'sfx-versioning-fetch-error'

const useCachedGetSFXPlatformVersions = makeQueryStaleWhileRevalidate(
  useGetIcPlatformVersionsQuery,
  { baseCacheKey: 'sfxPlatformVersions' }
)

/**
 * DO NOT USE DIRECTLY, this is an internal utility, use `useICPlatformContext` instead.
 * This will do an API call and execute to logic to determine a version, while the `useICPlatformContext`
 * has this information cached and ready to use.
 *
 * Returns the resolved version of the platform for the current user.
 * The resolved version is computed based on Roulette experiments plus a remote call to get the
 * platform versions. Depending on the group of the currently logged-in user, the resolved
 * version will be either the current version or the next.
 *
 * @returns
 */
export const useGetICPlatformVersion = () => {
  const { forcedCurrentICPlatformVersion } = useAdminControls()
  const currentAccountContext = useCurrentAccountContext()
  const { data, refetch, error, errorWhileRevalidating } = useCachedGetSFXPlatformVersions({
    skip: !currentAccountContext,
  })

  const { platformVersion, nextPlatformVersion, nextPlatformVersionRouletteName } =
    data?.retailersServiceGetPlatformVersions || {}

  const messages = useDashMessages({
    title: 'sfxVersionFetch.error.title',
    body: 'sfxVersionFetch.error.body',
    action: 'sfxVersionFetch.error.action',
  })

  /**
   * Depending on the user group, we will either use the next version or the current version
   * We currently only care for when the user is in the alpha_ipp group for the next version.
   */
  const resolvedVersionString = useMemo(() => {
    const userGroup = currentAccountContext?.account?.featureExperiments?.find(
      experiment => experiment.name === nextPlatformVersionRouletteName
    )?.variant

    return userGroup === 'alpha_ipp' ? nextPlatformVersion : platformVersion
  }, [
    currentAccountContext?.account?.featureExperiments,
    nextPlatformVersion,
    nextPlatformVersionRouletteName,
    platformVersion,
  ])

  /**
   * If we have a forced current version, we will use that instead of the resolved version.
   * However, if theres none of the those, we will use the fallback version.
   *
   * This will ensure a version is returned, as it would be otherwise unsafe and everything would
   * evaluate in false for matching SFX versions.
   *
   * Please keep the fallback version with a reasonable value. See more on the versions.constants.ts file.
   */
  const resolvedVersion: ICPlatformVersion =
    forcedCurrentICPlatformVersion ||
    ICPlatformVersion[resolvedVersionString] ||
    fallbackICPlatformVersion

  useEffect(() => {
    if (!error && !errorWhileRevalidating) return

    notify({
      id: NOTIFICATION_ID,
      timeout: 20000,
      title: messages.title,
      body: (
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          {messages.body}
          <div css={{ marginTop: spacing.X8, textAlign: 'right' }}>
            <Button
              compact
              css={{ margin: 0 }}
              onClick={() => {
                refetch()
                clearNotification(NOTIFICATION_ID)
              }}
            >
              {messages.action}
            </Button>
          </div>
        </div>
      ),
      level: NotificationLevel.Error,
    })
  }, [error, errorWhileRevalidating, refetch, messages])

  return {
    currentVersion: ICPlatformVersion[platformVersion] as ICPlatformVersion,
    resolvedVersion,
    resolvedVersionString: ICPlatformVersion[resolvedVersion],
    isVersionOverridden: !!forcedCurrentICPlatformVersion,
  }
}
