import React, { type FunctionComponent, type ReactElement } from 'react'
import { css } from '@emotion/react'
import { spacing } from '../../../foundation/spacing'
import { Text } from '../../text/Text'
import { Text as TdsText, useTDSContext } from '../../../tds'

const emptyStateStyles = css({
  width: '100%',
  marginTop: spacing.X128,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'center',
})

interface Props {
  emptyStateMessage: string
}

export const DefaultEmptyState: FunctionComponent<React.PropsWithChildren<Props>> = ({
  emptyStateMessage,
}): ReactElement => {
  const { useTDS } = useTDSContext()

  return (
    <div css={emptyStateStyles}>
      {useTDS ? (
        <TdsText typography="bodyRegular">{emptyStateMessage}</TdsText>
      ) : (
        <Text size="medium">{emptyStateMessage}</Text>
      )}
    </div>
  )
}
