import type { PropsWithChildren, ComponentType, FunctionComponent } from 'react'
import { useState } from 'react'
import { SurfaceContext } from '../../../../../contexts/surface/SurfaceContext'

const withSurfaceId = <T,>(
  Component: ComponentType<PropsWithChildren<T>>
): FunctionComponent<PropsWithChildren<T>> => {
  return props => {
    const [surfaceId, setSurfaceId] = useState(1)
    return (
      <SurfaceContext.Provider value={{ surfaceId, setSurfaceId }}>
        <Component {...props} />
      </SurfaceContext.Provider>
    )
  }
}

export default withSurfaceId
