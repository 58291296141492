import type { PropsWithChildren, FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { type StoreGroupsFormikProps } from '../../utils/form.types'
import { type OnClick } from '../../../../../../../utils/react/event.types'
import Card from '../../../../../../components/Card/Card'
import { StoreGroupsFormSelectedListEmptyState } from './components/StoreGroupsFormSelectedListEmptyState'
import { StoreGroupsFormSelectedListToggleButton } from './components/StoreGroupsFormSelectedListToggleButton'
import { StoreGroupsFormSelectedListErrors } from './components/StoreGroupsFormSelectedListErrors'
import { StoreGroupsFormSelectedListLocations } from './components/StoreGroupsFormSelectedListLocations'

interface Props extends StoreGroupsFormikProps {
  toggleEditLocationsMode: OnClick
  isEditLocationsMode: boolean
}

export const StoreGroupsFormSelectedList: FunctionComponent<PropsWithChildren<Props>> = ({
  values: { selectedLocationIds },
  toggleEditLocationsMode,
  isEditLocationsMode,
}) => {
  const anySelected = selectedLocationIds.length > 0
  const showLocationsList = anySelected || isEditLocationsMode
  const showEmptyState = !showLocationsList

  return (
    <Card style={{ marginBottom: 60 }}>
      <Card.Header title={<FormattedMessage id="settings.storeGroups.form.storeLocationsTitle" />}>
        {anySelected && (
          <StoreGroupsFormSelectedListToggleButton
            toggleEditLocationsMode={toggleEditLocationsMode}
            isEditLocationsMode={isEditLocationsMode}
          />
        )}
      </Card.Header>
      <Card.Body padding="0">
        <StoreGroupsFormSelectedListErrors />

        {showLocationsList && (
          <StoreGroupsFormSelectedListLocations isEditLocationsMode={isEditLocationsMode} />
        )}

        {showEmptyState && (
          <StoreGroupsFormSelectedListEmptyState
            toggleEditLocationsMode={toggleEditLocationsMode}
          />
        )}
      </Card.Body>
    </Card>
  )
}
