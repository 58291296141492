import type { ReactNode } from 'react'
import { createContext } from 'react'
import { createContextHoc } from '../utils'
import { type NotificationStyles } from './types'

export type LegacyNotifyFn = (
  notification: ReactNode,
  // Intentionally unused, only exists to keep function signature
  notificationStyle?: NotificationStyles,
  dismissAfter?: number
) => void

export interface NotificationsContextValue {
  /**
   * @deprecated prefer `notify` from `@retailer-platform/shared-components`
   */
  notify: LegacyNotifyFn
  /**
   * @deprecated prefer `notify` from `@retailer-platform/shared-components`
   */
  notifyError: LegacyNotifyFn
}

export const NotificationsContext = createContext<NotificationsContextValue | null>(null)

NotificationsContext.displayName = 'NotificationsContext'

export const withNotificationsContext = createContextHoc(NotificationsContext, {
  displayName: 'withNotificationsContext',
  required: true,
})
