import type { PropsWithChildren, FunctionComponent } from 'react'
import { LinkButton } from '../../../../gin-and-tonic/routing/link-button/LinkButton'
import { PublicLayout } from '../../../../gin-and-tonic/common/layout/PublicLayout'

export const HaltAccountLimitedAccess: FunctionComponent<PropsWithChildren<unknown>> = () => (
  <PublicLayout
    headerMessageId="app.halt.accountLimitedAccess.header"
    contentMessageId="app.halt.accountLimitedAccess.content"
    data-testid="app-halt-account-limited-access-layout"
  >
    <LinkButton
      messageId="app.halt.accountLimitedAccess.refreshAction"
      toRoute="app-halt-redirect"
      data-testid="app-halt-account-limited-access-refresh-button"
    />
  </PublicLayout>
)
