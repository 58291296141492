import { useAccessControl } from '@retailer-platform/dashboard/access-control'
import {
  type AccessControlConfig,
  type WarehouseLocationFeature,
  AccessGated,
  type AccessGatedProps,
} from '@retailer-platform/dashboard/access-control'
import { type Permission } from '../../access-control/permissions'
import { type FeatureToggle } from '../../access-control/featureToggles'
import { type FeatureEntitlements } from '../../access-control/featureEntitlements'

export type DomainAccessControlConfigItem = AccessControlConfig<
  FeatureToggle,
  Permission,
  WarehouseLocationFeature,
  FeatureEntitlements
>

export type DomainAccessControlConfig =
  | AccessControlConfig<FeatureToggle, Permission, WarehouseLocationFeature, FeatureEntitlements>
  | AccessControlConfig<FeatureToggle, Permission, WarehouseLocationFeature, FeatureEntitlements>[]
  | DomainAccessControlConfigItem[]

export const useDomainAccessControl = useAccessControl as any as () => (
  accessControlConfig: DomainAccessControlConfig
) => boolean

type DomainAccessControlProps = Omit<AccessGatedProps, 'accessControlConfig'> & {
  accessControlConfig: DomainAccessControlConfig
}

export const DomainAccessGated = AccessGated as any as React.FC<DomainAccessControlProps>
