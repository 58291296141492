import type { PropsWithChildren, FunctionComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { TextField } from 'ic-snacks'
import { type InjectedDashIntlProps } from '../../../../../../intl/intl.types'
import Card from '../../../../../components/Card/Card'
import { type StoreGroupsFormikProps } from '../utils/form.types'

interface Props extends StoreGroupsFormikProps, InjectedDashIntlProps {}

export const _StoreGroupsFormNameField: FunctionComponent<PropsWithChildren<Props>> = ({
  values: { name },
  handleChange,
  handleBlur,
  errors: { name: nameError },
  intl,
}) => (
  <Card margin="0 0 20px 0">
    <Card.Header title={<FormattedMessage id="settings.storeGroups.form.groupDetail" />} />
    <Card.Body>
      <TextField
        name="name"
        value={name}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={!nameError}
        hasError={!!nameError}
        validationErrorText={nameError}
        floatingLabelText={intl.formatMessage({
          id: 'settings.storeGroups.form.labels.name',
        })}
        autoComplete="off"
        fullWidth
        elementAttributes={{ 'data-testid': 'store-group-name-field' }}
      />
    </Card.Body>
  </Card>
)

export const StoreGroupsFormNameField = injectIntl(_StoreGroupsFormNameField)
