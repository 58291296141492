import type { ComponentPropsWithoutRef, CSSProperties } from 'react'
import { Component } from 'react'
import styled from '@emotion/styled'
import { colors } from 'ic-snacks'
import { CardContext } from './CardContext'
import CardHeader from './CardHeader'
import CardBody from './CardBody'
import CardFooter from './CardFooter'

const cardShadows = [
  'none',
  '0 1px 4px rgba(34, 25, 25, 0.28)',
  '0 1px 4px rgba(34, 25, 25, 0.28), 0 2px 2px rgba(34, 25, 25, 0.07)',
]

interface ContainerProps
  extends Pick<CSSProperties, 'width' | 'height' | 'margin' | 'borderRadius'> {
  depth?: 0 | 1 | 2
}

const CardContainer = styled.div<ContainerProps>`
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  background: ${colors.WHITE};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  border: ${({ depth = 0 }) => (depth === 0 ? `1px solid ${colors.GRAY_88}` : 'none')};
  border-radius: ${({ borderRadius }) => borderRadius || '4px'};
  box-shadow: ${({ depth = 0 }) => cardShadows[depth]};
`

interface Props extends ComponentPropsWithoutRef<typeof CardContainer> {
  collapsible?: boolean
  mountCollapsed?: boolean
}

interface State {
  collapsed: boolean
  id: string
}

let idCounter = 0

function nextId() {
  return `rpp-card-${(idCounter += 1)}`
}

class Card extends Component<Props, State> {
  static Header = CardHeader

  static Body = CardBody

  static Footer = CardFooter

  static defaultProps = {
    collapsible: false,
    mountCollapsed: false,
  }

  readonly state: State = {
    collapsed: this.props.mountCollapsed,
    id: nextId(),
  }

  toggle = () => {
    this.setState(state => ({
      collapsed: !state.collapsed,
    }))
  }

  render() {
    const { collapsible, ...rest } = this.props
    const contextValue = {
      collapsible,
      toggle: this.toggle,
      ...this.state,
    }

    return (
      <CardContext.Provider value={contextValue}>
        <CardContainer {...rest} />
      </CardContext.Provider>
    )
  }
}

export default Card
