import type { ReactElement, PropsWithChildren, FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { spacing, fontSize, fontWeight } from '@retailer-platform/shared-components'
import { type SVGIconProps, OpenInIcon } from '@instacart/ids-core'
import styled from '@emotion/styled'
import LinkBase from '../../../../legacy/components/LinkBase'
import { type RouteName } from '../../../../utils/routing/routes'
import { hexToRgba } from '../../../../legacy/common/utils/colors/hexToRGBA'
import { type AccessControlConfig } from '../../../../legacy/components/AccessControl/accessControl.utils'
import usePartnerAccessControl from '../../../../legacy/components/AccessControl/usePartnerAccessControl'
import { useNavContext } from '../utils/NavContext'
import { useOptionalPartnerContext } from '../../../../exports/utils'
import { useCoreContext } from '../../../../utils/core/RPPCoreContext'
import { type NavSupportedEnvironment } from '../../../../exports/routing'
import { getHrefOverride } from '../../nav-v2/utils/navEnvironment'
import { NavIconWrapper } from './NavIconWrapper'

export type NavItemProps = {
  title: string
  isMainItem?: boolean
  selected?: boolean
  Icon?: (props: Omit<SVGIconProps, 'component'>) => JSX.Element
  route?: string
  href?: string
  accessControl?: AccessControlConfig
  onClick?: (a: any) => void
  id?: string
  position?: number
  navBadge?: ReactElement
  environment?: NavSupportedEnvironment
  linkBaseWrapperStyles?: { [key: string]: string }
}

const linkAttrs = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

const LinkBaseStyled = styled(LinkBase)<{ color: string }>(
  {
    textDecoration: 'none',

    width: 256,
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: hexToRgba('#358266', 0.2),
    },
  },
  ({ color }) => ({
    color,

    // This is to undo a hover style in globalStyles.ts
    '&:hover': {
      color,
    },
  })
)

export const TextContainer = styled.div<{ isMainItem: boolean }>(
  {
    height: spacing.X48,
    borderRadius: spacing.X4,
    fontSize: fontSize.X15,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
  },
  ({ isMainItem }) => ({
    fontWeight: isMainItem ? fontWeight.SEMIBOLD : fontWeight.REGULAR,
  })
)

export const NavItem: FunctionComponent<PropsWithChildren<NavItemProps>> = ({
  title,
  Icon,
  accessControl,
  route,
  isMainItem = false,
  navBadge,
  href,
  environment,
  linkBaseWrapperStyles,
  ...rest
}) => {
  const hasAccess = usePartnerAccessControl()
  const partnerContext = useOptionalPartnerContext()
  const { routesByName } = useCoreContext()
  const intl = useIntl()

  const { contentColor, iconColor } = useNavContext()

  if (accessControl && !hasAccess(accessControl)) return null

  const hrefOverride = getHrefOverride(route, environment, partnerContext, routesByName)

  return (
    <LinkBaseStyled
      color={contentColor}
      wrapperStyles={{ display: 'block', ...linkBaseWrapperStyles }}
      route={route as RouteName}
      {...rest}
      href={hrefOverride ? hrefOverride : href}
      {...(href || hrefOverride ? linkAttrs : {})}
    >
      <NavIconWrapper>{Icon && <Icon size={24} color={iconColor} />}</NavIconWrapper>
      <TextContainer data-testid="nav-item-text" isMainItem={isMainItem}>
        {intl.formatMessage({ id: title })}
      </TextContainer>
      {(href || hrefOverride) && (
        <OpenInIcon css={{ marginLeft: spacing.X8 }} color="systemGrayscale00" />
      )}
      {navBadge}
    </LinkBaseStyled>
  )
}
