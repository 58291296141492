import type { PropsWithChildren, FunctionComponent } from 'react'
import { useCallback, useMemo } from 'react'
import { SVGIcon, colors } from 'ic-snacks'
import { injectIntl } from 'react-intl'
import styled from '@emotion/styled'
import { type InjectedDashIntlProps } from '../../../../../../../../intl/intl.types'
import TextField from '../../../../../../../components/Forms/TextField'
import { type OnChange } from '../../../../../../../../utils/react/event.types'
import FiltersContainer from '../../../../../../../routes/reports/files/Filters/FiltersContainer'
import MultiScopePicker from '../../../../../../../../gin-and-tonic/containers/multi-scope-picker-2/MultiScopePicker'
import { ViewType } from '../../../../../../../../gin-and-tonic/containers/retailer-scope-wrapper/RetailerScopeWrapper'
import { useRetailerId } from '../../../../../../../../utils/routing/params.hooks'
import { useCurrentAccountDetails } from '../../../../../../../../api/current-account/currentAccount.hooks'

const searchIcon = <SVGIcon name="search" color={colors.GRAY_46} style={{ height: '18px' }} />

const LocationSelectorFilterContainer = styled(FiltersContainer)`
  padding: 15px 30px;
  border-bottom: 1px solid ${colors.GRAY_93};
  align-items: center;
  gap: 10px;
`

interface Props extends InjectedDashIntlProps {
  filterValue: string
  setFilterValue: (nextValue: string) => void
  retailerIdFilter: Set<string>
  setRetailerIdFilter: (nextValue: Set<string>) => void
}

const _LocationsListFilters: FunctionComponent<PropsWithChildren<Props>> = ({
  intl,
  filterValue,
  setFilterValue,
  retailerIdFilter,
  setRetailerIdFilter,
}) => {
  const onChange: OnChange = useCallback(
    ({ target: { value: nextValue } }) => setFilterValue(nextValue),
    [setFilterValue]
  )
  const retailerId = useRetailerId()
  const { account } = useCurrentAccountDetails()

  const multiBannerSelectEnabled = useMemo(
    () =>
      account?.featureToggles?.find(
        featureToggle => featureToggle.name === 'rt_store_groups_multi_banner_select'
      ),
    [account?.featureToggles]
  )

  const defaultOptions = Number(retailerId) > 0 ? [{ retailerId }] : []

  return (
    <LocationSelectorFilterContainer>
      <div css={{ display: 'flex', width: '400px' }}>
        <TextField
          name="storeGroupName"
          type="text"
          value={filterValue}
          onChange={onChange}
          elementAttributes={{
            placeholder: intl.formatMessage({
              id: 'settings.storeGroups.addModal.filters.name.placeholder',
            }),
            'data-testid': 'locations-list-filter',
          }}
          prefix={searchIcon}
          fullWidth
        />
      </div>
      {multiBannerSelectEnabled && (
        <MultiScopePicker
          selectionType={ViewType.RETAILER}
          multiSelect={true}
          scopeSelectionOptional
          selectedOptions={defaultOptions}
          onSelected={selectedArr => {
            setRetailerIdFilter(new Set(selectedArr.map(item => item.retailerId)))
          }}
        />
      )}
    </LocationSelectorFilterContainer>
  )
}

export const LocationsListFilters = injectIntl(_LocationsListFilters)
