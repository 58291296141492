import type { PropsWithChildren, FunctionComponent } from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'
import { navBarHeight } from '../navBar.constants'
import { type NavMenuHierarchy } from '../../NavMenuHierarchy'
import { DrawerContentsLeftPane } from './DrawerContentsLeftPane'
import { DrawerContentsRightPane } from './DrawerContentsRightPane'

const DrawerContainer = styled.div({
  display: 'flex',
  marginTop: navBarHeight,
  height: `calc(100vh - ${navBarHeight}px)`,
})

type Props = {
  currentlySelectedItem: number
  setCurrentlySelectedItem: (x: number) => void
  closeDrawer: () => void
  navMenuHierarchy: NavMenuHierarchy
}

export const DrawerContents: FunctionComponent<PropsWithChildren<Props>> = ({
  currentlySelectedItem,
  setCurrentlySelectedItem,
  closeDrawer,
  navMenuHierarchy,
}) => (
  <DrawerContainer>
    <DrawerContentsLeftPane
      navMenuHierarchy={navMenuHierarchy}
      currentlySelectedItem={currentlySelectedItem}
      setCurrentlySelectedItem={setCurrentlySelectedItem}
    />
    <DrawerContentsRightPane
      navMenuHierarchy={navMenuHierarchy}
      currentlySelectedItem={currentlySelectedItem}
      closeDrawer={closeDrawer}
    />
  </DrawerContainer>
)
