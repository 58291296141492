import type { PropsWithChildren, FunctionComponent } from 'react'
import { DashSwitch } from '../../legacy/dash-components/dash-switch/DashSwitch'
import { DashRoute } from '../../legacy/dash-components/dash-route/DashRoute'
import { DashRedirect } from '../../legacy/dash-components/dash-redirect/DashRedirect'
import { HaltAuthServiceAccountLimitedAccess } from './components/auth-service-account-limited-access/HaltAuthServiceAccountLimitedAccess'
import { HaltAccountLimitedAccess } from './components/account-limited-access/HaltAccountLimitedAccess'
import { HaltAccountActionRequired } from './components/account-action-required/HaltAccountActionRequired'
import { HaltRedirect } from './components/redirect/HaltRedirect'

export const HaltSection: FunctionComponent<PropsWithChildren<unknown>> = () => (
  <DashSwitch>
    <DashRoute exact route="app-halt-redirect" component={HaltRedirect} />
    <DashRoute exact route="app-halt-account-limited-access" component={HaltAccountLimitedAccess} />
    <DashRoute
      exact
      route="app-halt-auth-service-account-limited-access"
      component={HaltAuthServiceAccountLimitedAccess}
    />
    <DashRoute
      exact
      route="app-halt-account-action-required"
      component={HaltAccountActionRequired}
    />
    <DashRedirect toRoute="app-halt-redirect" />
  </DashSwitch>
)
