import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { FilterParamsProvider } from '../utils/filter-params/FilterParamsProvider'
import { insightsPortalAdsDashboardAccessControl } from '../access-control/insightsPortalAdsDashboardAccess.configuration'
import { insightsPortalCombinedIAMilestone2AccessControl } from '../access-control/insightsPortalAccess.configuration'
import { insightsPortalSummaryReportAccessControl } from '../access-control/insightsPortalSummaryReportAccess.configuration'
import { insightsPortalDataExplorerV3AccessControl } from '../access-control/insightsPortalDataExplorerAccess.configuration'
import { insightsPortalCampaignCombinedIAMilestone2AccessControl } from '../access-control/insightsPortalCampaignInsights.configuration'
import { insightsPortalInventoryIntelligenceAccessControl } from '../access-control/insightsPortalInventoryIntelligence.configuration'
import { insightsPortalCollectionsIAMilestone2AccessControl } from '../access-control/insightsPortalCollectionInsights.configuration'
import { insightsPortalCreativeInsightsAccessControl } from '../access-control/insightsPortalCreativeInsights.configuration'
import { insightsPortalAwarenessCampaignInsightsAccessControl } from '../access-control/insightsPortalAwarenessCampaignInsights.configuration'
import { InsightsPortalStoreLaborPerformanceV2AccessControl } from '../access-control/insightsPortalStoreLaborPerformanceAccess.configuration'

const InsightsPortalSummaryReportPdfPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalSummaryReportPdfPage" */
      '../pages/summary-report-pdf/InsightsPortalSummaryReportPdfPage'
    )
)
const InsightsPortalInventoryIntelligencePage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalInventoryIntelligence" */
      '../pages/inventory-intelligence/InsightsPortalInventoryIntelligencePage'
    )
)
const InsightsPortalAdsDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalAdsDashboardPage" */
      '../pages/ads-dashboard/InsightsPortalAdsDashboardPage'
    )
)

const InsightsPortalStoreLaborPerformanceV2DashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalStoreLaborPerformanceV2DashboardPage" */
      '../pages/store-labor-performance/InsightsPortalStoreLaborPerformanceV2Page'
    )
)

const InsightsPortalExecutiveSummaryPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalExecutiveSummaryPage" */
      '../pages/executive-summary/InsightsPortalExecutiveSummaryPage'
    )
)
const InsightsPortalPlacementsInsightsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalPlacementsInsightsPage" */
      '../pages/placements-insights/InsightsPortalPlacementsInsightsPage'
    )
)

const InsightsPortalCollectionsOverviewDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalCompletedOrdersDashboardPage" */
      '../pages/merchandising/InsightsPortalCollectionsOverviewPage'
    )
)
const InsightsPortalCollectionsProductCarouselDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalCollectionsProductCarouselDashboardPage" */
      '../pages/merchandising/InsightsPortalCollectionsProductCarouselPage'
    )
)
const InsightsPortalCollectionsSingleCollectionDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalCollectionsSingleCollectionDashboardPage" */
      '../pages/merchandising/InsightsPortalCollectionsSingleCollectionPage'
    )
)
const InsightsPortalCollectionsDepartmentsDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalCollectionsDepartmentsDashboardPage" */
      '../pages/merchandising/InsightsPortalCollectionsDepartmentsPage'
    )
)
const InsightsPortalCollectionsPageDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalCollectionsPageDashboardPage" */
      '../pages/merchandising/InsightsPortalCollectionsPagePage'
    )
)
const InsightsPortalCompletedOrdersDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalCompletedOrdersDashboardPage" */
      '../pages/store-performance/InsightsPortalStorePerformanceCompletedOrdersPage'
    )
)
const InsightsPortalStorePerformanceBasketDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalStorePerformanceBasketDashboardPage" */
      '../pages/store-performance/InsightsPortalStorePerformanceBasketOverviewPage'
    )
)
const InsightsPortalStorePerformanceBasketOutOfStockDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalStorePerformanceBasketOutOfStockDashboardPage" */
      '../pages/store-performance/InsightsPortalStorePerformanceBasketOutOfStockPage'
    )
)
const InsightsPortalStorePerformanceSearchDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalStorePerformanceSearchDashboardPage" */
      '../pages/store-performance/InsightsPortalStorePerformanceSearchDashboardPage'
    )
)
const InsightsPortalMarketingCampaignsAnalyticsOverviewDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalMarketingCampaignsAnalyticsOverviewPage" */
      '../pages/marketing/InsightsPortalMarketingCampaignsAnalyticsOverviewPage'
    )
)
const InsightsPortalMarketingCampaignsAnalyticsRedemptionsInAppDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalMarketingCampaignsAnalyticsOverviewPage" */
      '../pages/marketing/InsightsPortalMarketingCampaignsAnalyticsRedemptionInAppPage'
    )
)
const InsightsPortalMarketingCampaignsAnalyticsRedemptionsInStoreDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalMarketingCampaignsAnalyticsOverviewPage" */
      '../pages/marketing/InsightsPortalMarketingCampaignsAnalyticsRedemptionInStorePage'
    )
)
const InsightsPortalMarketingCampaignsAnalyticsBasketImpactDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalMarketingCampaignsAnalyticsOverviewPage" */
      '../pages/marketing/InsightsPortalMarketingCampaignsAnalyticsBasketImpactPage'
    )
)
const InsightsPortalMarketingCampaignsAnalyticsUserGrowthDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalMarketingCampaignsAnalyticsOverviewPage" */
      '../pages/marketing/InsightsPortalMarketingCampaignsAnalyticsUserGrowthPage'
    )
)
const InsightsPortalStorePerformanceImageCoverageDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalStorePerformanceImageCoverageDashboardPage" */
      '../pages/store-performance/InsightsPortalStorePerformanceImageCoveragePage'
    )
)
const InsightsPortalReportsLogsCustomReportListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalReportsLogsCustomReportListPage" */
      '../pages/reports-logs/InsightsPortalReportsLogsCustomReportListPage'
    )
)
const InsightsPortalReportsLogsCustomReportExportsListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalReportsLogsCustomReportExportsListPage" */
      '../pages/reports-logs/InsightsPortalReportsLogsCustomReportExportsListPage'
    )
)
const InsightsPortalReportsLogsCustomReportPreviewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalReportsLogsCustomReportPreviewPage" */
      '../pages/reports-logs/InsightsPortalReportsLogsCustomReportPreviewPage'
    )
)
const InsightsPortalMarketingAwarenessCampaignsAnalyticsOverviewDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalMarketingAwarenessCampaignsAnalyticsOverviewPage" */
      '../pages/marketing/InsightsPortalMarketingAwarenessCampaignAnalyticsOverviewPage'
    )
)
const InsightsPortalMarketingAwarenessCampaignsAnalyticsBasketImpactDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalMarketingAwarenessCampaignsAnalyticsOverviewPage" */
      '../pages/marketing/InsightsPortalMarketingAwarenessCampaignAnalyticsBasketImpactPage'
    )
)
const InsightsPortalMarketingAwarenessCampaignsAnalyticsFunnelDataDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsPortalMarketingAwarenessCampaignsAnalyticsOverviewPage" */
      '../pages/marketing/InsightsPortalMarketingAwarenessCampaignAnalyticsFunnelDataPage'
    )
)

const InsightsPortalDisplayPreviewBlockingToolPage = lazy(
  () =>
    import('../pages/display-preview-blocking-tool/InsightsPortalDisplayPreviewBlockingToolPage')
)
// @hygen:inject page-import

export const insightsPortalRouter = (
  <DomainRoute route="insights-portal">
    <FilterParamsProvider>
      <DashSwitch>
        <DomainRoute
          exact
          route="insights-portal-store-performance-completed-orders"
          component={InsightsPortalCompletedOrdersDashboardPage}
          accessControl={insightsPortalCombinedIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: [
              'insightsPortalDomain.dataAndAnalytics.breadcrumb',
              'insightsPortalDomain.storePerformance.completedOrders.title',
            ],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-store-performance-basket-overview"
          component={InsightsPortalStorePerformanceBasketDashboardPage}
          accessControl={insightsPortalCombinedIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: [
              'insightsPortalDomain.dataAndAnalytics.breadcrumb',
              'insightsPortalDomain.storePerformance.basket.title',
            ],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-store-performance-basket-out-of-stock"
          component={InsightsPortalStorePerformanceBasketOutOfStockDashboardPage}
          accessControl={insightsPortalCombinedIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: [
              'insightsPortalDomain.dataAndAnalytics.breadcrumb',
              'insightsPortalDomain.storePerformance.basket.title',
            ],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-store-performance-search"
          component={InsightsPortalStorePerformanceSearchDashboardPage}
          accessControl={insightsPortalCombinedIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: [
              'insightsPortalDomain.dataAndAnalytics.breadcrumb',
              'insightsPortalDomain.storePerformance.search.title',
            ],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-store-performance-image-coverage"
          component={InsightsPortalStorePerformanceImageCoverageDashboardPage}
          accessControl={insightsPortalCombinedIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: [
              'insightsPortalDomain.dataAndAnalytics.breadcrumb',
              'insightsPortalDomain.storePerformance.imageCoverage.title',
            ],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-merchandising-collections-overview"
          component={InsightsPortalCollectionsOverviewDashboardPage}
          accessControl={insightsPortalCollectionsIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['catalog.merchandising.title', 'app.admin.nav.links.collections'],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-merchandising-collections-product-carousel"
          component={InsightsPortalCollectionsProductCarouselDashboardPage}
          accessControl={insightsPortalCollectionsIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['catalog.merchandising.title', 'app.admin.nav.links.collections'],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-merchandising-collections-single-collection"
          component={InsightsPortalCollectionsSingleCollectionDashboardPage}
          accessControl={insightsPortalCollectionsIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['catalog.merchandising.title', 'app.admin.nav.links.collections'],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-merchandising-collections-departments"
          component={InsightsPortalCollectionsDepartmentsDashboardPage}
          accessControl={insightsPortalCollectionsIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['catalog.merchandising.title', 'app.admin.nav.links.collections'],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-merchandising-collections-page"
          component={InsightsPortalCollectionsPageDashboardPage}
          accessControl={insightsPortalCollectionsIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['catalog.merchandising.title', 'app.admin.nav.links.collections'],
          }}
        />
        <DomainRoute
          route="insights-portal-marketing-campaigns-analytics-overview"
          component={InsightsPortalMarketingCampaignsAnalyticsOverviewDashboardPage}
          accessControl={insightsPortalCampaignCombinedIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['insightsPortalDomain.dataAndAnalytics.breadcrumb'],
          }}
        />
        <DomainRoute
          route="insights-portal-marketing-campaigns-analytics-redemptions-in-app"
          component={InsightsPortalMarketingCampaignsAnalyticsRedemptionsInAppDashboardPage}
          accessControl={insightsPortalCampaignCombinedIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['insightsPortalDomain.dataAndAnalytics.breadcrumb'],
          }}
        />
        <DomainRoute
          route="insights-portal-marketing-campaigns-analytics-redemptions-in-store"
          component={InsightsPortalMarketingCampaignsAnalyticsRedemptionsInStoreDashboardPage}
          accessControl={insightsPortalCampaignCombinedIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['insightsPortalDomain.dataAndAnalytics.breadcrumb'],
          }}
        />
        <DomainRoute
          route="insights-portal-marketing-campaigns-analytics-user-growth"
          component={InsightsPortalMarketingCampaignsAnalyticsUserGrowthDashboardPage}
          accessControl={insightsPortalCampaignCombinedIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['insightsPortalDomain.dataAndAnalytics.breadcrumb'],
          }}
        />
        <DomainRoute
          route="insights-portal-marketing-campaigns-analytics-basket-impact"
          component={InsightsPortalMarketingCampaignsAnalyticsBasketImpactDashboardPage}
          accessControl={insightsPortalCampaignCombinedIAMilestone2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['insightsPortalDomain.dataAndAnalytics.breadcrumb'],
          }}
        />
        <DomainRoute
          route="insights-portal-marketing-awareness-campaigns-analytics-overview"
          component={InsightsPortalMarketingAwarenessCampaignsAnalyticsOverviewDashboardPage}
          accessControl={insightsPortalAwarenessCampaignInsightsAccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: [
              'insightsPortalDomain.dataAndAnalytics.breadcrumb',
              'insightsPortalDomain.awarenessCampaigns.nav',
            ],
          }}
        />
        <DomainRoute
          route="insights-portal-marketing-awareness-campaigns-analytics-basket-impact"
          component={InsightsPortalMarketingAwarenessCampaignsAnalyticsBasketImpactDashboardPage}
          accessControl={insightsPortalAwarenessCampaignInsightsAccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: [
              'insightsPortalDomain.dataAndAnalytics.breadcrumb',
              'insightsPortalDomain.awarenessCampaigns.nav',
            ],
          }}
        />
        <DomainRoute
          route="insights-portal-marketing-awareness-campaigns-analytics-funnel-data"
          component={InsightsPortalMarketingAwarenessCampaignsAnalyticsFunnelDataDashboardPage}
          accessControl={insightsPortalAwarenessCampaignInsightsAccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: [
              'insightsPortalDomain.dataAndAnalytics.breadcrumb',
              'insightsPortalDomain.awarenessCampaigns.nav',
            ],
          }}
        />
        <DomainRoute
          route="insights-portal-placements-insights"
          component={InsightsPortalPlacementsInsightsPage}
          accessControl={insightsPortalCreativeInsightsAccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: [
              'insightsPortalDomain.dataAndAnalytics.breadcrumb',
              'insightsPortalDomain.creatives.nav',
            ],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-reports-logs-custom-report-list"
          component={InsightsPortalReportsLogsCustomReportListPage}
          accessControl={insightsPortalDataExplorerV3AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-reports-logs-custom-report-exports-list"
          component={InsightsPortalReportsLogsCustomReportExportsListPage}
          accessControl={insightsPortalDataExplorerV3AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-reports-logs-custom-report-preview"
          component={InsightsPortalReportsLogsCustomReportPreviewPage}
          accessControl={insightsPortalDataExplorerV3AccessControl}
          scopePicker={{ hiddenScopePicker: true }}
        />

        <DomainRoute
          exact
          route="insights-portal-store-labor-performance-v2"
          component={InsightsPortalStoreLaborPerformanceV2DashboardPage}
          accessControl={InsightsPortalStoreLaborPerformanceV2AccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: [
              'insightsPortalDomain.dataAndAnalytics.breadcrumb',
              'insightsPortalDomain.storeLaborPerformanceV2.title',
            ],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-inventory-intelligence"
          component={InsightsPortalInventoryIntelligencePage}
          accessControl={insightsPortalInventoryIntelligenceAccessControl}
        />
        <DomainRoute
          exact
          route="insights-portal-summary-report-pdf"
          component={InsightsPortalSummaryReportPdfPage}
          // access control for insights-portal-summary-report and insights-portal-summary-report-pdf should stay the same
          accessControl={insightsPortalSummaryReportAccessControl}
        />
        <DomainRoute
          exact
          route="insights-portal-executive-summary"
          component={InsightsPortalExecutiveSummaryPage}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['insightsPortalDomain.summaryReport.pageHeaderTitle'],
          }}
        />

        <DomainRoute
          route="insights-portal-ads-dashboard"
          component={InsightsPortalAdsDashboardPage}
          accessControl={insightsPortalAdsDashboardAccessControl}
          scopePicker={{
            hiddenScopePicker: true,
            titles: ['insightsPortalDomain.adsDashboard.header'],
          }}
        />
        <DomainRoute
          exact
          route="insights-portal-display-preview-blocking-tool"
          component={InsightsPortalDisplayPreviewBlockingToolPage}
        />

        {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
      </DashSwitch>
    </FilterParamsProvider>
  </DomainRoute>
)
