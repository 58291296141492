import type { PropsWithChildren, FunctionComponent } from 'react'
import { lazy } from 'react'
import { Redirect } from 'react-router'
import RetailerRoute, { type RetailerRouteComponentProps } from '../../components/RetailerRoute'
import RetailerSwitch from '../../components/RetailerSwitch'
import DocumentTitle from '../../components/DocumentTitle'
import ContentContainer from '../../components/ContentContainer'
import { SEARCH_BASE_URL, NEW_SEARCH_URL, SEARCH_RESULTS_URL } from '../../common/routes'
// legacy routes
import LegacyProductRoute from '../../routes/catalog/products/product/ProductRoute'
import { type RetailerScopedRouteParams } from '../../common/types'
// eslint-disable-next-line workspaces/no-relative-imports
import { routes } from '../../../../../../domains/products/src/routing/routes'
import RegionLocationProvider from '../../contexts/regionLocation/RegionLocationProvider'

const LegacyCatalog = lazy(() => import('../../routes/catalog/Catalog'))

const LegacyDepartmentsRoute = lazy(
  () => import('../../routes/catalog/departments/DepartmentsRoute')
)
const LegacyDepartmentRoute = lazy(() => import('../../routes/catalog/departments/DepartmentRoute'))

interface Props extends RetailerRouteComponentProps<RetailerScopedRouteParams> {}

const CatalogSection: FunctionComponent<PropsWithChildren<Props>> = props => {
  // The Legacy Catalog is still required until all pages under catalog are converted to new patterns
  // It's required because the entire Catalog section is wrapped in a provider so unconverted routes
  // will break without it
  return (
    <LegacyCatalog {...props}>
      <DocumentTitle titleId="catalog.title" />
      <RegionLocationProvider>
        <ContentContainer display="flex" padding="0">
          <RetailerSwitch>
            <RetailerRoute exact route="departments">
              <DocumentTitle titleId="catalog.departments.title">
                <LegacyDepartmentsRoute />
              </DocumentTitle>
            </RetailerRoute>
            <RetailerRoute exact route="aisles">
              <LegacyDepartmentRoute useMesh={true} />
            </RetailerRoute>
            <RetailerRoute route="aisle">
              <LegacyDepartmentRoute useMesh={true} />
            </RetailerRoute>
            <RetailerRoute route="product" component={LegacyProductRoute} />
            {/* Redirect legacy search to new product search */}
            <Redirect from={SEARCH_BASE_URL} to={routes['products-search']} />
            <Redirect from={NEW_SEARCH_URL} to={SEARCH_BASE_URL} />
            <Redirect from={SEARCH_RESULTS_URL} to={SEARCH_BASE_URL} />
          </RetailerSwitch>
        </ContentContainer>
      </RegionLocationProvider>
    </LegacyCatalog>
  )
}

export default CatalogSection
