import type { PropsWithChildren, FunctionComponent } from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'
import { useDashMessage } from '../../../intl/intl.hooks'
import { ColumnHeader, ItemList } from './RetailerAndSitePicker.styles'
import { type WarehousesForSelection } from './RetailerAndSitePicker.types'
import { RetailerItem } from './RetailerItem'
import { RetailerInsufficientPermissionInfoBar } from './RetailerInsufficientPermissionInfoBar'

interface Props {
  options: WarehousesForSelection[]
  onBannerSelect: (e: WarehousesForSelection) => void
  retailerInsufficientPermission?: boolean
  currentSelectedRetailer: WarehousesForSelection
}

const RetailerPaneStyle = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 'min-content',
})

export const RetailerPane: FunctionComponent<PropsWithChildren<Props>> = ({
  options,
  onBannerSelect,
  retailerInsufficientPermission,
  currentSelectedRetailer,
}) => {
  const [currentHover, setCurrentHover] = useState('')

  const bannersMessage = useDashMessage('retailerPicker.banners')

  const retailerRows = options.map(e => {
    const currentlySelected = currentSelectedRetailer?.value == e.value

    return (
      <RetailerItem
        key={e.value}
        retailer={e}
        selected={currentHover ? e.value == currentHover : currentlySelected}
        onClick={() => {
          onBannerSelect(e)
        }}
        onMouseEnter={() => {
          setCurrentHover(e.value)
        }}
        currentlySelected={currentlySelected}
      />
    )
  })

  return (
    <RetailerPaneStyle>
      {retailerInsufficientPermission && (
        <RetailerInsufficientPermissionInfoBar currentSelectedRetailer={currentSelectedRetailer} />
      )}
      <ColumnHeader>{bannersMessage}</ColumnHeader>
      <ItemList>{retailerRows}</ItemList>
    </RetailerPaneStyle>
  )
}
