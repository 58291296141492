import { WarehouseLocationFeature } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../../utils/domain/accessControl'
import { Permission } from '../../access-control/permissions'
import { FeatureToggle } from '../../access-control/featureToggles'

export const ReportsPickupAnalyticsAccessControl: DomainAccessControlConfig = [
  {
    warehouseLocationFeatures: WarehouseLocationFeature.RetailerPickup,
    permissions: Permission.Analytics,
    notFeatureToggles: FeatureToggle.InsightsPortalStoreLaborPerformanceV2,
  },
  {
    warehouseLocationFeatures: WarehouseLocationFeature.RetailerPickup,
    permissions: Permission.PickupAnalytics,
    notFeatureToggles: FeatureToggle.InsightsPortalStoreLaborPerformanceV2,
  },
]
