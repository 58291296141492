import type { PropsWithChildren, FunctionComponent } from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'
import { ConfirmIcon } from '@instacart/ids-core'
import { useRetailerId, useStoreConfigId } from '../../../utils/routing/params.hooks'
import { useDashMessages } from '../../../intl/intl.hooks'
import {
  ColumnHeader,
  ItemList,
  StoreConfigItem,
  StyledLogoImage,
  Tag,
} from './RetailerAndSitePicker.styles'
import {
  type WarehousesForSelection,
  type StoreConfigurationForSelection,
  SiteSelectOption,
} from './RetailerAndSitePicker.types'

const StoreConfigPaneStyle = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minWidth: '300px',
  padding: '0px 0px 0px 10px',
})

const StoreConfigPaneEmpty = styled.div({
  textAlign: 'center',
})

const RightAlign = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: '0',
})

interface Props {
  storeConfigurations: StoreConfigurationForSelection[]
  onStoreConfigurationSelect: (e: StoreConfigurationForSelection) => void
  currentUnconfirmedRetailer: WarehousesForSelection
}

export const StoreConfigPane: FunctionComponent<PropsWithChildren<Props>> = ({
  storeConfigurations,
  onStoreConfigurationSelect,
  currentUnconfirmedRetailer,
}) => {
  const retailerId = useRetailerId()
  const storeConfigId = useStoreConfigId({ throwOnMissing: false })
  const [currentHover, setCurrentHover] = useState('')

  const messages = useDashMessages(
    {
      selectBannerFirst: 'retailerPicker.selectBannerFirst',
      availableSitesForBanner: 'retailerPicker.availableSitesForBanner',
      notApplicable: 'retailerPicker.notApplicable',
      inactive: 'retailerPicker.inactive',
    },
    { currentRetailer: currentUnconfirmedRetailer?.label }
  )

  const storeConfigurationRows = storeConfigurations?.map(storeConfig => {
    const currentlySelected =
      storeConfig.retailerId == retailerId && storeConfig.value == storeConfigId
    const disabled = storeConfig.selectOption == SiteSelectOption.SELECT_NOT_APPLICABLE
    const active = storeConfig.storeConfig.active

    return (
      <StoreConfigItem
        key={storeConfig.value}
        selected={currentHover && !disabled ? storeConfig.value == currentHover : currentlySelected}
        onClick={e => {
          !disabled && onStoreConfigurationSelect(storeConfig)
        }}
        onMouseEnter={() => {
          !disabled && setCurrentHover(storeConfig.value)
        }}
        disabled={disabled}
      >
        <StyledLogoImage src={storeConfig.logoUrl} grayout={disabled} alt="logo" />
        <div>
          <div>{storeConfig.label}</div>
          <div>{storeConfig.domainName}</div>
        </div>

        <RightAlign>
          {currentlySelected && <ConfirmIcon color="brandPrimaryRegular" />}{' '}
          {disabled && <Tag color={'#F6F7F8'}>{messages.notApplicable}</Tag>}
          {!active && <Tag color={'#F6F7F8'}>{messages.inactive}</Tag>}
        </RightAlign>
      </StoreConfigItem>
    )
  })

  return (
    <StoreConfigPaneStyle>
      {currentUnconfirmedRetailer ? (
        <>
          <ColumnHeader>{messages.availableSitesForBanner}</ColumnHeader>
          <ItemList>{storeConfigurationRows}</ItemList>
        </>
      ) : (
        <StoreConfigPaneEmpty>{messages.selectBannerFirst}</StoreConfigPaneEmpty>
      )}
    </StoreConfigPaneStyle>
  )
}
