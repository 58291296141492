// @ts-nocheck legacy file transformed to TS
import { Fragment } from 'react'
import styled from '@emotion/styled'
import { Icon, colors, Text } from 'ic-snacks'
import { FormattedMessage } from 'react-intl'
import ButtonBase from '../ButtonBase'
import PageInput from './PageInput'

const paginationIconStyle = {
  fontSize: '15px',
  color: colors.GREEN_600,
  top: '1px',
}

const textStyle = {
  color: colors.GRAY_46,
}

const disabledPaginationIconStyle = {
  color: colors.GRAY_88,
}

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-left: 25px;
  padding-right: 25px;
  border-top: 1px solid ${colors.GRAY_93};
  background-color: ${colors.WHITE};
`

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${colors.GRAY_93};
  height: 34px;
  border-radius: 5px;
`

const PageControl = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 100%;
  border: 0;
  color: inherit;
  overflow: visible;
  padding: 0;
  user-select: none;
  background: ${colors.WHITE};
  color: ${props => (props.disabled ? colors.GRAY_46 : colors.GREEN_600)};
  transition: background 0.1s ease-in-out;
  &:hover:enabled {
    background: ${colors.GRAY_93};
  }
  &:first-child {
    border-radius: 5px 0 0 5px;
  }
  &:last-child {
    border-radius: 0 5px 5px 0;
  }
`

const TotalPageCount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: ${colors.WHITE};
  color: ${colors.GRAY_46};
  padding: 10px;
  border-right: 1px solid ${colors.GRAY_93};
`

export default function TablePaginator(props) {
  if (!props.paginated) return <PaginationContainer />
  const page = props.page + 1
  const { hasNextPage, totalPages, perPage, totalCount, data } = props
  const hasPageCount = totalPages && totalPages !== -1
  if (!hasPageCount && page === 1 && !hasNextPage) {
    return <PaginationContainer />
  }
  const isPreviousDisabled = page === 1
  const isNextDisabled = page === props.totalPages || (!hasPageCount && !hasNextPage)

  const previous = (
    <PageControl
      disabled={isPreviousDisabled || props.loading}
      onClick={() => props.onPageChange(props.page - 1)}
    >
      <Icon
        style={[
          paginationIconStyle,
          (props.loading || isPreviousDisabled) && disabledPaginationIconStyle,
        ]}
        name="arrowLeftSmall"
      />
    </PageControl>
  )
  const next = (
    <PageControl
      disabled={isNextDisabled || props.loading}
      onClick={() => props.onPageChange(props.page + 1)}
    >
      <Icon
        style={[
          paginationIconStyle,
          (props.loading || isNextDisabled) && disabledPaginationIconStyle,
        ]}
        name="arrowRightSmall"
      />
    </PageControl>
  )

  const paginations = (
    <Fragment>
      {previous}
      <PageInput
        page={page}
        totalPages={hasPageCount ? totalPages : undefined}
        onPageChange={props.onPageChange}
      />
      {hasPageCount && (
        <TotalPageCount>
          <Text variant="T.12">
            <FormattedMessage id="components.table.totalPages" values={{ pageCount: totalPages }} />
          </Text>
        </TotalPageCount>
      )}
      {next}
    </Fragment>
  )

  let items = <div />
  if (perPage && totalCount && data) {
    const from = (page - 1) * perPage + 1
    const to = Math.min(from + perPage - 1, totalCount)
    items = (
      <FormattedMessage id="components.table.itemCounts" values={{ from, to, count: totalCount }} />
    )
  }

  return (
    <PaginationContainer>
      <Text variant="T.12" style={textStyle} elementType="div">
        {items}
      </Text>
      <Pagination>{paginations}</Pagination>
    </PaginationContainer>
  )
}
