import type { ReactNode } from 'react'
import { useMemo } from 'react'
import {
  Breadcrumb,
  Breadcrumbs,
  colors,
  getTargetedPortal,
  spacing,
} from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { useStoreConfigurations } from '../../../api/store-configurations/useStoreConfigurations.hooks'
import { StoreConfigurationRelationshipEnum } from '../../../__codegen__/api'
import { useRetailerId, useStoreConfigIds } from '../../../utils/routing/params.hooks'
import { CircleLogo } from '../../common/select-partner/CircleLogo'
import { type SiteOperationIntent } from '../../common/site-select/SiteSelect'
import { type FilterSitesFn } from '../retailer-and-site-picker/RetailerAndSitePicker.types'
import { useWarehouseContext } from '../../../utils/contexts/warehouse/WarehouseContext.hooks'
import { useDashMessage } from '../../../intl/intl.hooks'
import { type StoreConfigurationScopedRouteParams } from '../../../legacy/common/types'
import { usePartnerContext } from '../../../utils/contexts/partner/PartnerContext.hooks'
import { useCurrentAccountDetails } from '../../../api/current-account/currentAccount.hooks'
import MultiScopePickerWrapper from '../multi-scope-picker-2/MultiScopePickerWrapper'
import { RetailerAndSitePickerWrapper } from '../retailer-and-site-picker/RetailerAndSitePickerWrapper'
import { StoreConfigScopeWrapper } from './StoreConfigScopeWrapper'
import { BannerSelectRequired } from './BannerSelectRequired'

export interface RetailerScopeWrapperProps {
  children?: ReactNode
  selectionType?: ViewType
  retailerSelectionOptional?: boolean
  storeConfigSelectionOptional?: boolean
  storeConfigOperationIntent?: SiteOperationIntent // 'view' or 'edit'
  storeConfigFilter?: FilterSitesFn // store config filters
  titles?: string[]
  formattedMessageTitle?: string
  retailerInsufficientPermission?: boolean
  hiddenScopePicker?: boolean
  multiSelect?: boolean
}

const Header = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: `${spacing.X16} ${spacing.X16}`,
  borderBottom: `1px solid ${colors.GRAYSCALE.X20}`,
})

const Toolbar = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
})

export enum ViewType {
  RETAILER,
  RETAILER_AND_STORE_CONFIG,
  STORE_CONFIG,
}

export const [HeaderTitlePortalTarget, HeaderTitlePortal] = getTargetedPortal()
export const [HeaderToolsPortalTarget, HeaderToolsPortal] = getTargetedPortal()
export const [HeaderExtraContentPortalTarget, HeaderExtraContentPortal] = getTargetedPortal()

export const RetailerScopeWrapper = ({
  children,
  retailerSelectionOptional,
  storeConfigSelectionOptional,
  selectionType,
  storeConfigOperationIntent,
  storeConfigFilter,
  titles,
  formattedMessageTitle,
  retailerInsufficientPermission,
  hiddenScopePicker,
  multiSelect,
}: RetailerScopeWrapperProps) => {
  const { warehouses: availableWarehouses, partnerName } = usePartnerContext()
  const retailerId = Number(useRetailerId())
  const { warehouse } = useWarehouseContext()
  const storeConfigIds = useStoreConfigIds({ throwOnMissing: false })
  const storeConfigurations = useStoreConfigurations({
    retailerIds: availableWarehouses?.map(e => e.id.toString()),
    storeConfigRelationship: StoreConfigurationRelationshipEnum.Associated,
  })

  const { account: currentAccount } = useCurrentAccountDetails()
  const scopePickerV2 = !!currentAccount?.featureToggles?.find(
    feature => feature.name === 'rt_scope_picker_v2'
  )

  const params = useParams<StoreConfigurationScopedRouteParams>()
  const storeConfigId = Number(params.storeConfigId)

  selectionType = selectionType || ViewType.RETAILER

  // if scope picker is optional, then override retailerSelectionOptional, storeConfigSelectionOptional
  retailerSelectionOptional ||= hiddenScopePicker
  storeConfigSelectionOptional ||= hiddenScopePicker

  const intl = useIntl()
  const headerComponent = useMemo(() => {
    if (titles) {
      const breadcrumbs = titles?.map(title => (
        <Breadcrumb key={title}>{intl.formatMessage({ id: title }) || title}</Breadcrumb>
      ))
      return <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
    } else if (formattedMessageTitle) {
      return (
        <Breadcrumbs>
          <Breadcrumb>
            <FormattedMessage id={formattedMessageTitle} />
          </Breadcrumb>
        </Breadcrumbs>
      )
    }
  }, [formattedMessageTitle, intl, titles])

  const storeConfigs = storeConfigurations?.data?.storeConfigurationsByRetailerIds

  const bannerSelected = retailerSelectionOptional || warehouse
  const siteSelected = storeConfigSelectionOptional || storeConfigId > 0

  const toolContentVisible =
    (selectionType == ViewType.RETAILER && bannerSelected) ||
    (selectionType == ViewType.RETAILER_AND_STORE_CONFIG && bannerSelected && siteSelected) ||
    (selectionType == ViewType.STORE_CONFIG && siteSelected)

  children = toolContentVisible ? children : null

  if (selectionType == ViewType.RETAILER_AND_STORE_CONFIG) {
    children = (
      <StoreConfigScopeWrapper
        storeConfig={storeConfigs?.find(e => e.id === String(storeConfigId))}
        storeConfigs={storeConfigs?.filter(e => storeConfigIds.includes(e.id))}
      >
        {children}
      </StoreConfigScopeWrapper>
    )
  }

  return (
    <>
      <Header>
        {toolContentVisible ? <HeaderTitlePortalTarget /> : headerComponent}
        {!hiddenScopePicker && (
          <>
            <Toolbar>
              {scopePickerV2 ? (
                <MultiScopePickerWrapper
                  selectionType={selectionType}
                  multiSelect={multiSelect}
                  scopeSelectionOptional={retailerSelectionOptional}
                  storeConfigOperationIntent={storeConfigOperationIntent}
                  storeConfigFilter={storeConfigFilter}
                />
              ) : (
                <RetailerAndSitePickerWrapper
                  selectionType={selectionType}
                  retailerSelectionOptional={retailerSelectionOptional}
                  storeConfigOperationIntent={storeConfigOperationIntent}
                  storeConfigFilter={storeConfigFilter}
                  retailerInsufficientPermission={retailerInsufficientPermission}
                />
              )}
              <HeaderToolsPortalTarget />
            </Toolbar>
            <CurrentScopeInfoBar
              retailerId={retailerId}
              partnerName={partnerName}
              retailerSelectionOptional={retailerSelectionOptional}
            />
          </>
        )}
        {toolContentVisible && <HeaderExtraContentPortalTarget />}
      </Header>
      {toolContentVisible ? children : <BannerSelectRequired selectionType={selectionType} />}
    </>
  )
}

const BlueInfoBar = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px 32px 12px 12px',
  gap: '12px',
  background: '#F2F8FF',
})

const CurrentScopeInfoBar = ({ retailerId, partnerName, retailerSelectionOptional }) => {
  const infoMessage = useDashMessage('retailerPicker.noBannerSelectedCurrentScopeInfo', {
    partnerName,
  })

  if (retailerId == 0 && retailerSelectionOptional) {
    return (
      <BlueInfoBar>
        <CircleLogo size={19} color={'#2B78C6'}>
          i
        </CircleLogo>
        {infoMessage}
      </BlueInfoBar>
    )
  }
  return null
}
