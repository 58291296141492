import type { PropsWithChildren, FunctionComponent } from 'react'
import { Fragment } from 'react'
import { useCurrentAccountDetails } from '../../../../api/current-account/currentAccount.hooks'

// Load all globally-required data before the app boots
export const AppProviderPreloader: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { loading } = useCurrentAccountDetails()

  if (loading) {
    return null
  }

  return <Fragment>{children}</Fragment>
}
