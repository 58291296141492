import { FormattedMessage } from 'react-intl'
import type { FunctionComponent } from 'react'
import { fontSize, notify } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { colors } from 'ic-snacks'

const ErrorDiv = styled.div({
  fontSize: fontSize.X12,
  color: colors.RED_500,
})

export const notifyUpdateErrors = (error: string) => {
  if (error == 'DURATION_TOO_LONG') {
    notify({
      contents: (
        <FormattedMessage
          id={
            'catalog.products.locations.requestSubmitted.availabilityScoreOverride.dateLimitError'
          }
        />
      ),
      timeout: 5000,
    })
  } else if (error == 'TOO_MANY_REQUESTS') {
    notify({
      contents: (
        <FormattedMessage
          id={
            'catalog.products.locations.requestSubmitted.availabilityScoreOverride.countTooHighError'
          }
        />
      ),
      timeout: 5000,
    })
  } else if (error == 'EXACTLY_ONE_ENTITY_ID_TYPE_ALLOWED') {
    notify({
      contents: (
        <FormattedMessage
          id={
            'catalog.products.locations.requestSubmitted.availabilityScoreOverride.multipleEntityTypeError'
          }
        />
      ),
      timeout: 5000,
    })
  }
}

export const AvailabilityScoreOverrideDateError: FunctionComponent = () => {
  return (
    <ErrorDiv>
      <FormattedMessage id="catalog.products.locations.requestSubmitted.availabilityScoreOverride.dateLimitError" />
    </ErrorDiv>
  )
}
