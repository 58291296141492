// prettier-ignore
const EN_US = {
  'authenticationDomain.logIn.header': 'Welcome to the Instacart Platform Portal',
  'authenticationDomain.logIn.form.fields.email.placeholder': 'Email',
  'authenticationDomain.logIn.form.fields.email.error.required': "Email can't be blank",
  'authenticationDomain.logIn.form.fields.email.error.pattern': 'Invalid Email',
  'authenticationDomain.logIn.form.fields.password.placeholder': 'Password',
  'authenticationDomain.logIn.form.fields.password.error.required': "Password can't be blank",
  'authenticationDomain.logIn.form.fields.password.error.minLength':
    'Password must be at least 8 characters long',
  'authenticationDomain.logIn.form.error.invalidCredentials': 'Invalid Credentials',
  'authenticationDomain.logIn.form.error.disabledAccount': 'Account is disabled. Please contact support.',
  'authenticationDomain.logIn.form.error.captchaFailed': 'Invalid Captcha value. Please refresh and try again.',
  'authenticationDomain.logIn.form.error.common': 'Login Failed',
  'authenticationDomain.logIn.form.submit': 'Login',
  'authenticationDomain.logIn.forgotPasswordDetail': 'Forgot your password?',
  'authenticationDomain.logIn.backToEmail': 'Back to email',
  'authenticationDomain.logIn.loggingInWithEmail': 'Logging in as {email}',
  'authenticationDomain.logIn.forgotPasswordAction': 'Click here',
  'authenticationDomain.logIn.signUpDetail': "Don't have an account? ",
  'authenticationDomain.logIn.signUpAction': 'Sign up',
  'authenticationDomain.logIn.sessionExpired': 'Your session has expired. Please log in again.',
  'authenticationDomain.logIn.disabledAccount': 'Your account is disabled. Please contact support.',
  'authenticationDomain.logIn.dev.message':
    'This is the dev environment to access your development and testing data. ',
  'authenticationDomain.createAccount.header': 'Create your account',
  'authenticationDomain.createAccount.form.fields.email.placeholder': 'Email',
  'authenticationDomain.createAccount.form.fields.email.error.required': "Email can't be blank",
  'authenticationDomain.createAccount.form.fields.email.error.pattern': 'Invalid Email',
  'authenticationDomain.createAccount.form.fields.givenName.placeholder': 'Full Name (e.g. John Doe)',
  'authenticationDomain.createAccount.form.fields.givenName.error.required': "Name can't be blank",
  'authenticationDomain.createAccount.form.fields.surname.placeholder': 'Last Name',
  'authenticationDomain.createAccount.form.fields.surname.error.required': "Last Name can't be blank",
  'authenticationDomain.createAccount.form.fields.phoneNumber.placeholder': 'Phone Number',
  'authenticationDomain.createAccount.form.fields.phoneNumber.error.required': "Phone Number can't be blank",
  'authenticationDomain.createAccount.form.fields.phoneNumber.error.pattern': 'Invalid Phone Number',
  'authenticationDomain.createAccount.form.fields.password.placeholder': 'Password',
  'authenticationDomain.createAccount.form.fields.password.error.required': "Password can't be blank",
  'authenticationDomain.createAccount.form.fields.password.error.minLength':
    'Password must be at least 8 characters long',
  'authenticationDomain.createAccount.form.fields.confirmPassword.placeholder': 'Confirm Password',
  'authenticationDomain.createAccount.form.fields.confirmPassword.error.validate': 'Passwords must match',
  'authenticationDomain.createAccount.form.error.insecurePassword': 'Please pick a more secure password',
  'authenticationDomain.createAccount.form.error.existingEmail': 'An account with that email already exists',
  'authenticationDomain.createAccount.form.error.captchaFailed':
    'Invalid Captcha value. Please refresh and try again.',
  'authenticationDomain.createAccount.form.error.common': 'Sign Up Failed',
  'authenticationDomain.createAccount.form.submit': 'Create your account',

  'authenticationDomain.accountSetup.header': 'Create your account',
  'authenticationDomain.accountSetup.content.success': 'Success - your account has been created!',
  'authenticationDomain.accountSetup.successAction': 'Continue',
  'authenticationDomain.accountSetup.content.error.expiredToken':
    'Sorry - your account setup token is expired. Please try again!',
  'authenticationDomain.accountSetup.content.error.notFound':
    'Sorry - your account setup token is invalid. Please try again!',
  'authenticationDomain.accountSetup.content.error.insecurePassword':
    'Password is too easy to guess. Please try a more unique password (at least 8 characters long).',
  'authenticationDomain.accountSetup.content.error.common':
    'Sorry - we were unable to set up your account. Please try again!',
  'authenticationDomain.accountSetup.errorAction': 'Send Me Another Account Setup Email',

  'authenticationDomain.authService.callback.header': 'Logging in...',

  'authenticationDomain.requestAccountSetup.header': 'Request Account Setup Email',
  'authenticationDomain.requestAccountSetup.form.fields.email.placeholder': 'Email',
  'authenticationDomain.requestAccountSetup.form.fields.email.error.required': "Email can't be blank",
  'authenticationDomain.requestAccountSetup.form.fields.email.error.pattern': 'Invalid Email',
  'authenticationDomain.requestAccountSetup.form.error.emailNotFound': 'No account matches that email.',
  'authenticationDomain.requestAccountSetup.form.error.common': 'Account Setup Email Request Failed.',
  'authenticationDomain.requestAccountSetup.form.submit': 'Send Email',
  'authenticationDomain.requestAccountSetup.form.error.alreadyExists': 'Account already exists. Please have the user login.',
  'authenticationDomain.requestAccountSetup.form.error.alreadyExistsRedirect': 'The account associated with this token already exists. Please login.',

  'authenticationDomain.requestEmailVerification.header': 'Verify your email',
  'authenticationDomain.requestEmailVerification.form.fields.email.placeholder': 'Email',
  'authenticationDomain.requestEmailVerification.form.fields.email.error.required': "Email can't be blank",
  'authenticationDomain.requestEmailVerification.form.fields.email.error.pattern': 'Invalid Email',
  'authenticationDomain.requestEmailVerification.form.error.emailNotFound': 'No account matches that email.',
  'authenticationDomain.requestEmailVerification.form.error.captchaFailed':
    'Invalid Captcha value. Please refresh and try again.',
  'authenticationDomain.requestEmailVerification.form.error.common': 'Verification Email Request Failed.',
  'authenticationDomain.requestEmailVerification.form.submit': 'Send Email',
  'authenticationDomain.pendingEmailVerification.header': 'Verify your email',
  'authenticationDomain.pendingEmailVerification.content':
    'We just sent you an email - please click on the verification link that we sent you in order to activate your account.',
  'authenticationDomain.pendingEmailVerification.requestAction': 'Request Another Verification Email',
  'authenticationDomain.emailVerification.header': 'Email Verification',
  'authenticationDomain.emailVerification.content.success': 'Success - your email has been verified!',
  'authenticationDomain.emailVerification.successAction': 'Continue',
  'authenticationDomain.emailVerification.content.error.expiredToken':
    'Sorry - your verification token is expired. Please try again!',
  'authenticationDomain.emailVerification.content.error.notFound':
    'Sorry - your verification token is invalid. Please try again!',
  'authenticationDomain.emailVerification.content.error.common':
    'Sorry - we were unable to verify your email. Please try again!',
  'authenticationDomain.emailVerification.errorAction': 'Send Me Another Email',
  'authenticationDomain.passwordReset.header': 'Set your new password',
  'authenticationDomain.passwordReset.content.success': 'Success - your password has been reset!',
  'authenticationDomain.passwordReset.successAction': 'Continue',
  'authenticationDomain.passwordReset.content.error.expiredToken':
    'Sorry - your password reset token is expired. Please try again!',
  'authenticationDomain.passwordReset.content.error.notFound':
    'Sorry - your password reset token is invalid. Please try again!',
  'authenticationDomain.passwordReset.content.error.common':
    'Sorry - we were unable to reset your password. Please try again!',
  'authenticationDomain.passwordReset.errorAction': 'Send Me Another Password Reset Email',
  'authenticationDomain.passwordReset.form.fields.password.placeholder': 'New Password',
  'authenticationDomain.passwordReset.form.fields.password.error.required': "Password can't be blank",
  'authenticationDomain.passwordReset.form.fields.password.error.minLength':
    'Password must be at least 8 characters long',
  'authenticationDomain.passwordReset.form.fields.confirmPassword.placeholder': 'Confirm Password',
  'authenticationDomain.passwordReset.form.fields.confirmPassword.error.validate': 'Passwords must match',
  'authenticationDomain.passwordReset.form.error.insecurePassword': 'Please pick a more secure password',
  'authenticationDomain.passwordReset.form.submit': 'Reset Password',
  'authenticationDomain.requestPasswordReset.header': 'Reset your password',
  'authenticationDomain.requestPasswordReset.form.fields.email.placeholder': 'Email',
  'authenticationDomain.requestPasswordReset.form.fields.email.error.required': "Email can't be blank",
  'authenticationDomain.requestPasswordReset.form.fields.email.error.pattern': 'Invalid Email',
  'authenticationDomain.requestPasswordReset.form.error.emailNotFound': 'No account matches that email.',
  'authenticationDomain.requestPasswordReset.form.error.captchaFailed':
    'Invalid Captcha value. Please refresh and try again.',
  'authenticationDomain.requestPasswordReset.form.error.common': 'Password Reset Request Failed.',
  'authenticationDomain.requestPasswordReset.form.submit': 'Send Email',
  'authenticationDomain.pendingPasswordReset.header': 'Reset your password',
  'authenticationDomain.pendingPasswordReset.content':
    'We just sent you an email - please click on the reset link that we sent you in order to reset the password for your account.',
  'authenticationDomain.pendingPasswordReset.requestAction': 'Request Another Password Reset Email',

  'authenticationDomain.signup.retailerId.label': 'Your company',
  'authenticationDomain.signup.retailerId.description':
    'Only retailers on the Instacart Platform can be entered here.',
  'authenticationDomain.signup.retailerId.beginTyping': 'Begin typing to find your company.',
  'authenticationDomain.signup.retailerId.noResults': 'No results found.',

  'authenticationDomain.signup.retailerId.placeholder': 'Enter your company name',
  'authenticationDomain.signup.headline': 'Account not found',
  'authenticationDomain.signup.description':
    'To sign up, you can request an account from your company admin below.',
  'authenticationDomain.signup.description2':
    'If you have an account, double check for typos, try a different email, or contact your admin for help.',
  'authenticationDomain.signup.email.label': 'Work email',
  'authenticationDomain.signup.fullName.label': 'Your name',
  'authenticationDomain.signup.fullName.placeholder': 'First and last name',
  'authenticationDomain.signup.requestReason.label': 'Reason for request',
  'authenticationDomain.signup.requestReason.description':
    'Tell your admin why you want access to the Instacart Platform Portal. Be as detailed as possible—include your team, job title, and how you plan to use the platform.',
  'authenticationDomain.signup.continue': 'Continue',
  'authenticationDomain.signup.back': 'Back',
  'authenticationDomain.signup.required': 'This field is required',
  'authenticationDomain.signup.email.pattern': 'Must be a valid email address',
  'authenticationDomain.signup.requestReason.minLength': 'Must be at least 30 characters',
  'authenticationDomain.signup.retailerId.required': 'Please select a company',
  'authenticationDomain.signup.error.generic': 'We were unable to process your request. Please try again later.',
  'authenticationDomain.signup.emailVerificationRequired.headline': 'Email verification required',
  'authenticationDomain.signup.emailVerificationRequired.description':
    'We sent a message to {email}. Please use the link in this email to complete verification.',

  'authenticationDomain.signupEmailVerification.loading.headline': 'Verifying your email...',
  'authenticationDomain.signupEmailVerification.loading.description': 'Hold on while we validate your email...',
  'authenticationDomain.signupEmailVerification.success.headline': 'Your request is on its way!',
  'authenticationDomain.signupEmailVerification.success.description':
    'Your account request has been sent to your company admin, and you’ll get an email once it’s approved. In the meantime, you can contact your admin directly to help speed up the process.',
  'authenticationDomain.signupEmailVerification.backButton': 'Back to instacart.com',
  'authenticationDomain.signupEmailVerification.error.headline': 'Something went wrong',
  'authenticationDomain.signupEmailVerification.error.message': "We couldn't verify this code. Please try again.",
  'authenticationDomain.signupEmailVerification.errorButton': 'Try again',
} as const

export default EN_US
