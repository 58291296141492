import type { PropsWithChildren, FC } from 'react'
import {
  type RecommendationShiftType,
  RecommendedShiftsContext,
  RecommendationTypeEnum,
  ShiftTypeEnum,
} from '@retailer-platform/domain-shifts'
type Props = {}

const MOCK_RECOMMENDATIONS: RecommendationShiftType[] = [
  {
    id: '123',
    retailerLocationId: '2334',
    date: '2021-08-15',
    recommendationType: RecommendationTypeEnum.DemandSurge,
    shiftType: ShiftTypeEnum.PickingOnly,
    startsAt: '2021-08-15T10:00:00-07:00',
    endsAt: '2021-08-15T11:00:00-07:00',
    __typename: 'PartnerPickCapacityRecommendation',
  },
  {
    id: '456',
    retailerLocationId: '2334',
    date: '2021-08-15',
    recommendationType: RecommendationTypeEnum.NoRunner,
    shiftType: ShiftTypeEnum.Runner,
    startsAt: '2021-08-15T09:00:00-07:00',
    endsAt: '2021-08-15T10:00:00-07:00',
    __typename: 'PartnerPickCapacityRecommendation',
  },
  {
    id: '789',
    retailerLocationId: '2334',
    date: '2021-08-15',
    recommendationType: RecommendationTypeEnum.DemandSurge,
    shiftType: ShiftTypeEnum.Runner,
    startsAt: '2021-08-15T12:00:00-07:00',
    endsAt: '2021-08-15T13:00:00-07:00',
    __typename: 'PartnerPickCapacityRecommendation',
  },
]

const MOCK_ALLOWED_RECOMMENDATION_TYPES = [
  RecommendationTypeEnum.NoRunner,
  RecommendationTypeEnum.DemandSurge,
]

const MockRecommendationShiftsContextProvider: FC<PropsWithChildren<Props>> = ({ children }) => (
  <RecommendedShiftsContext.Provider
    value={{
      recommendations: MOCK_RECOMMENDATIONS,
      allowedRecommendationTypes: MOCK_ALLOWED_RECOMMENDATION_TYPES,
      refetchRecommendations: jest.fn(),
    }}
  >
    {children}
  </RecommendedShiftsContext.Provider>
)

export default MockRecommendationShiftsContextProvider
