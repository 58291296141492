import { Modal } from '@retailer-platform/shared-components'
import type { PropsWithChildren, FunctionComponent } from 'react'
import { AccountManagementMyAccountForm } from '../my-account-form/AccountManagementMyAccountForm'

type AccountManagementMyAccountModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const AccountManagementMyAccountModal: FunctionComponent<
  PropsWithChildren<AccountManagementMyAccountModalProps>
> = ({ isOpen, onClose }) => {
  if (!isOpen) return null

  return (
    <Modal onRequestClose={onClose} isOpen maxHeight={'none'}>
      <AccountManagementMyAccountForm />
    </Modal>
  )
}
