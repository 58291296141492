import React, { type ReactElement, useMemo } from 'react'
import { css, type SerializedStyles } from '@emotion/react'
import { SortIcon, SortDescendingIcon, SortAscendingIcon } from '@instacart/ids-core'
import {
  gridHeaderCellStyles,
  gridSortedCellStyles,
  gridSortedHeaderCellStyles,
  gridCompactHeaderCellStyles,
  gridSortableHeaderStyles,
} from '../utils/grid.styles'
import { type GridDatum, type GridColumnInstance } from '../utils/grid.types'
import { colors } from '../../../foundation'
import { Tooltip } from '../../tooltip/Tooltip'
import { useTDSContext, typography } from '../../../tds/index'
interface Props<TData extends GridDatum> extends GridColumnInstance<TData> {
  compact?: boolean
}

const sortIcon = ({ isSorted, isSortedDesc }: { isSorted: boolean; isSortedDesc: boolean }) => {
  const iconCss = { marginLeft: 8 }

  if (isSorted && isSortedDesc) {
    return <SortDescendingIcon size={16} css={iconCss} />
  }

  if (isSorted && !isSortedDesc) {
    return <SortAscendingIcon size={16} css={iconCss} />
  }

  return <SortIcon size={16} color="systemGrayscale30" css={iconCss} />
}

export function GridHeaderColumnCell<TData extends GridDatum>({
  getHeaderProps,
  getSortByToggleProps,
  render,
  isSorted,
  isSortedDesc,
  canSort,
  headerStyles,
  compact,
  tooltip,
}: Props<TData> & { headerStyles?: SerializedStyles }): ReactElement {
  const renderedHeader = useMemo(() => render('Header'), [render]) as unknown as JSX.Element
  const { style, ...headerProps } = getHeaderProps(getSortByToggleProps())
  const { key, ...restHeaderProps } = headerProps

  const { useTDS } = useTDSContext()
  const tdsHeaderStyles = css(typography.bodyEmphasized, compact && typography.accentEmphasized)

  const dynamicStyles = css(
    style as SerializedStyles,
    gridHeaderCellStyles,
    canSort && gridSortableHeaderStyles,
    isSorted && gridSortedCellStyles,
    isSorted && gridSortedHeaderCellStyles,
    headerStyles && headerStyles,
    compact && gridCompactHeaderCellStyles,
    useTDS && tdsHeaderStyles
  )

  const icon = sortIcon({
    isSorted,
    isSortedDesc: Boolean(isSortedDesc),
  })

  const content = (
    <div data-testid="cell-text">
      {tooltip ? (
        <span css={{ borderBottom: `1px dashed ${colors.GRAYSCALE.X70}` }}>{renderedHeader}</span>
      ) : (
        renderedHeader
      )}
    </div>
  )

  return (
    <div data-testid="header-title" key={key} {...restHeaderProps} css={dynamicStyles}>
      {tooltip ? (
        <Tooltip
          placement="bottom"
          target={
            <div
              css={{
                cursor: 'help',
              }}
            >
              {content}
            </div>
          }
        >
          {tooltip}
        </Tooltip>
      ) : (
        content
      )}

      {canSort && icon}
    </div>
  )
}
