import type { PropsWithChildren, FunctionComponent } from 'react'
import { useState, useEffect, useCallback } from 'react'
import { DateRangePicker, type FocusedInputShape } from 'react-dates'
import { type Moment } from 'moment'

export type DateRange = {
  startDate?: Moment
  endDate?: Moment
}

interface Props {
  value: DateRange
  onChange: (nextValue: DateRange) => void
  isOutsideRange?: (day: Moment) => boolean
  withPortal?: boolean
}

export const DateRangeCustom: FunctionComponent<PropsWithChildren<Props>> = ({
  value,
  onChange,
  isOutsideRange,
  withPortal = true,
}) => {
  const [internalValue, setInternalValue] = useState<DateRange>(value)
  const [focusedInput, setFocusedState] = useState<FocusedInputShape | null>(null)
  useEffect(() => {
    setInternalValue(value)
  }, [value])
  const handleFocusChange = useCallback((nextFocusedInput: FocusedInputShape | null) => {
    setFocusedState(nextFocusedInput)
  }, [])
  const handleInternalValueChange = useCallback(
    (nextDates: DateRange) => {
      setInternalValue(nextDates)

      // If the focused input was endDate, then we know the modal is going to close
      // Now, we execute the search
      if (focusedInput === 'endDate' && nextDates.endDate) {
        onChange(nextDates)
      }
    },
    [focusedInput, onChange]
  )

  return (
    <DateRangePicker
      minimumNights={0}
      isOutsideRange={isOutsideRange}
      withPortal={withPortal}
      startDate={internalValue.startDate}
      startDateId="startDate"
      endDate={internalValue.endDate}
      endDateId="endDate"
      onDatesChange={handleInternalValueChange}
      focusedInput={focusedInput}
      onFocusChange={handleFocusChange}
    />
  )
}
