import type { PropsWithChildren, FunctionComponent } from 'react'
import { useState, useCallback } from 'react'
import { Tooltip } from 'ic-snacks'
import { type TooltipProps } from 'ic-snacks/dist/esm/components/Tooltip/Tooltip'
import styled from '@emotion/styled'

type RequiredTooltipProps = Pick<TooltipProps, 'target' | 'children'>
type OptionalTooltipProps = Pick<TooltipProps, 'placement' | 'snacksStyle'>

export type DashTooltipProps = RequiredTooltipProps &
  Partial<OptionalTooltipProps> & {
    disabled?: boolean
  }

const EventLayerContainer = styled.div`
  position: relative;
  display: inline-block;
`

const DisabledEventLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: not-allowed;
`

export const DashTooltip: FunctionComponent<PropsWithChildren<DashTooltipProps>> = ({
  placement,
  target,
  snacksStyle,
  children,
  disabled,
}) => {
  const [visible, setVisible] = useState(false)
  const showTooltip = useCallback(() => setVisible(true), [setVisible])
  const hideTooltip = useCallback(() => setVisible(false), [setVisible])
  const resolvedSnacksStyle = snacksStyle || 'dark'
  /**
   * For disabled elements in Chrome, onMouseLeave (and other events) don't fire as expected.
   * To handle this (reasonable) usecase, we generate an absolutely positioned "mask" element
   * over our target. That mask div now becomes responsible for firing enter / leave events.
   * To signal lack of interactivity, "disabled" state is spoofed with a not-allowed cursor.
   *
   * https://github.com/facebook/react/issues/4251
   */
  const targetDisabled = disabled

  return (
    <Tooltip
      target={
        <EventLayerContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
          {target}
          {targetDisabled && <DisabledEventLayer />}
        </EventLayerContainer>
      }
      isVisible={visible}
      size="small"
      placement={placement}
      snacksStyle={resolvedSnacksStyle}
      onDismiss={hideTooltip}
      onShow={showTooltip}
    >
      {children}
    </Tooltip>
  )
}
