import type { PropsWithChildren, FunctionComponent } from 'react'
import { DashRedirect } from '../../../../legacy/dash-components/dash-redirect/DashRedirect'
import { useHaltRedirectAccessDetails } from './utils/haltRedirect.hooks'

// After logging in, the user should land on the HaltRedirect page.
// This page will take a look at the users access-control details, then send them
// along to the appropriate "default" section
//
//    - Admins should be sent to the App Selector
//
//    - Standard users should be sent to the Retailer Dashboard
//
//    - Any remaining users are marked as "limited access"
//      Users in this category don't have the required permissions to view any pages,
//      So we send them along to a page and will ask them to contact the support team.
//
export const HaltRedirect: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const { loading, canAccessRetailerDashboard, authServiceRequired, isInternal } =
    useHaltRedirectAccessDetails()

  if (loading) {
    return null
  }

  if (canAccessRetailerDashboard) {
    return <DashRedirect toRoute="scope" />
  }

  // An auth service/SSO account with no access will be redirected to a page with more details on next steps
  if (!canAccessRetailerDashboard && authServiceRequired && isInternal) {
    return <DashRedirect toRoute="app-halt-auth-service-account-limited-access" />
  }

  // Generic "No Access" message for other cases
  return <DashRedirect toRoute="app-halt-account-limited-access" />
}
