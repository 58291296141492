import type { PropsWithChildren, FunctionComponent } from 'react'
import { createContext, useContext, useMemo } from 'react'
import {
  type ICPlatformVersion,
  useGetICPlatformVersion,
  fallbackICPlatformVersion,
} from './versioning'

const ICPlatformContext = createContext<{
  /**
   * The enum member value of the resolved version, use along with the `ICPlatformVersion` enum.
   * If you're using this context directly, this is the version that has been resolved for the current user,
   * and it's likely what you need!
   *
   * If you want the returned values from origin, use `currentVersion` instead.
   */
  version?: ICPlatformVersion
  /**
   * The enum member value of the current version that was returned from the origin.
   * Keep in mind this does not consider the current user's group.
   */
  currentVersion?: ICPlatformVersion
  isVersionOverridden: boolean
}>({ version: fallbackICPlatformVersion, isVersionOverridden: false })

/**
 * Consumes the Instacart Platform context data.
 */
export const useICPlatformContext = () => useContext(ICPlatformContext)

/**
 * This provider is meant to be used to provide Instacart Platform context data throughout the app.
 * If there's any other needs that may require adding global data, this is the place for it.
 */
export const ICPlatformProvider: FunctionComponent<PropsWithChildren<unknown>> = ({ children }) => {
  const { resolvedVersion, currentVersion, isVersionOverridden } = useGetICPlatformVersion()

  const providerValue = useMemo(
    () => ({
      version: resolvedVersion,
      currentVersion,
      isVersionOverridden,
    }),
    [currentVersion, isVersionOverridden, resolvedVersion]
  )

  return <ICPlatformContext.Provider value={providerValue}>{children}</ICPlatformContext.Provider>
}
