import type { PropsWithChildren, FunctionComponent } from 'react'
import { SelectedStoreConfigurationProvider } from '../../common/selected-site-provider/SelectedSiteProvider'
import { type InstacartStoreConfiguration } from '../../../__codegen__/api'

interface Props {
  storeConfig: InstacartStoreConfiguration
  storeConfigs: InstacartStoreConfiguration[]
}

export const StoreConfigScopeWrapper: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  storeConfig,
  storeConfigs,
}) => (
  <>
    {storeConfig && (
      <SelectedStoreConfigurationProvider
        selectedStoreConfiguration={storeConfig}
        selectedStoreConfigurations={storeConfigs}
      >
        {children}
      </SelectedStoreConfigurationProvider>
    )}
  </>
)
