import type { PropsWithChildren, FunctionComponent } from 'react'
import { FieldArray } from 'formik'
import { StoreGroupsFormLocationSelector } from '../../location-selector/StoreGroupsFormLocationSelector'

interface Props {
  isEditLocationsMode: boolean
}

export const StoreGroupsFormSelectedListLocations: FunctionComponent<PropsWithChildren<Props>> = ({
  isEditLocationsMode,
}) => (
  <FieldArray name="locationIds">
    {({ form: formikProps }) => {
      const showAddAllButton = isEditLocationsMode
      const showFilters = isEditLocationsMode
      const showSelectedLocationsOnly = !isEditLocationsMode

      return (
        <StoreGroupsFormLocationSelector
          {...formikProps}
          showAddAllButton={showAddAllButton}
          showFilters={showFilters}
          showSelectedLocationsOnly={showSelectedLocationsOnly}
        />
      )
    }}
  </FieldArray>
)
