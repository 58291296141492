import type { PropsWithChildren, FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { L1Header } from '../L1Header'
import { useGenerateL2ComponentTree } from '../navBar.common'
import { navBarHeight } from '../navBar.constants'
import { type NavMenuHierarchy } from '../../NavMenuHierarchy'

type Props = {
  currentlySelectedItem: number
  closeDrawer: any
  navMenuHierarchy: NavMenuHierarchy
}

const RootContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: navBarHeight,
})

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  padding: `16px 0px 48px`,
})

export const NavMenuTopDropdown: FunctionComponent<PropsWithChildren<Props>> = ({
  currentlySelectedItem,
  closeDrawer,
  navMenuHierarchy,
}) => {
  const l1 = navMenuHierarchy.hierarchy[currentlySelectedItem]
  const l2Items = useGenerateL2ComponentTree(l1, closeDrawer, false)
  const intl = useIntl()

  if (!l1) {
    return null
  }
  const Icon = l1.icon

  return (
    <RootContainer>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '864px',
          paddingTop: '32px',
          gap: '0px',
        }}
      >
        {l1?.name && (
          <L1Header
            HeaderIcon={Icon}
            titleMessage={intl.formatMessage({ id: l1?.name })}
            description={l1.description}
            descriptionLink={l1.descriptionLink}
            customPadding="0px 0px 0px 16px"
          />
        )}
        <Row>{l2Items}</Row>
      </div>
    </RootContainer>
  )
}
