// @ts-nocheck legacy file transformed to TS
import { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Button } from 'ic-snacks'
import instacart from '../../../../../common/instacart'
import Modal from '../../../../../components/Modal'
import Spinner from '../../../../../components/Spinner'
import Dropdown from '../../../../../components/Dropdown'
import withDepartmentAisles from '../../../../../common/withDepartmentAisles'
import deprecatedAnalytics from '../../../../../common/deprecatedAnalytics'
import withRetailerRouter from '../../../../../common/withRouter'
import { withNotificationContext } from '../../../../../common/context'
import { withTrackEventOnMount } from '../../../../../../utils/events/hocs'
import { trackEvent } from '../../../../../../utils/events/trackEvent'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`

const buttonStyle = {
  marginLeft: '10px',
}

@withNotificationContext
@withDepartmentAisles
@withRetailerRouter
class ProductDetailsChangeAisleModal extends Component<any> {
  /* eslint-disable react/forbid-prop-types */
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired,
    onDepartmentSelected: PropTypes.func.isRequired,
    departments: PropTypes.array,
    aisles: PropTypes.array,
    onClose: PropTypes.func,
    onCloseSuccess: PropTypes.func,
    notifyError: PropTypes.func,
    product: PropTypes.object,
  }
  /* eslint-enable react/forbid-prop-types */

  state = {
    isBusy: false,
    selectedDepartmentId: +this.props.query.department_id,
    selectedAisleId: +this.props.query.aisle_id,
  }

  componentDidMount() {
    const { selectedDepartmentId } = this.state
    if (selectedDepartmentId) {
      this.props.onDepartmentSelected(selectedDepartmentId)
    }
  }

  onRequestClose = () => {
    if (this.state.isBusy) return false

    return true
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    } else {
      this.props.history.goBack()
    }
  }

  onCloseSuccess = ({ department, aisle }) => {
    if (this.props.onCloseSuccess) {
      this.props.onCloseSuccess({
        department,
        ...aisle,
      })
    } else {
      this.props.history.goBack()
    }
  }

  onSave = async () => {
    if (!this.state.selectedAisleId) return
    this.setState({
      isBusy: true,
    })

    try {
      const { partner_id, warehouse_id, productId, product_id } = this.props.match.params
      // Depending on the page, we use either productId(new) or product_id(legacy)
      const resolvedProductId = productId || product_id

      const department = this.props.departments.find(
        ({ id }) => id === this.state.selectedDepartmentId
      )
      const aisle = this.props.aisles.find(({ id }) => id === this.state.selectedAisleId)

      const payload = {}
      let action = ''
      if (this.props.query.aisle_id) {
        Object.assign(payload, {
          from_aisle_id: +this.props.query.aisle_id,
          to_aisle_id: +this.state.selectedAisleId,
        })
        action = 'change_aisle'
      } else {
        Object.assign(payload, {
          aisle_id: +this.state.selectedAisleId,
        })
        action = 'add_aisle'
      }

      await instacart.go.put(
        `/v1/partners/${partner_id}/warehouses/${warehouse_id}/products/${resolvedProductId}/${action}`,
        { data: payload }
      )

      trackEvent({
        id: `catalog.products.departments_and_aisles.${action}_success`,
        description: `departments and aisles ${action} success`,
        data: {
          aisleId: this.state.selectedAisleId,
          productId: parseInt(resolvedProductId, 10),
        },
      })

      deprecatedAnalytics.track('product.aisle_change', {
        product_id,
        source_type: action,
        source_value: this.state.selectedAisleId,
      })
      this.onCloseSuccess({ department, aisle })
    } catch (e) {
      this.props.notifyError(
        'We could not add the department and aisle to this product. Please try again later.'
      )
      this.onClose()
    } finally {
      this.setState({
        isBusy: false,
      })
    }
  }

  onRemove = async () => {
    if (!this.state.selectedAisleId) return

    this.setState({
      isBusy: true,
    })

    try {
      const { product_id, productId, partner_id, warehouse_id } = this.props.match.params
      // Depending on the page, we use either productId(new) or product_id(legacy)
      const resolvedProductId = productId || product_id

      await instacart.go.put(
        `/v1/partners/${partner_id}/warehouses/${warehouse_id}/products/${resolvedProductId}/remove_aisle`,
        {
          data: {
            aisle_id: +this.state.selectedAisleId,
          },
        }
      )
      this.onClose()
    } catch (e) {
      // eslint-disable-line no-empty
      // maybe show an error here?
    } finally {
      this.setState({
        isBusy: false,
      })
    }
  }

  onDepartmentChange = selectedDepartmentId =>
    this.setState({ selectedDepartmentId }, () =>
      this.props.onDepartmentSelected(selectedDepartmentId)
    )

  onAisleChange = selectedAisleId =>
    this.setState({
      selectedAisleId,
    })

  getDepartmentOptions = () =>
    this.props.departments.map(({ id: value, name: label }) => ({
      label,
      value,
    }))

  getAisleOptions = () => {
    if (!this.state.selectedDepartmentId) return []
    if (!this.props.aisles) return []
    return this.props.aisles
      .filter(
        ({ id }) => !this.props.product.aisles?.some(({ id: existingId }) => existingId === id)
      )
      .map(({ id: value, name: label }) => ({
        label,
        value,
      }))
  }

  renderFooter() {
    if (this.state.isBusy) {
      return <Spinner />
    }

    return [
      this.props.query.aisle_id && (
        <Button key="0" style={buttonStyle} onClick={this.onRemove} snacksStyle="secondary">
          Remove
        </Button>
      ),
      <Button key="1" style={buttonStyle} onClick={this.onSave} snacksStyle="primary">
        Save
      </Button>,
    ]
  }

  render() {
    return (
      <Modal
        width={500}
        height={400}
        isOpen
        onClose={this.onClose}
        onRequestClose={this.onRequestClose}
      >
        {(Header, Body, Footer) => [
          <Header>{this.props.query.aisle_id ? 'Change Aisle' : 'Add Aisle'}</Header>,
          <Body>
            {this.props.departments ? (
              <Container>
                <Dropdown
                  width="90%"
                  defaultable
                  defaultText="Select Department"
                  isDisabled={this.state.isBusy}
                  options={this.getDepartmentOptions()}
                  value={this.state.selectedDepartmentId}
                  onChange={this.onDepartmentChange}
                />
                <br />
                <Dropdown
                  width="90%"
                  defaultable
                  defaultText="Select Aisle"
                  isDisabled={this.state.isBusy}
                  options={this.getAisleOptions()}
                  value={this.state.selectedAisleId}
                  onChange={this.onAisleChange}
                />
              </Container>
            ) : (
              <Container>
                <Spinner />
              </Container>
            )}
          </Body>,
          <Footer>{this.renderFooter()}</Footer>,
        ]}
      </Modal>
    )
  }
}

export default withTrackEventOnMount({
  id: 'catalog.products.departments_and_aisles.add_aisle.viewed',
  description: 'Viewed the add aisles section of the product department and aisles page',
})(ProductDetailsChangeAisleModal)
