import type { PropsWithChildren, FC } from 'react'
import { Form } from 'formik'
import { type StoreGroupsFormikProps } from './utils/form.types'
import { StoreGroupsFormNameField } from './components/StoreGroupsFormNameField'
import { StoreGroupsFormConfirmationBar } from './components/StoreGroupsFormConfirmationBar'
import { StoreGroupsFormSelectedList } from './components/selected-list/StoreGroupsFormSelectedList'
import { useStoreGroupsEditModeState } from './utils/form.hooks'

interface Props extends StoreGroupsFormikProps {
  formId?: string
  showFormConfirmBar?: boolean
}

export const StoreGroupsFormContent: FC<PropsWithChildren<Props>> = ({
  formId,
  showFormConfirmBar = true,
  ...formikProps
}) => {
  const { isEditLocationsMode, toggleEditLocationsMode } = useStoreGroupsEditModeState()

  return (
    <Form id={formId}>
      <StoreGroupsFormNameField {...formikProps} />
      <StoreGroupsFormSelectedList
        {...formikProps}
        toggleEditLocationsMode={toggleEditLocationsMode}
        isEditLocationsMode={isEditLocationsMode}
      />
      {showFormConfirmBar && <StoreGroupsFormConfirmationBar {...formikProps} />}
    </Form>
  )
}
