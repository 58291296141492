import { useMemo } from 'react'
import { useAbsoluteRouteMatch } from '../routing/useAbsoluteRouteMatch.hooks'
import { usePartnerId, useRetailerId, useStoreConfigId } from '../routing/params.hooks'
import { useCurrentAccountDetails } from '../../api/current-account/currentAccount.hooks.ts'
import { trackEvent, type TrackedEvent } from './trackEvent'

export const useTrackEvent = () => {
  const partnerId = usePartnerId({ throwOnMissing: false })
  const retailerId = useRetailerId({ throwOnMissing: false })
  const storeConfigId = useStoreConfigId({ throwOnMissing: false })
  const { account } = useCurrentAccountDetails()
  const sanitizedUrlPath = useAbsoluteRouteMatch()?.pathName

  const trackEventWithStandardData = useMemo(
    () =>
      ({ id, description, data }: TrackedEvent) => {
        trackEvent({
          id,
          description,
          data: {
            partnerId,
            retailerId,
            storeConfigId,
            canonicalId: account?.canonicalId,
            isInternal: account?.isInternal,
            sanitizedUrlPath,
            ...data,
          },
        })
      },
    [
      partnerId,
      retailerId,
      storeConfigId,
      account?.canonicalId,
      account?.isInternal,
      sanitizedUrlPath,
    ]
  )

  return trackEventWithStandardData
}
