import type { PropsWithChildren, FunctionComponent } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@instacart/ids-core'
import {
  ProductLocationsTableColumnSortEnum,
  type ProductLocationsTableColumnSortState,
} from './ProductLocationsTableColumns.tsx'
import { spacing } from '@retailer-platform/shared-components'

export const ProductLocationsTableColumnSortIcon: FunctionComponent<
  PropsWithChildren<{
    state: ProductLocationsTableColumnSortState
    attribute: string
  }>
> = ({ state, attribute }) => {
  if (state?.column != attribute) return null

  return state?.order === ProductLocationsTableColumnSortEnum.ASC ? (
    <ChevronUpIcon size={18} style={{ marginBottom: `-${spacing.X4}`, marginLeft: spacing.X8 }} />
  ) : (
    <ChevronDownIcon size={18} style={{ marginBottom: `-${spacing.X4}`, marginLeft: spacing.X8 }} />
  )
}
