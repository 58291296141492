export enum Metrics {
  CLICKS = 'CLICKS',
  IMPRESSIONS = 'IMPRESSIONS',
  AVG_CPC = 'AVG_CPC',
  CTR = 'CTR',
  REV_SHARE = 'REV_SHARE',
}

export enum Dimensions {
  RETAILER_LOCATION = 'RETAILER_LOCATION',
  RETAILER_LOCATION_NAME = 'RETAILER_LOCATION_NAME',
  DEMAND_SOURCE = 'DEMAND_SOURCE',
  WAREHOUSE_LOCATION_STATE = 'WAREHOUSE_LOCATION_STATE',
  RETAILER = 'RETAILER',
  WAREHOUSE = 'WAREHOUSE',
  PLACEMENT_TYPE = 'PLACEMENT_TYPE',
  SURFACE = 'SURFACE',
}
