import type { PropsWithChildren, FunctionComponent } from 'react'
import { useState, useMemo, useEffect } from 'react'
import { DecoratedInput, PopoverTrigger, colors } from '@retailer-platform/shared-components'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { Popover } from '@mantine/core'
import { useHistory } from 'react-router'
import styled from '@emotion/styled'
import { SearchIcon } from '@instacart/ids-core'
import { useLocation } from 'react-router-dom'
import { useRetailerId, useStoreConfigId } from '../../../utils/routing/params.hooks'
import { ViewType } from '../retailer-scope-wrapper/RetailerScopeWrapper'
import { useDashMessage } from '../../../intl/intl.hooks'
import { RetailerPane } from './RetailerPane'
import { StoreConfigPane } from './StoreConfigPane'
import {
  type FilterSitesFn,
  type SiteOperationIntent,
  SiteSelectOption,
  type StoreConfigurationForSelection,
  type WarehousesForSelection,
} from './RetailerAndSitePicker.types'
import { PickerLabel } from './PickerLabel'

export interface ColourOption {
  readonly value: string
  readonly label: string
  readonly color: string
  readonly extra?: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
}

const ColumnsContainer = styled.div({
  paddingTop: '12px',
  display: 'flex',
  maxHeight: '700px',
})

const VerticalSeparator = styled.div({
  borderLeft: `thin solid ${colors.GRAYSCALE.X20}`,
  marginTop: '8px',
})

const PopoverContainer = styled.div({
  flexDirection: 'column',
  margin: '10px 0px',
})

export const CARROT_LOGO =
  'https://www.instacart.com/assets/beetstrap/brand/2022/carrotlogo-1286c257354036d178c09e815906198eb7f012b8cdc4f6f8ec86d3e64d799a5b.png'

interface Props {
  options: WarehousesForSelection[]
  selectionType: ViewType
  retailerSelectionOptional?: boolean
  storeConfigSelectionOptional?: boolean
  storeConfigOperationIntent?: SiteOperationIntent // 'view' or 'edit'
  storeConfigFilter?: FilterSitesFn // store config filters
  retailerInsufficientPermission?: boolean
}

const RetailerAndSitePicker: FunctionComponent<PropsWithChildren<Props>> = ({
  options,
  selectionType,
  retailerSelectionOptional,
  storeConfigSelectionOptional,
  storeConfigOperationIntent,
  storeConfigFilter,
  retailerInsufficientPermission,
}) => {
  const retailerId = useRetailerId()
  const storeConfigId = useStoreConfigId({ throwOnMissing: false })

  const history = useHistory()
  const location = useLocation()

  const retailerNeedsSelection =
    (retailerId === '0' || retailerId === 'undefined') && !retailerSelectionOptional
  const storeConfigNeedsSelection =
    (storeConfigId === '0' || storeConfigId == 'undefined' || !storeConfigId) &&
    selectionType == ViewType.RETAILER_AND_STORE_CONFIG &&
    !storeConfigSelectionOptional

  const [isOpen, setIsOpen] = useState(retailerNeedsSelection || storeConfigNeedsSelection)
  const [searchFilter, setSearchFilter] = useState('')

  // "Viewing" retailer is a retailer we haven't fully chosen yet - we've only clicked
  // it in the site selector to reveal the store configurations
  const [viewingRetailerId, setViewingRetailerId] = useState(retailerId)
  const [selectedRetailerId, setSelectedRetailerId] = useState<string>(null)
  const [selectedStoreConfigId, setSelectedStoreConfigId] = useState<string>(null)

  const searchMessage = useDashMessage('retailerPicker.search')

  const currentSelectedRetailer = options.find(e => e.value == retailerId)
  const currentSelectedStoreConfig = currentSelectedRetailer?.storeConfigurations?.find(
    e => e.id == storeConfigId
  )

  // used when we click a retailer, and need to display store configurations
  const currentViewingRetailer = options.find(e => e.value == viewingRetailerId)

  const storeConfigurations: StoreConfigurationForSelection[] = useMemo(() => {
    let options =
      currentViewingRetailer?.storeConfigurations?.map(s => {
        const disabled = storeConfigOperationIntent === 'modify' && !s.userAccessToModify

        return {
          label: s.brandedName,
          value: s.id,
          logoUrl: s.logoUrl || CARROT_LOGO,
          retailerId: currentViewingRetailer.value,
          domainName: s.domainName,
          storeConfig: s,
          selectOption: disabled ? SiteSelectOption.SELECT_NOT_APPLICABLE : SiteSelectOption.ALLOW,
        }
      }) || []

    if (storeConfigFilter) {
      options = storeConfigFilter(options)
    }

    return options
  }, [
    currentViewingRetailer?.storeConfigurations,
    currentViewingRetailer?.value,
    storeConfigFilter,
    storeConfigOperationIntent,
  ])

  const showAvailableStoreConfigurations = retailer => {
    setViewingRetailerId(retailer.value)
  }

  const changeRetailerId = (newRetailerId: string) => {
    setIsOpen(false)
    if (!newRetailerId) {
      newRetailerId = '0'
    }

    setSelectedRetailerId(newRetailerId)
  }

  const changeStoreConfigId = (newStoreConfigId: string) => {
    setIsOpen(false)
    if (!newStoreConfigId) {
      newStoreConfigId = '0'
    }

    setSelectedStoreConfigId(newStoreConfigId)
  }

  const onStoreConfigurationSelect = storeConfig => {
    changeStoreConfigId(storeConfig.value)
  }

  // Use useEffect when redirecting, since we don't want to redirect during the rendering phase.
  useEffect(() => {
    // unfortunately this very manual way of changing the URL is required. Normally we could use
    // useRouteMatch() with no arguments to get a raw URL such as /partner/:partner_id/warehouse/:warehouse/dashboard
    // and then generatePath(useRouteMatch(), {partner_id: 289, warehouse_id, 1000}}) to cleanly generate a new URL
    // This approach fails if the user doesn't have access to a parent route.
    // For example if we don't have access to /partner/:partner_id/warehouse/:warehouse/dashboard and we try to hit
    // /partner/:partner_id/warehouse/:warehouse/dashboard/search, then on our "No Access" Error page, useRouteMatch()
    // will return /partner/:partner_id/warehouse/:warehouse/dashboard even though the URL says /partner/289/warehouse/1000/dashboard/search
    if (selectedRetailerId && selectedRetailerId != retailerId) {
      const newPath = location.pathname.replace(
        /\/warehouses\/(undefined|\d+)/,
        '/warehouses/' + selectedRetailerId
      )
      history.push(newPath)
    } else if (
      selectionType == ViewType.RETAILER_AND_STORE_CONFIG &&
      viewingRetailerId &&
      selectedStoreConfigId &&
      (viewingRetailerId != retailerId || selectedStoreConfigId != storeConfigId)
    ) {
      let newPath = location.pathname.replace(
        /\/warehouses\/(undefined|\d+)/,
        '/warehouses/' + viewingRetailerId
      )
      newPath = newPath.replace(/\/sites\/(undefined|[0-9]+)/, '/sites/' + selectedStoreConfigId)
      history.push(newPath)
      // reset selected after redirecting so that back button will work to this page
      setSelectedStoreConfigId(null)
    }
  }, [
    selectedRetailerId,
    retailerId,
    selectionType,
    viewingRetailerId,
    selectedStoreConfigId,
    storeConfigId,
    location.pathname,
    history,
  ])

  const onBannerSelect = (e: WarehousesForSelection) => {
    if (selectionType == ViewType.RETAILER) {
      changeRetailerId(e.value)
    } else {
      showAvailableStoreConfigurations(e)
    }
  }

  const filteredOptions = useMemo(
    () =>
      options.filter(e => {
        // strip all dashes, periods etc to make some names easier to search for
        const alphaNumericOnlyLabel = e.label.match(/\w*/g).join('')
        return alphaNumericOnlyLabel.match(new RegExp(searchFilter, 'i'))
      }),
    [options, searchFilter]
  )

  return (
    <Popover opened={isOpen} onChange={setIsOpen} position="bottom-start" offset={4} zIndex={90}>
      <Popover.Target>
        <PopoverContainer>
          <PopoverTrigger onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
            <PickerLabel
              currentSelectedRetailer={currentSelectedRetailer}
              currentSelectedStoreConfig={currentSelectedStoreConfig}
              changeRetailerId={changeRetailerId}
              changeStoreConfigId={changeStoreConfigId}
              retailerSelectionOptional={retailerSelectionOptional}
              selectionType={selectionType}
            ></PickerLabel>
          </PopoverTrigger>
        </PopoverContainer>
      </Popover.Target>

      <Popover.Dropdown style={{ padding: '16px' }}>
        <DecoratedInput
          id="search"
          decorationComponent={SearchIcon}
          decorationPosition={'left'}
          placeholder={searchMessage}
          onChange={evt => {
            setSearchFilter(evt.currentTarget.value || '')
          }}
          value={searchFilter}
        />
        <ColumnsContainer>
          <RetailerPane
            options={filteredOptions}
            onBannerSelect={onBannerSelect}
            currentSelectedRetailer={currentSelectedRetailer}
            retailerInsufficientPermission={retailerInsufficientPermission}
          />

          {selectionType == ViewType.RETAILER_AND_STORE_CONFIG && (
            <>
              <VerticalSeparator />
              <StoreConfigPane
                storeConfigurations={storeConfigurations}
                onStoreConfigurationSelect={onStoreConfigurationSelect}
                currentUnconfirmedRetailer={currentViewingRetailer}
              ></StoreConfigPane>
            </>
          )}
        </ColumnsContainer>
      </Popover.Dropdown>
    </Popover>
  )
}

export default RetailerAndSitePicker
