import type { PropsWithChildren, FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { colors, Text } from 'ic-snacks'
import ButtonBase from '../ButtonBase'
import { useWarehouseContext } from '../../../utils/contexts/warehouse/WarehouseContext.hooks'
import clientEnv from '../../../utils/global/clientEnv'

interface Props {
  onClick: () => void
  skipImageLoad?: boolean
}

const BannerSwitchButton: FunctionComponent<PropsWithChildren<Props>> = ({
  onClick,
  skipImageLoad = clientEnv.PUBLIC_CLIENT_ENVIRONMENT === 'development',
}) => {
  const warehouseContext = useWarehouseContext()
  let buttonContent = null

  if (warehouseContext.error) {
    buttonContent = (
      <Text variant="T.11" style={{ color: colors.RED_500 }}>
        <FormattedMessage id="common.error" />
      </Text>
    )
  }

  if (warehouseContext.loaded) {
    const { name, logo } = warehouseContext.warehouse

    // In local/development mode, use the name instead of the
    // image, because images may not load properly.
    if (skipImageLoad || !logo) {
      buttonContent = <span>{name}</span>
    } else {
      buttonContent = <img height="36px" src={logo.url} alt="logo" />
    }
  }

  return <ButtonBase onClick={onClick}>{buttonContent}</ButtonBase>
}

export default BannerSwitchButton
