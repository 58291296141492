import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { AdminNavEntryPoint, AdminNavEntryPointV2 } from '@retailer-platform/dashboard/routing'
import { fulfillmentEventsAdminRouter } from './routing/fulfillmentEventsAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import {
  fulfillmentEventsModifyAccessControl,
  fulfillmentEventsViewAccessControl,
} from './access-control/fulfillmentEventsAdminAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'fulfillment-events',
  totem: {
    entity: 'fulfillment-events-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    admin: [fulfillmentEventsAdminRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.MarketplaceOps,
      attachToV2: AdminNavEntryPointV2.Operations,
      subNavItems: [
        {
          route: 'app-admin-fulfillment-events-list',
          labelId: 'fulfillmentEventsDomain.manageEvents',
          accessControl: fulfillmentEventsViewAccessControl,
        },
      ],
    },
    {
      attachTo: AdminNavEntryPoint.MarketplaceOps,
      attachToV2: AdminNavEntryPointV2.Operations,
      subNavItems: [
        {
          route: 'app-admin-fulfillment-events-approval-requests-list',
          labelId: 'fulfillmentEventsDomain.manageEventsApprovalRequests',
          accessControl: fulfillmentEventsModifyAccessControl,
        },
      ],
    },
  ],
}

export default definition
