import React, { type FunctionComponent } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useTDSContext, Text } from '../../tds/index'
import { spacing, fontSize, fontWeight, colors } from '../../foundation'

const titleStyles = css({
  fontSize: fontSize.X18,
  padding: `${spacing.X16} ${spacing.X24}`,
  fontWeight: fontWeight.BOLD,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  button: {
    /**
     * Button margins default are 8px.
     * Set 3 sides to 0 so the header's padding is the only thing in play.
     * The left side will be used as a gap between buttons.
     */
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
  },
})

const headerDividerStyles = css({
  borderBottom: `1px solid ${colors.GRAYSCALE.X20}`,
})

const TitlesWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 2,
  lineHeight: spacing.X26,
})

const ChildrenWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
})

const Subtitle = styled.div({
  fontSize: fontSize.X15,
  fontWeight: fontWeight.REGULAR,
  lineHeight: spacing.X22,
})

export interface CardHeaderProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: string | React.ReactElement
  subtitle?: string | React.ReactElement
  titleDivider?: boolean
}

export const CardHeader: FunctionComponent<React.PropsWithChildren<CardHeaderProps>> = ({
  title,
  subtitle,
  titleDivider = true,
  children,
}) => {
  const { useTDS } = useTDSContext()

  return (
    <div css={[titleStyles, titleDivider && headerDividerStyles]}>
      <TitlesWrapper>
        {useTDS ? <Text typography="subtitle">{title}</Text> : title}
        {subtitle && useTDS && <Text typography="bodyRegular">{subtitle}</Text>}

        {subtitle && !useTDS && <Subtitle>{subtitle}</Subtitle>}
      </TitlesWrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </div>
  )
}
