import type { PropsWithChildren, FunctionComponent } from 'react'
import { MenuOption } from './MenuOption'

interface Props {
  tag: string
  label: string
  subLabel?: JSX.Element
}

export const UserOption: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  tag,
  label,
  subLabel,
}) => <MenuOption tagType={tag} label={label} subLabel={subLabel} heavyLabel showChevron />
