import { createContext } from 'react'
import { type AccessControlConfig } from '../../../legacy/components/AccessControl/accessControl.utils'

export type AccessControlsContextValue = {
  accessControls: (AccessControlConfig | AccessControlConfig[])[]
}

export const AccessControlsContext = createContext<AccessControlsContextValue | null>(null)

AccessControlsContext.displayName = 'AccessControlsContext'

export const accessControlsHasAccess = (
  accessControls: (AccessControlConfig | AccessControlConfig[])[],
  hasAccessControl: (accessControlConfig: AccessControlConfig | AccessControlConfig[]) => boolean
) => (accessControls || []).every(accessControl => hasAccessControl(accessControl))
