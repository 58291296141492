import type { PropsWithChildren, FunctionComponent, CSSProperties } from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { colors } from '@retailer-platform/shared-components'

type Props = {
  title?: string
  marginTop?: boolean
  customPadding?: string
  navGroupCSSProperties?: CSSProperties
}

const RootContainer = styled.div(
  ({
    marginTop,
    navGroupCSSProperties,
  }: {
    marginTop: boolean
    navGroupCSSProperties: CSSProperties
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    marginTop: marginTop ? '16px' : '0px',
    ...navGroupCSSProperties,
  })
)

const TextContainer = styled.div(({ padding }: { padding: string }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px',
  color: colors.GRAYSCALE.X60,
  padding: padding ? padding : '8px 16px',
  cursor: 'default',
}))

export const L2Group: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  title,
  marginTop,
  customPadding,
  navGroupCSSProperties,
}) => {
  const intl = useIntl()

  return (
    <RootContainer marginTop={marginTop} navGroupCSSProperties={navGroupCSSProperties}>
      {title && (
        <TextContainer padding={customPadding}>{intl.formatMessage({ id: title })}</TextContainer>
      )}
      {children}
    </RootContainer>
  )
}
