import { useMemo } from 'react'
import chunk from 'lodash/chunk'
import { type NavMenuHierarchyL2, type NavMenuHierarchyL1 } from '../NavMenuHierarchy'
import { L2Group } from './L2Group'
import { NavItem } from './NavItem'

export const useGenerateL2ComponentTree = (
  l1: NavMenuHierarchyL1,
  closeDrawer,
  spacingAboveEachCategory,
  customNavItemPadding = null,
  customNavGroupPadding = null,
  navGroupCSSProperties = {},
  shouldChunkL1Items = true
) => {
  const l2Items = useMemo(() => {
    const idx = '0'

    if (!l1) {
      return null
    }

    if (l1.hasL2Children) {
      return (l1.children || []).map((l2, idx1) => {
        const l3items = (l2.children || []).map((l3, idx2) => (
          <NavItem
            key={`${idx}_${idx1}_${idx2}`}
            onClick={closeDrawer}
            route={l3.route}
            href={l3.href}
            title={l3.name}
            newTab={l3.newTab}
            isNew={l3.isNew}
            customPadding={customNavItemPadding}
            accessControl={l3.accessControl}
            NavItemWrapper={l3.NavItemWrapper}
            environment={l3.environment}
          />
        ))

        return (
          <L2Group
            key={`${idx}_${idx1}`}
            title={l2.name}
            customPadding={customNavGroupPadding}
            marginTop={spacingAboveEachCategory}
            navGroupCSSProperties={navGroupCSSProperties}
          >
            {l3items}
          </L2Group>
        )
      })
    } else {
      const chunkSize = shouldChunkL1Items ? 4 : l1.children.length
      return chunk(l1.children, chunkSize).map((chunk, idx1) => {
        const l3Items = chunk.map((e, idx2) => (
          <NavItem
            key={`${idx}_${idx1}_${idx2}`}
            onClick={closeDrawer}
            route={e.route}
            href={e.href}
            title={e.name}
            newTab={e.newTab}
            isNew={e.isNew}
            customPadding={customNavItemPadding}
            accessControl={e.accessControl}
            NavItemWrapper={e.NavItemWrapper}
            environment={e.environment}
          />
        ))
        return (
          <L2Group
            key={`${idx}_${idx1}`}
            marginTop={false}
            navGroupCSSProperties={navGroupCSSProperties}
          >
            {l3Items}
          </L2Group>
        )
      })
    }
  }, [
    closeDrawer,
    customNavGroupPadding,
    customNavItemPadding,
    l1,
    navGroupCSSProperties,
    shouldChunkL1Items,
    spacingAboveEachCategory,
  ])

  return l2Items
}

const gatherAllRoutes = (currentNode: NavMenuHierarchyL1 | NavMenuHierarchyL2) => {
  let seenRoutes: string[] = []

  if ('route' in currentNode && currentNode.route) {
    seenRoutes.push(currentNode.route)
  }

  currentNode.children?.forEach(e => {
    seenRoutes = seenRoutes.concat(gatherAllRoutes(e))
  })

  return seenRoutes
}

export const useGatherAllRoutes = (currentNode: NavMenuHierarchyL1 | NavMenuHierarchyL2) =>
  useMemo(() => gatherAllRoutes(currentNode), [currentNode])
