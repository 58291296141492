import type { PropsWithChildren, ComponentType, FunctionComponent } from 'react'
import { Route } from 'react-router'

export const RetailerSatisfactionSurveyStep: FunctionComponent<
  PropsWithChildren<{
    name: string
    component?: ComponentType<PropsWithChildren<any>>
  }>
> = ({ name, component, children }) => (
  <Route path={name} exact component={component}>
    {children}
  </Route>
)
