import type { PropsWithChildren, FunctionComponent } from 'react'
import { Fragment, useState } from 'react'
import { NewSelect } from '@retailer-platform/shared-components'
import { css } from '@emotion/react'
import { DateRangePicker, type FocusedInputShape } from 'react-dates'
import moment from 'moment/moment'
import { NINETY_DAYS } from './ProductLocations.tsx'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { AvailabilityScoreOverrideDateError } from './components/AvailabilityScoreOverrideErrors.tsx'

interface Props {
  options?: { label: string; value: boolean }[]
  onChange: (AvailabilityScoreOverrideState) => void
  disable: boolean
}

interface State {
  enable: boolean | null
  startAt: Date
  endAt: Date
  dateError: boolean
  isBusy: boolean
}

const initialState: State = {
  enable: null,
  startAt: null,
  endAt: null,
  dateError: false,
  isBusy: false,
}

const CellContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`

const DisableHintDiv = styled.div`
  font-size: 12px;
`

const ProductLocationsAvailabilityScoreOverrideCell: FunctionComponent<
  PropsWithChildren<Props>
> = ({ options, onChange, disable }) => {
  const [state, setState] = useState<State>(initialState)
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)

  const onEnableChange = (enable: boolean) => {
    setState({
      ...state,
      enable,
    })
    onChange({
      enable: enable,
      startAt: state.startAt,
      endAt: state.endAt,
    })
  }

  const onDateChange = ({ startDate, endDate }) => {
    const start = startDate?.toDate()
    const end = endDate?.toDate()
    setState({
      ...state,
      dateError: start && end && end.getTime() - start.getTime() > NINETY_DAYS,
      startAt: start,
      endAt: end,
    })
    onChange({
      enable: state.enable,
      startAt: start,
      endAt: end,
    })
  }

  if (disable) {
    return (
      <DisableHintDiv>
        <FormattedMessage id="catalog.products.locations.availabilityScoreOverride.disabled" />
      </DisableHintDiv>
    )
  }

  return (
    <Fragment>
      <CellContent>
        <NewSelect
          css={css({
            width: state.enable ? '90px' : '100%',
          })}
          value={state.enable}
          isLoading={state.isBusy}
          isDisabled={disable}
          onChange={onEnableChange}
          options={options}
        />
        {state.enable && (
          <DateRangePicker
            startDate={state.startAt ? moment(state.startAt) : null}
            startDateId="start_date_picker"
            endDate={state.endAt ? moment(state.endAt) : null}
            endDateId="end_date_picker"
            onDatesChange={onDateChange}
            focusedInput={focusedInput}
            onFocusChange={setFocusedInput}
            small
            showClearDates
            withPortal
          />
        )}
      </CellContent>
      {state.enable && state.dateError && <AvailabilityScoreOverrideDateError />}
    </Fragment>
  )
}

export default ProductLocationsAvailabilityScoreOverrideCell
