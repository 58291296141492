// @ts-nocheck legacy file transformed to TS
import { ReactNode, Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { Icon } from 'ic-snacks'
import styled from '@emotion/styled'

import { Z_INDEX_SECOND } from '../../common/theme'

import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'

const style = {
  overlay: {
    display: 'flex',
    backgroundColor: '#4a4a4aba',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: Z_INDEX_SECOND,
  },
  content: {
    display: 'flex',
    border: '1px solid rgba(91, 91, 91, 0.77)',
    borderRadius: '5px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    flex: '1',
    padding: 0,
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'hidden',
    justifyContent: 'space-between',
    position: 'relative',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
}

const ModalContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const closeStyle = {
  position: 'fixed',
  top: '20px',
  right: '20px',
  color: 'white',
  fontSize: '22px',
  cursor: 'pointer',
}

class Modal extends Component {
  static propTypes = {
    onRequestClose: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    height: PropTypes.string,
    width: PropTypes.string,
    children: ReactNode,
  }

  static defaultProps = {
    onRequestClose: () => true,
    height: '800px',
    width: '700px',
  }

  componentDidMount() {
    global.document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    global.document.body.style.overflow = 'auto'
  }

  onRequestClose = () => {
    this.props.onRequestClose() && this.props.onClose()
  }

  render() {
    const { height, width, children } = this.props

    return (
      <ReactModal
        isOpen
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={this.onRequestClose}
        style={{
          ...style,
          content: {
            ...style.content,
            maxHeight: height,
            width,
          },
        }}
      >
        <Icon onClick={this.onRequestClose} name="xBold" style={closeStyle} />
        <ModalContentContainer>
          {children(ModalHeader, ModalBody, ModalFooter)}
        </ModalContentContainer>
      </ReactModal>
    )
  }
}

export default Modal
