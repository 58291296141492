import type { ReactElement, PropsWithChildren } from 'react'
import { Children, cloneElement } from 'react'
import { Tooltip } from '@retailer-platform/shared-components'
import { type Permission, type PartnerType } from '../../common/types'
import { type WarehouseLocationFeature } from '../../common/warehouseLocations'
import { type FeatureToggle } from '../../contexts/featureToggles/FeatureToggleContext'
import { AccessControlOneOf, type AccessControlConfig } from './accessControl.utils'
import useAccessControl from './useAccessControl'
import { useDashMessage } from '../../../intl/intl.hooks'
import { useIsReadOnly } from '../../../gin-and-tonic/utils/access/useIsReadOnly'

/**
 * This component is designed to provide gated access to other components.
 * By default, accessBlockedResult is 'remove-from-dom', which corresponds to the component not being rendered in the DOM at all.
 * The next option is 'disable', which is supported by any component that implements the 'disabled' prop: <button>, <fieldset>, <optgroup>, <option>, <select>, <textarea>, and <input>.
 * In addition, it can be used with any custom component that implements the 'disabled' prop.
 *
 * Example usage:
 *
 * // To remove a component from the DOM if access is blocked:
 * <AccessControl accessControlConfig={config} accessBlockedResult="remove-from-dom">
 *   <YourComponent />
 * </AccessControl>
 *
 * // To disable a component if access is blocked:
 * <AccessControl accessControlConfig={config} accessBlockedResult="disable">
 *   <Button>Click Me</Button>
 * </AccessControl>
 *
 * // To require write access:
 * <AccessControl accessControlConfig={config} requiresWriteAccess={true}>
 *   // Will be removed from the DOM if access is blocked.
 *   <YourComponent />
 * </AccessControl>
 */

type AccessBlockedType = 'remove-from-dom' | 'disable'

type BaseProps = PropsWithChildren<{
  accessControlConfig: AccessControlConfig
  accessBlockedResult?: AccessBlockedType
  requiresWriteAccess?: boolean
}>

interface RemoveFromDomOnlyProps extends BaseProps {
  accessBlockedResult: 'remove-from-dom'
}

interface DisableProps extends Omit<BaseProps, 'children'> {
  accessBlockedResult: 'disable'
  children: ReactElement<{ disabled?: boolean }> | ReactElement<{ disabled?: boolean }>[]
}

export type AccessGatedProps = RemoveFromDomOnlyProps | DisableProps | BaseProps

export function AccessGated(props: AccessGatedProps) {
  const {
    accessControlConfig,
    accessBlockedResult = 'remove-from-dom',
    requiresWriteAccess,
    children,
  } = props

  const isReadOnly = useIsReadOnly()
  const hasAccess = useAccessControl()
  const writeOk = requiresWriteAccess ? !isReadOnly : true

  const disabledComponentTooltipText = useDashMessage('accessControl.disabledComponentTooltipText')
  const missingWriteAccessTooltipText = useDashMessage(
    'accessControl.missingWriteAccessTooltipText'
  )

  if (writeOk && hasAccess(accessControlConfig)) {
    return <>{children}</>
  }

  if (accessBlockedResult === 'disable') {
    return (
      <Tooltip
        target={Children.map(children, child =>
          cloneElement(child as ReactElement, { disabled: true })
        )}
      >
        {!writeOk ? missingWriteAccessTooltipText : disabledComponentTooltipText}
      </Tooltip>
    )
  } else {
    return null
  }
}

function oneOf(values: Permission[]): AccessControlOneOf<Permission>
function oneOf(values: FeatureToggle[]): AccessControlOneOf<FeatureToggle>
function oneOf(values: WarehouseLocationFeature[]): AccessControlOneOf<WarehouseLocationFeature>
function oneOf(values: PartnerType[]): AccessControlOneOf<PartnerType>
function oneOf<T>(values: T[]) {
  return new AccessControlOneOf<T>(values)
}

export default Object.assign(AccessGated, { oneOf })
