import React, { useContext } from 'react'

interface TDSContextValue {
  useTDS: boolean
}

const TDSContext = React.createContext<TDSContextValue>({
  useTDS: false,
})

export const useTDSContext = () => useContext(TDSContext)

export const TDSStylesProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <TDSContext.Provider value={{ useTDS: true }}>{children}</TDSContext.Provider>
)
