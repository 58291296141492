import type { PropsWithChildren, FunctionComponent } from 'react'
import { createContext, useContext } from 'react'
import {
  useSelection,
  type UseSelectionHookReturn,
  type UseSelectionInitialState,
} from './useSelection.hooks'

export const SelectionManagerContext = createContext<UseSelectionHookReturn>(null)

export const useSelectionManager = () => useContext(SelectionManagerContext)

interface Props {
  initialState?: UseSelectionInitialState
}

export const SelectionManagerProvider: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  initialState,
}) => {
  const state = useSelection(initialState)

  return (
    <SelectionManagerContext.Provider value={state}>{children}</SelectionManagerContext.Provider>
  )
}
