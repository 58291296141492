// @ts-nocheck legacy file transformed to TS
import { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors, Switch } from 'ic-snacks'
import FlexRow from '../FlexRow'

const Row = styled(FlexRow)`
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const DisabledText = styled.span`
  font-size: 12px;
  color: ${colors.GRAY_46};
  margin-top: 3px;
  margin-left: 1px;
`

class CheckableFeature extends Component {
  static propTypes = {
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    title: PropTypes.element.isRequired,
    disabledText: PropTypes.element.isRequired,
  }

  render() {
    const { title, disabledText, isDisabled, isSelected, onChange } = this.props

    return (
      <Row>
        <Column>
          {title}
          {isDisabled && <DisabledText>{disabledText}</DisabledText>}
        </Column>
        <Switch isDisabled={isDisabled} isSelected={isSelected} onChange={onChange} />
      </Row>
    )
  }
}

export default CheckableFeature
