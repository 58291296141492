import { createContext } from 'react'
import { createContextHoc } from '../utils'

export interface GlobalContextValue {
  loading: boolean
  onLogout(): Promise<void>
}

export const GlobalContext = createContext<GlobalContextValue | null>(null)

GlobalContext.displayName = 'GlobalContext'

export const withGlobalContext = createContextHoc(GlobalContext, {
  displayName: 'withGlobalContext',
  required: true,
})
