import type { PropsWithChildren, FunctionComponent } from 'react'
import { useEffect } from 'react'
import withRouter from '../legacy/common/withRouter'
import { DashRoute } from '../legacy/dash-components/dash-route/DashRoute'
import { DashRedirect } from '../legacy/dash-components/dash-redirect/DashRedirect'
import { DashSwitch } from '../legacy/dash-components/dash-switch/DashSwitch'
import {
  useStripeConnectCallbackOptions,
  type StripeConnectCallbackProps,
} from './utils/stripeConnect.hooks'
import { LoggedInRoutes } from './logged-in/LoggedInRoutes'

interface Props extends StripeConnectCallbackProps {}

// When someone is connecting their account to stripe, they return a token to us using
// the STRIPE_CONNECT_CALLBACK_URL. We assume the user is still logged in to their current
// session, and redirect the to the stripe page with some of the query params preset.
export const _Routes: FunctionComponent<PropsWithChildren<Props>> = ({
  location: { pathname, search },
  query: { state },
}) => {
  const stripeRedirectOptions = useStripeConnectCallbackOptions({ pathname, search, state })

  return (
    <DashSwitch>
      <DashRedirect exact fromRoute="stripe-connect-callback" to={stripeRedirectOptions} />
      <DashRoute component={LoggedInRoutes} />
    </DashSwitch>
  )
}

export const BaseRoutes = withRouter(_Routes)
