import type { PropsWithChildren, FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'ic-snacks'
import { type OnClick } from '../../../../../../../../utils/react/event.types'

interface Props {
  toggleEditLocationsMode: OnClick
  isEditLocationsMode: boolean
}

export const StoreGroupsFormSelectedListToggleButton: FunctionComponent<
  PropsWithChildren<Props>
> = ({ toggleEditLocationsMode, isEditLocationsMode }) => {
  const buttonMessageId = isEditLocationsMode
    ? 'settings.storeGroups.form.viewLocations'
    : 'settings.storeGroups.form.editLocations'

  return (
    <Button
      size="small"
      onClick={toggleEditLocationsMode}
      elementAttributes={{ 'data-testid': 'store-group-toggle-edit-locations-mode' }}
    >
      <FormattedMessage id={buttonMessageId} />
    </Button>
  )
}
