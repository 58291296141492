import { lazy } from 'react'
import { Redirect } from 'react-router'
import RetailerRoute, {
  type RetailerRouteComponentProps,
} from '../../../../components/RetailerRoute'
import RetailerSwitch from '../../../../components/RetailerSwitch'
import { PRODUCT_EDIT_URL, PRODUCT_URL } from '../../../../common/routes'

const EditProduct = lazy(() => import('./edit/EditProduct'))
const RequestNewProduct = lazy(() => import('./request-new/NewProduct'))

interface Props extends RetailerRouteComponentProps {}

const ProductRoute = (props: Props) => {
  const {
    history: {
      location: { search },
    },
  } = props

  return (
    <RetailerSwitch>
      <RetailerRoute route="new-product-request" component={RequestNewProduct} />
      <RetailerRoute route="editProduct" component={EditProduct} />
      <Redirect from={PRODUCT_URL} to={{ pathname: PRODUCT_EDIT_URL, search }} />
    </RetailerSwitch>
  )
}

export default ProductRoute
