import type { PropsWithChildren, FunctionComponent } from 'react'
import { Fragment } from 'react'
import { NavSelect } from '../NavSelect'
import { NavSeparator } from '../NavSeparator'

export const RetailerNavHeaderContent: FunctionComponent<PropsWithChildren<unknown>> = () => (
  <Fragment>
    <NavSelect />
    <NavSeparator />
  </Fragment>
)
