import 'react-dates/initialize'
import type { ReactElement } from 'react'
import { Router } from 'react-router'
import flow from 'lodash/flow'
import map from 'lodash/map'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import Favicon from 'react-favicon'
import { App as LegacyAppProvider } from '../../legacy/dash-components/app/App'
import { EN_US_TRANSLATIONS } from '../../intl/translations/en-US'
import { AppRouter } from '../../routes/AppRouter'
import { AppProvider } from '../../gin-and-tonic/containers/app-provider/AppProvider'
import createHistory from '../../legacy/common/history'
import { ROUTES_BY_NAME } from '../routing/routes'
import RPP_FAVICON from '../../assets/favicon.png'
import { setTotemRouteOwnerMapping } from '../events/analytics'
import { CoreContext } from './RPPCoreContext'
import {
  type RPPDomain,
  type RPPCoreContext,
  type RPPRouter,
  type RPPDomainNavigationEntriesByAttachType,
  type RPPDomainAdminNavigationEntriesByAttachType,
  type RPPNavigationEntry,
} from './RPPCore.types'

/**
 * This class represents our base application, where multiple domains
 * are registered, and a context generated based on all defined domains
 * which is then passed throughout the application.
 */
export class RPPCore {
  domains: RPPDomain<any, any, any>[]
  additionalTranslations: Record<string, string>[]

  constructor({ domains = [] }: { domains?: RPPDomain<any, any, any>[] }) {
    this.domains = domains
    this.additionalTranslations = []
  }

  attachDomain(domain: RPPDomain<any, any, any>) {
    this.domains.push(domain)
  }

  getTranslations() {
    const domainTranslations: Record<string, string>[] = this.domains.map(
      domain => domain.translations?.EN_US
    )

    return [...domainTranslations, ...this.additionalTranslations].reduce(
      (translations, translationObject) => ({ ...translations, ...translationObject }),
      { ...EN_US_TRANSLATIONS }
    )
  }

  addTranslations(translationObject: Record<string, string>) {
    this.additionalTranslations.push(translationObject)
  }

  // TODO: abstract getDomainNavigationEntriesByAttachType and getDomainAdminNavigationEntriesByAttachType
  // when old nav is ripped out
  /**
   * Groups the retailer-scoped navigation entries from the domains by attach type
   */
  getDomainNavigationEntriesByAttachType(
    options: {
      useV2: boolean
    } = { useV2: false }
  ): RPPDomainNavigationEntriesByAttachType {
    return this.domains.reduce((allNavEntries, domain) => {
      if (!domain.navigationEntries) return allNavEntries

      domain.navigationEntries.forEach(({ subNavItems, attachTo, attachToV2 }) => {
        const newEntries: RPPNavigationEntry[] = subNavItems
          .map(subNavItem => {
            if (subNavItem.type === 'header') return null
            return {
              route: subNavItem.route as string,
              subRoutes: subNavItem.subRoutes as string[],
              href: subNavItem.href as string,
              labelId: subNavItem.labelId,
              accessControl: subNavItem.accessControl,
              id: subNavItem.id,
              position: subNavItem.position,
              positionNavV2: subNavItem.positionNavV2,
              isNew: subNavItem.isNew,
              navBadge: subNavItem.navBadge,
              environment: subNavItem.environment,
              NavItemWrapper: subNavItem.NavItemWrapper,
            }
          })
          .filter(Boolean)

        if (options.useV2) {
          if (attachToV2) {
            allNavEntries[attachToV2] = [...(allNavEntries[attachToV2] || []), ...newEntries]
          }
        } else {
          if (attachTo) {
            allNavEntries[attachTo] = [...(allNavEntries[attachTo] || []), ...newEntries]
          }
        }
      })

      return allNavEntries
    }, {} as RPPDomainNavigationEntriesByAttachType)
  }

  /**
   * Groups the admin navigation entries from the domains into by attach type
   */
  getDomainAdminNavigationEntriesByAttachType(
    options: {
      useV2: boolean
    } = { useV2: false }
  ): RPPDomainAdminNavigationEntriesByAttachType {
    return this.domains.reduce((allNavEntries, domain) => {
      if (!domain.adminNavigationEntries) return allNavEntries

      domain.adminNavigationEntries.forEach(({ subNavItems, attachTo, attachToV2 }) => {
        const newEntries: RPPNavigationEntry[] = subNavItems.map(subNavItem => ({
          route: subNavItem.route as string,
          subRoutes: subNavItem.subRoutes as string[],
          labelId: subNavItem.labelId,
          accessControl: subNavItem.accessControl,
          positionNavV2: subNavItem.positionNavV2,
        }))

        if (options.useV2) {
          if (attachToV2) {
            allNavEntries[attachToV2] = [...(allNavEntries[attachToV2] || []), ...newEntries]
          }
        } else {
          if (attachTo) {
            allNavEntries[attachTo] = [...(allNavEntries[attachTo] || []), ...newEntries]
          }
        }
      })

      return allNavEntries
    }, {} as RPPDomainAdminNavigationEntriesByAttachType)
  }

  /**
   * Merges all routes
   */
  getRoutes(): Record<string, string> {
    return this.domains.reduce((acc, domain) => ({ ...acc, ...domain.routes }), {
      ...ROUTES_BY_NAME,
    })
  }

  /**
   * Maps routes to totem info
   */
  getTotemInfoByRoute(): Record<string, { routePattern: string; hasDevTooling?: boolean }> {
    const routeOwnerMapping = {}
    this.domains.forEach(domain => {
      Object.entries(domain.routes).forEach((entry: [string, string]) => {
        const [route, routePattern] = entry

        if (domain.totem?.routeOverrides?.[route]) {
          routeOwnerMapping[routePattern] = {
            routePattern: domain.totem.routeOverrides[route],
            hasDevTooling: domain.totem.hasDevTooling,
          }
        } else {
          routeOwnerMapping[routePattern] = {
            routePattern: domain.totem?.entity,
            hasDevTooling: domain.totem.hasDevTooling,
          }
        }
      })
    })

    setTotemRouteOwnerMapping(routeOwnerMapping)
    return routeOwnerMapping
  }

  /**
   * Extracts the admin routers of each domain and collects them into a flattened array
   */
  getAdminRouters(): RPPRouter[] {
    return flow([a => map(a, 'routers.admin'), flatten, compact])(this.domains)
  }

  /**
   * Extracts the dev portal routers of each domain and collects them into a flattened array
   */
  getDevPortalRouters(): RPPRouter[] {
    return flow([a => map(a, 'routers.devPortal'), flatten, compact])(this.domains)
  }

  /**
   * Extracts the scoped routers of each domain and collects them into a flattened array
   */
  getScopedRouters(): RPPRouter[] {
    return flow([a => map(a, 'routers.scoped'), flatten, compact])(this.domains)
  }

  /**
   * Extracts the scoped routers of each domain and collects them into a flattened array
   */
  getPartnerScopedRouters(): RPPRouter[] {
    return flow([a => map(a, 'routers.partnerScoped'), flatten, compact])(this.domains)
  }

  /**
   * Extracts the public routers of each domain and collects them into a flattened array
   */
  getPublicRouters(): RPPRouter[] {
    return flow([a => map(a, 'routers.public'), flatten, compact])(this.domains)
  }

  prepareContext(): RPPCoreContext {
    return {
      routesByName: this.getRoutes(),
      adminRouters: this.getAdminRouters(),
      scopedRouters: this.getScopedRouters(),
      partnerScopedRouters: this.getPartnerScopedRouters(),
      publicRouters: this.getPublicRouters(),
      domainNavigationEntries: this.getDomainNavigationEntriesByAttachType(),
      domainNavigationEntriesV2: this.getDomainNavigationEntriesByAttachType({ useV2: true }),
      domainAdminNavigationEntries: this.getDomainAdminNavigationEntriesByAttachType(),
      domainAdminNavigationEntriesV2: this.getDomainAdminNavigationEntriesByAttachType({
        useV2: true,
      }),
      totemInfoByRoute: this.getTotemInfoByRoute(),
    }
  }

  start<T extends (tree: ReactElement) => any>(callback: T) {
    const history = createHistory()
    const messages = this.getTranslations()
    const contextValue = this.prepareContext()

    return callback(
      <CoreContext.Provider value={contextValue}>
        <Favicon url={RPP_FAVICON} />
        <Router history={history}>
          <AppProvider messages={messages}>
            <LegacyAppProvider>
              <AppRouter />
            </LegacyAppProvider>
          </AppProvider>
        </Router>
      </CoreContext.Provider>
    ) as ReturnType<T>
  }
}
