import type { PropsWithChildren, FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { colors, Text } from 'ic-snacks'
import FlexColumn from '../FlexColumn'
import { type Warehouse } from '../../common/types'

const Banner = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 230px;
  width: 220px;
  margin: 10px;
  margin-right: 50px;
  margin-bottom: 100px;
  justify-content: space-between;
  float: left;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  padding: 10px;
  padding-top: 20px;
  transition: background 0.08s ease;
  background: white;
  user-select: none;

  &:hover {
    background: ${colors.GRAY_93};
  }

  &:active {
    background: ${colors.GRAY_88};
  }
`

const BannerLogo = styled.img`
  width: 130px;
  height: 130px;
  object-fit: contain;
`

interface Props extends Warehouse {
  onClick: (id: number) => void
}

const BannerCell: FunctionComponent<PropsWithChildren<Props>> = ({ id, logo, name, onClick }) => (
  <Banner data-testid={`select-partner-warehouse-banner-cell-${id}`} onClick={() => onClick(id)}>
    {logo && <BannerLogo src={logo.url} draggable={false} />}
    <Text fontWeight="bold" variant="T.14" style={{ color: colors.GRAY_20, marginBottom: '12px' }}>
      {name}
    </Text>
  </Banner>
)

export default BannerCell
