// @ts-nocheck legacy file transformed to TS
import { createRef, PureComponent } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors, Icon, Text } from 'ic-snacks'
import intersection from 'lodash/intersection'
import debounce from 'lodash/debounce'
import mem from 'mem'
import FlexRow from '../FlexRow'
import Spinner from '../Spinner'
import { OldCard as Card } from '../Card'
import { Z_INDEX_FIRST } from '../../common/theme'
import DropdownOptions from './DropdownOptions'
import DropdownFilter from './DropdownFilter'

const Container = styled.div`
  position relative;
  margin: ${({ margin }) => margin || '0 5px'};
  width: ${props => props.width};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`

const CONTROL_SIZE = 35
const DROPDOWN_MARGIN = 4
const DROPDOWN_PADDING = 8

const OptionsContainer = styled(Card)`
  margin: ${DROPDOWN_MARGIN}px 0;
  transform: translate3d(0, ${CONTROL_SIZE}px, 0);
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px 0px, rgba(0, 0, 0, 0.23) 0px 3px 10px 0px;
  display: inline-block;
  padding: ${DROPDOWN_PADDING}px 0px 0px;
  overflow-y: auto;
  user-select: none;
  max-height: calc(100% - ${CONTROL_SIZE}px);
  outline: none;
  width: 100%;
`

const SelectedContainer = styled(FlexRow)`
  flex: 1;
  width: 100%;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 10px 12px;
  padding-right: 5px;
  background-color: ${props => (props.isError ? '#FDE6EB' : colors.WHITE)};
  transition: background 0.1s linear;
  border: 1px solid
    ${props =>
      props.isError ? colors.RED_700 : props.isOpen ? 'rgb(67, 176, 42)' : 'rgb(189, 189, 189)'};
  border-radius: 4px;
  box-sizing: border-box;
  height: ${CONTROL_SIZE}px;
  margin: 0px;
  outline: none;
  position: relative;
  opacity: 1;
  color: ${props =>
    props.isError ? colors.RED_700 : props.isOpen ? 'rgb(67, 176, 42)' : colors.GRAY_46};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    background: ${props => (props.isError ? '#FDE6EB' : 'inherit')};
  }
`

const modalStyle = {
  overlay: {
    backgroundColor: 'transparent',
    zIndex: Z_INDEX_FIRST,
  },
  content: {
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
    background: 'transparent',
    border: 'none',
    overflow: 'visible',
    padding: 0,
  },
}

const iconStyle = {
  top: '2.5px',
  right: '9px',
  outline: 'none',
}

const zIndex = Z_INDEX_FIRST

const filterVisibleOptions = mem((...args) => {
  // mem will break in ie11 if we specify the args...
  const [options, appliedFilter] = args
  // Escape special characters from entered search term i.e. appliedFilter
  const filterRegexp = new RegExp(appliedFilter.replace(/[.*+?^#${}()|[\]\\]/g, '\\$&'), 'i')

  return options.filter(({ label }) => label && label.match(filterRegexp))
})

class Dropdown extends PureComponent<any> {
  static propTypes = {
    defaultText: PropTypes.node,
    defaultable: PropTypes.bool,
    filterable: PropTypes.bool,
    width: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        isDisabled: PropTypes.bool,
      })
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.any]),
    renderSelectedValue: PropTypes.func,
    margin: PropTypes.string,
    multiple: PropTypes.bool,
    multipleInfixText: PropTypes.string,
    selectAll: PropTypes.bool,
    isBusy: PropTypes.bool,
    isError: PropTypes.bool,
    handlesError: PropTypes.bool,
    errorText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
  }

  static defaultProps = {
    defaultText: 'Select one',
    defaultable: false,
    filterable: false,
    isBusy: false,
    isError: false,
    handlesError: false,
    width: '100%',
    multiple: false,
    selectAll: false,
    isDisabled: false,
  }

  state = {
    isOpen: false,
    filter: '',
    appliedFilter: '',
    selectedAll: false,
    maxHeight: 0,
  }

  containerRef = createRef()

  optionsRef = createRef()

  filterRef = createRef()

  modalContentContainer

  calcHeight(rect) {
    let maxHeight = global.innerHeight - rect.top - 10
    if (maxHeight > 500) maxHeight = 500

    return maxHeight
  }

  componentDidMount() {
    global.document.body.addEventListener('click', this.onClick, { capture: true })
    global.addEventListener('resize', this.onResize)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOpen && !prevState.isOpen) {
      global.document.body.addEventListener('keydown', this.handleKeyDown)
    } else if (!this.state.isOpen && prevState.isOpen) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        filter: '',
        appliedFilter: '',
      })
      global.document.body.removeEventListener('keydown', this.handleKeyDown)
    }
  }

  componentWillUnmount() {
    global.document.body.removeEventListener('click', this.onClick, { capture: true })
    global.removeEventListener('resize', this.onResize)
  }

  handleKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault()

      if (this.state.isOpen) {
        this.onClose()
      }
    }
  }

  onResize = () => {
    if (this.state.isOpen) {
      this.applyPortalStyles()
    }
  }

  applyPortalStyles() {
    const containerRect = this.containerRef.current.getBoundingClientRect()
    const maxHeight = this.calcHeight(containerRect)

    this.setState({ maxHeight })

    Object.assign(this.modalContentContainer.style, {
      position: 'absolute',
      'z-index': zIndex,
      width: `${containerRect.width}px`,
      height: `${maxHeight}px`,
      top: `${containerRect.top}px`,
      left: `${containerRect.left}px`,
    })
  }

  handleAfterOpen = () => {
    this.applyPortalStyles()
    if (this.props.filterable) {
      this.filterRef.current.focus()
    }
  }

  onClick = e => {
    if (!this.state.isOpen) return
    if (e.target === this.containerRef.current) return
    if (this.optionsRef.current && this.optionsRef.current.contains(e.target)) return
    this.onClose()
  }

  onOpen = () => {
    if (this.props.isBusy) return
    if (this.props.isDisabled) return
    if (!this.props.options.length) return
    this.setState({ isOpen: true })
  }

  onClose = () => {
    this.setState({ isOpen: false })
  }

  onDefault = () => {
    if (this.props.isBusy) return

    if (this.props.defaultValue !== undefined) {
      this.props.onChange(this.props.defaultValue)
    } else {
      this.props.onChange([])
    }

    this.onClose()
  }

  getVisibleOptions() {
    const { options } = this.props
    const { appliedFilter } = this.state

    if (!appliedFilter) {
      return options
    }

    return filterVisibleOptions(options, appliedFilter)
  }

  onSelectAll = () => {
    const { selectedAll, appliedFilter } = this.state
    const { value, onChange } = this.props
    const newSelectedAll = !selectedAll
    const visibleOptions = this.getVisibleOptions().map(n => n.value)

    let newOptions = []

    if (appliedFilter) {
      const selectedOptions = value || []

      if (newSelectedAll) {
        const items = new Set([...selectedOptions, ...visibleOptions])
        newOptions = [...items]
      } else {
        newOptions = selectedOptions.filter(n => visibleOptions.indexOf(n) === -1)
      }
    } else if (newSelectedAll) {
      newOptions = visibleOptions
    }

    onChange(newOptions)

    this.setState({ selectedAll: newSelectedAll })
  }

  onSelect = value => {
    if (this.props.multiple) {
      const selection = [...(this.props.value || [])]
      const valueIndex = selection.indexOf(value)
      if (valueIndex === -1) {
        selection.push(value)
      } else {
        selection.splice(valueIndex, 1)
      }
      this.props.onChange(selection)
    } else {
      this.props.onChange(value)
      this.onClose()
    }
  }

  onFilterChange = e => {
    this.setState(
      {
        filter: e.target.value,
      },
      this.onDebouncedFilterChange
    )
  }

  onDebouncedFilterChange = debounce(
    () =>
      this.setState(state => ({
        appliedFilter: state.filter,
      })),
    150
  )

  isOptionSelected = value => {
    if (this.props.multiple) {
      return intersection(this.props.value, [value]).length > 0
    }

    return this.props.value === value
  }

  renderFilter() {
    return (
      <DropdownFilter
        placeholder="Filter by name..."
        type="text"
        ref={this.filterRef}
        value={this.state.filter}
        onChange={this.onFilterChange}
      />
    )
  }

  renderOptions() {
    const { defaultText, isBusy, filterable, defaultable, multiple, selectAll } = this.props
    const { isOpen, filter, selectedAll, maxHeight } = this.state

    if (!isOpen) {
      return null
    }
    if (isBusy) return null

    // Subtract control and dropdown margin/padding to get max height of list
    let optionsMaxHeight = maxHeight - CONTROL_SIZE - DROPDOWN_MARGIN - DROPDOWN_PADDING

    if (filterable) optionsMaxHeight -= 37 // dropdown option size

    return (
      <ReactModal
        isOpen
        style={modalStyle}
        contentRef={node => (this.modalContentContainer = node)}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.onClose}
        role="presentation"
      >
        <OptionsContainer data-testid="dropdown-options-container" ref={this.optionsRef}>
          {filterable && this.renderFilter()}
          <DropdownOptions
            options={this.getVisibleOptions()}
            defaultable={!filter && defaultable}
            defaultText={defaultText}
            multiple={multiple}
            selectAll={selectAll}
            selectedAll={selectedAll}
            isOptionSelected={this.isOptionSelected}
            onDefault={this.onDefault}
            onSelect={this.onSelect}
            onSelectAll={this.onSelectAll}
            maxHeight={optionsMaxHeight}
          />
        </OptionsContainer>
      </ReactModal>
    )
  }

  renderStatus() {
    if (this.props.isBusy) {
      return <Spinner data-testid="dropdown-loading-spinner" />
    }

    const style = {
      ...iconStyle,
    }

    if (this.state.isOpen) {
      if (!this.isError) {
        style.color = 'rgb(67, 176, 42)'
      }

      return <Icon name="arrowUpSmall" style={style} />
    }

    return <Icon name="arrowDownSmall" style={style} />
  }

  renderSelected() {
    const { multipleInfixText, value, multiple, options, defaultText, renderSelectedValue } =
      this.props
    let display = defaultText

    if (options) {
      if (renderSelectedValue) {
        display = renderSelectedValue(value)
      } else if (multiple && value && value.length > 1) {
        const infix = multipleInfixText ? `${multipleInfixText} ` : ''

        display = `${value.length} ${infix}selected`
      } else {
        const selectedValue = multiple && value ? value[0] : value
        const selectedOption = options.find(option => option.value === selectedValue)

        if (selectedOption) {
          display = selectedOption.label
        }
      }
    }

    return (
      <SelectedContainer
        data-testid={this.props['data-testid'] || 'dropdown-selected-container'}
        isOpen={this.state.isOpen}
        isError={this.props.isError}
        onClick={this.onOpen}
      >
        <span data-testid="dropdown-display-value">{display}</span>
        {this.renderStatus()}
      </SelectedContainer>
    )
  }

  render() {
    return (
      <Container
        data-testid="dropdown"
        ref={this.containerRef}
        width={this.props.width}
        margin={this.props.margin}
        isDisabled={this.props.isDisabled}
      >
        {this.renderOptions()}
        {this.renderSelected()}
        {this.props.handlesError && (
          <Text
            style={{
              marginTop: 2,
              marginLeft: 1,
              color: colors.RED_700,
              height: 15,
            }}
            variant="T.12"
          >
            {this.props.isError && this.props.errorText}
          </Text>
        )}
      </Container>
    )
  }
}

export default Dropdown
