import { Accordion, Badge, Text, Tooltip } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { useDashMessages } from '../../../../intl/intl.hooks'
import { useCurrentAccountContext } from '../../../../utils/current-account/CurrentAccountContext'

const Label = ({ label, description }: { label: string; description: string }) => (
  <Tooltip target={<Text size="small">{label}</Text>}>{description}</Tooltip>
)

const DetailContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

export const AccountDetails = () => {
  const context = useCurrentAccountContext()

  const i18n = useDashMessages({
    title: 'app.admin.controlPanel.accountDetails.title',
    id: 'app.admin.controlPanel.accountDetails.id',
    idDescription: 'app.admin.controlPanel.accountDetails.id.description',
    uuid: 'app.admin.controlPanel.accountDetails.uuid',
    uuidDescription: 'app.admin.controlPanel.accountDetails.uuid.description',
    email: 'app.admin.controlPanel.accountDetails.email',
    emailDescription: 'app.admin.controlPanel.accountDetails.email.description',
  })

  return (
    <Accordion showContentBackground={true} title={i18n.title}>
      <DetailContainer>
        <Label label={i18n.id} description={i18n.idDescription} />
        <Badge>{context.account.id}</Badge>
      </DetailContainer>
      <DetailContainer>
        <Label label={i18n.uuid} description={i18n.uuidDescription} />
        <Badge>{context.account.canonicalId}</Badge>
      </DetailContainer>
      <DetailContainer>
        <Label label={i18n.email} description={i18n.emailDescription} />
        <Badge>{context.account.email}</Badge>
      </DetailContainer>
    </Accordion>
  )
}
